import React, { Component } from "react";

import QVFourEmailAndWebsiteFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourEmailAndWebsiteFooter";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import TQMarketSalesTable from "./TQMarketSalesTable";

import TQMarketIndicators from "./TQMarketIndicators";

class TableQuadrantsSecondLeft extends Component {
  render() {
    const {
      agentData,
      MQCityDetails,
      quarterlyAreaTable,
      quarterlyCityTable,
      currentArea,
      hoData,
      demoAccountAreaName,

      printVersion,
    } = this.props;
    const { mlsAreaID } = currentArea;

    let useCity = false;
    if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
      useCity = true;
    }

    return (
      <div className="table-quadrants-second-left">
        <div className="mq3-second-left-middle-section">
          <TQMarketSalesTable
            quarterlyCityTable={quarterlyCityTable}
            currentArea={currentArea}
            quarterlyAreaTable={
              useCity ? quarterlyCityTable : quarterlyAreaTable
            }
            useCity="false"
            hoData={hoData}
            agentData={agentData}
            demoAccountAreaName={demoAccountAreaName}
            MQCityDetails={MQCityDetails}
            lastMidQuarterlyPrintDate={
              quarterlyAreaTable.lastMidQuarterlyPrintDate
            }
            //printDate="Nov 1, 2021"
            printVersion={printVersion}
          />
          <TQMarketIndicators
            quarterlyAreaTable={quarterlyAreaTable}
            currentArea={currentArea}
            useCity={useCity}
            quarterlyCityTable={quarterlyCityTable}
          />
        </div>
        <MQThreeAvailableHomes
          currentArea={currentArea}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          MQCityDetails={MQCityDetails}
          hoData={hoData}
          agentData={agentData}
          useCity={useCity}
          altVersion="may-2024"
          printVersion={printVersion}
        />
        {/*<TQAvailableHomes
          currentArea={currentArea}
          quarterlyAreaTable={quarterlyAreaTable}
          quarterlyCityTable={quarterlyCityTable}
          MQCityDetails={MQCityDetails}
          hoData={hoData}
          agentData={agentData}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCity={demoAccountCity}
          useCity={useCity}
        />*/}
        <QVFourEmailAndWebsiteFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default TableQuadrantsSecondLeft;
