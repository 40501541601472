import { GetStateStorage } from "common/storage";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import moment from "moment";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import { NotesCameFrom } from "./NotesConfig";

const getPersonalNoteSizes = () => {
  return {
    personalNoteMinLength: 200,
    personalNoteMaxLength: 560,
    personalPSMinLength: 50,
    personalPSMaxLength: 205,
  };
};

const geGlobalNoteSizes = () => {
  return {
    globalNoteMinLength: 200,
    globalNoteMaxLength: 560,
    globalPSMinLength: 50,
    globalPSMaxLength: 205,
  };
};

const shouldOveridePersonalNote = ({ changeDetails }, cameFrom) => {
  let { textPS, personalPS, typePS } = changeDetails || {};
  textPS = textPS && textPS != "-" && textPS != "<p></p>" ? textPS : "";
  return (textPS || personalPS) && typePS && cameFrom && typePS !== cameFrom
    ? true
    : false;
};

const getLastWrittenNotesandPS = (allHomeowners = [], cameFrom) => {
  //filter the Hos first
  let newHomeowners = [...allHomeowners].filter((item) => {
    let { lastNoteHTML } = getNotePSFromPropertyandCameFrom(item, cameFrom);
    return lastNoteHTML !== "";
  });
  newHomeowners = [...newHomeowners].sort((a, b) => {
    let { textPSDate: atextPSDate } = a.changeDetails ? a.changeDetails : {};
    let { textPSDate: btextPSDate } = b.changeDetails ? b.changeDetails : {};
    if (new Date(atextPSDate) > new Date(btextPSDate)) {
      return -1;
    } else if (new Date(atextPSDate) < new Date(btextPSDate)) {
      return 1;
    } else {
      return 0;
    }
  });
  const latestNotePSHO = (newHomeowners && newHomeowners[0]) || {};
  return getNotePSFromPropertyandCameFrom(latestNotePSHO, cameFrom);
};

const getNotePSFromPropertyandCameFrom = (property, cameFrom) => {
  const {
    textPS, //personal note
    personalPS, //personal ps
    typePS,
  } = property.changeDetails ? property.changeDetails : {};
  const lastNoteHTML =
    textPS && textPS != "-" && textPS != "<p></p>" && typePS === cameFrom
      ? textPS
      : "";
  const lastNotePS = personalPS && typePS === cameFrom ? personalPS : "";
  return { lastNoteHTML, lastNotePS };
};

const isPropertyHasACameFromNoteWritten = (property, cameFrom) => {
  const { lastNoteHTML } = getNotePSFromPropertyandCameFrom(property, cameFrom);
  return lastNoteHTML !== "";
};

const getAllPropertiesPendingAnniversaryNoteswrtHOFilter = (
  propertyData,
  cameFrom,
  hoFilter = null
) => {
  const anniversaryPendingNoteHomeowners = (propertyData || []).filter((prop) =>
    getAPropertyPendingAnniversaryNoteStatuswrtHOFilter(
      prop,
      cameFrom,
      hoFilter
    )
  );
  return anniversaryPendingNoteHomeowners;
};

//private
const getAPropertyPendingAnniversaryNoteStatuswrtHOFilter = (
  property = {},
  cameFrom,
  hoFilter = null
) => {
  return (
    property.HOQuickFilterData.isAnniversary &&
    renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(property, hoFilter) &&
    !noteUtils.isPropertyHasACameFromNoteWritten(property, cameFrom) &&
    (cameFrom === NotesCameFrom.Anniversary
      ? !isAnniversaryNotePSSkipped(property)
      : true)
  );
};

const getPersonalNoteTextFromCameFrom = (cameFrom) => {
  return cameFrom
    ? {
        PersonalPS: "PERSONAL NOTE / PS",
        AbsentHOPS: "ABSENTEE HOMEOWNER NOTE / PS",
        Anniversary: "ANNIVERSARY NOTE / PS",
        Congrats: "CONGRATS NOTE /PS",
        Cancelled: "CANCELLED NOTE /PS",
        Expired: "EXPIRED NOTE / PS",
        Coincidence: "COINCIDENCE NOTE / PS",
      }[cameFrom]
    : "";
};

const getPersonalNoteSingleTextFromCameFrom = (
  singleMode = "personalNote" | "personalPS",
  cameFrom
) => {
  return cameFrom
    ? {
        personalNote: {
          PersonalPS: "PERSONAL NOTE",
          AbsentHOPS: "ABSENTEE HOMEOWNER NOTE",
          Anniversary: "ANNIVERSARY NOTE",
          Congrats: "CONGRATS NOTE",
          Cancelled: "CANCELLED NOTE",
          Expired: "EXPIRED NOTE",
          Coincidence: "COINCIDENCE NOTE",
        },
        personalPS: {
          PersonalPS: "PERSONAL PS",
          AbsentHOPS: "ABSENTEE HOMEOWNER PS",
          Anniversary: "ANNIVERSARY PS",
          Congrats: "CONGRATS PS",
          Cancelled: "CANCELLED PS",
          Expired: "EXPIRED  PS",
          Coincidence: "COINCIDENCE PS",
        },
      }[singleMode][cameFrom]
    : "";
};

const sortPropertyByPersonalNoteType = (propertyData, cameFrom) => {
  return (propertyData || []).sort((a, b) => {
    return (
      (a.changeDetails.typePS === cameFrom ? 1 : 0) -
      (b.changeDetails.typePS === cameFrom ? 1 : 0)
    );
  });
};

const sortPropertyByAnniversaryCount = (propertyData) => {
  return (propertyData || []).sort((a, b) => {
    return (
      renderLogicUtil.getHOAnniversaryNumber(b) -
      renderLogicUtil.getHOAnniversaryNumber(a)
    );
  });
};

const fetchPropertySequenceNumberWhereNeedtoAddNote = (
  propertyData,
  cameFrom
) => {
  let sequenceNumber = 0;
  for (let i = 0; i < (propertyData || []).length; i++) {
    //typePS:- anniversary, null or personalNote
    if (
      propertyData[i].changeDetails.typePS !== cameFrom &&
      (cameFrom === NotesCameFrom.Anniversary
        ? !isAnniversaryNotePSSkipped(propertyData[i])
        : true)
    ) {
      sequenceNumber = i;
      break;
    }
  }
  return sequenceNumber;
};

const getMailProIDsWhereAnniversaryNoteCanbeSkipped = (
  anniversaryHomeowners,
  cameFrom
) => {
  return (anniversaryHomeowners || [])
    .filter((prop) => {
      //prop.changeDetails.typePS
      return (
        prop.changeDetails.typePS === null && !isAnniversaryNotePSSkipped(prop)
      );
    })
    .map((prop) => prop.mailerProID);
};

const checkPersonalNotePSForErrors = (
  isBlocked,
  editMode = "personalNote" | "personalPS", //need to develop: | "both"
  plainPersonalNote,
  personalPS,
  cameFrom
) => {
  const {
    personalNoteMinLength,
    personalNoteMaxLength,
    personalPSMinLength,
    personalPSMaxLength,
  } = getPersonalNoteSizes();
  const contextWord = getPersonalNoteSingleTextFromCameFrom(editMode, cameFrom);
  let errors = [];
  //check for block status
  if (isBlocked) {
    errors.push("Mailpro has been locked for this mailing.");
  } else if (editMode === "personalNote") {
    if (
      !plainPersonalNote ||
      (plainPersonalNote && plainPersonalNote.length < personalNoteMinLength)
    ) {
      errors.push(
        `A minimum of ${personalNoteMinLength} characters is required for ${contextWord}.`
      );
    } else if (
      plainPersonalNote &&
      plainPersonalNote.length > personalNoteMaxLength
    ) {
      errors.push(
        `A maximum of ${personalNoteMaxLength} characters is can be added for ${contextWord}.`
      );
    }
  } else if (editMode === "personalPS" && personalPS && personalPS.length > 0) {
    //check for P.S. we can submit without ps as well
    if (
      !personalPS ||
      (personalPS && personalPS.length < personalPSMinLength)
    ) {
      errors.push(
        `A minimum of ${personalPSMinLength} characters is required for ${contextWord}`
      );
    } else if (personalPS && personalPS.length > personalPSMaxLength) {
      errors.push(
        `A maximum $of {personalPSMaxLength} characters is can be added for ${contextWord}`
      );
    }
  }
  return errors;
};

const getPersonalNoteGreetingTexts = (property, agentData, symbol = "&") => {
  const { listgenUserFirstName, teamMembers } = agentData;
  let hoName = renderLogicUtil.getNoteGreetingTextV4(
    property.changeDetails,
    symbol
  );
  let agentSignoff = propertyUtil.agentSignoffForGlobalNote(
    agentData,
    null,
    false
  );
  return {
    greetTextTop: "Hello " + hoName,
    signOffTextBottom: `${propertyUtil.getSalutation(
      null,
      agentData.listgenUserID
    )}, ${agentSignoff}`,
  };
};

const getPersonalNoteGreetingTextsWithFullName = (
  { changeDetails },
  symbol = "&"
) => {
  const {
    lgOwnerFirstName,
    lgOwnerLastName,
    lgSecondaryOwnerFirstName,
    lgSecondaryOwnerLastName,
  } = changeDetails;
  const firstHOName =
    lgOwnerFirstName && lgOwnerFirstName != "-"
      ? `${lgOwnerFirstName}${
          lgOwnerLastName && lgOwnerLastName != "-" ? " " + lgOwnerLastName : ""
        }`
      : ``;
  const secondHOName =
    lgSecondaryOwnerFirstName && lgSecondaryOwnerFirstName != "-"
      ? `${lgSecondaryOwnerFirstName}${
          lgSecondaryOwnerLastName && lgSecondaryOwnerLastName != "-"
            ? " " + lgSecondaryOwnerLastName
            : ""
        }`
      : ``;
  return firstHOName + (secondHOName ? ` ${symbol} ${secondHOName}` : ``);
};

const getPlainTextFromRichEditorState = (htmlNote) => {
  const editorState = EditorState.createWithContent(stateFromHTML(htmlNote));
  return editorState
    .getCurrentContent()
    .getPlainText()
    .replace(/(\r\n|\n|\r)/gm, " ");
};

const isNotePSReadyForSubmit = (
  isBlocked,
  htmlPersonalNote,
  personalPS,
  cameFrom
) => {
  const plainPersonalNote = getPlainTextFromRichEditorState(htmlPersonalNote);
  const tempError1 = noteUtils.checkPersonalNotePSForErrors(
    isBlocked,
    "personalNote",
    plainPersonalNote,
    personalPS,
    cameFrom
  );
  const tempError2 = noteUtils.checkPersonalNotePSForErrors(
    isBlocked,
    "personalPS",
    plainPersonalNote,
    personalPS,
    cameFrom
  );
  if (isBlocked) {
    return [
      "Hey there! It looks like Mailpro is currently locked for this mailing. Don't worry, editing has been temporarily disabled, but it will be available again soon. Hang tight!",
    ];
  } else if (tempError1.length > 0 || tempError2.length > 0) {
    return [...tempError1, ...tempError2];
  }
  return [];
};

const buildNotePSDataBeforeSubmit = (
  cameFrom,
  changeDetails,
  isBlocked,
  personalNote,
  personalPS,
  editMode,
  resetMode = false,
  resetType = "note"
) => {
  const {
    personalNoteMinLength,
    personalPSMinLength,
  } = noteUtils.getPersonalNoteSizes();
  //remove line breaks from code
  personalNote = propertyUtil.refactorNotePSWithRemovalBeforeSubmit(
    personalNote
  );
  personalPS = propertyUtil.refactorNotePSWithRemovalBeforeSubmit(personalPS);

  if (
    (personalNote.length >= personalNoteMinLength &&
      (personalPS && personalPS.length > 0
        ? personalPS.length >= personalPSMinLength // TODo: dont allow with true personal PS once we have PS back
        : true)) ||
    resetMode
  ) {
    let myDate = moment().format("YYYY-MM-DD H:m:s");
    let updatedChangeDetails = changeDetails;
    //only update one of ps or note based on currentEditMode other use before edit keys
    if (resetMode) {
      //First check if current note is a
      //no need to reset typePS as we can only reset from a section in which we added last note
      updatedChangeDetails = Object.assign(
        {},
        changeDetails,
        resetType === "personalNote"
          ? {
              textPS: null,
              textPSDate: null,
            }
          : editMode === "personalPS"
          ? {
              personalPS: null,
              personalPSDate: null,
            }
          : {
              textPS: null,
              textPSDate: null,
              personalPS: null,
              personalPSDate: null,
              typePS: null,
            }
      );
    } else {
      //In case of update note or PS set isAnniversaryNoteSkipped to false if cameFrom is anniversary
      updatedChangeDetails = Object.assign(
        {},
        changeDetails,
        editMode === "personalNote"
          ? {
              textPS: personalNote,
              textPSDate: myDate,
            }
          : editMode === "personalPS"
          ? {
              personalPS: personalPS,
              personalPSDate: myDate,
            }
          : {
              textPS: personalNote,
              textPSDate: myDate,
              personalPS: personalPS,
              personalPSDate:
                personalPS && personalPS.length > 0 ? myDate : null,
            },
        {
          typePS: cameFrom,
        }
      );
    }

    if (cameFrom === NotesCameFrom.Anniversary) {
      //In case of reset always set isAnniversaryNoteSkipped to true because we will be resetting notes otherwise it will be false as not will be present
      updatedChangeDetails = updateDatatoSkipAnniversaryNotePS(
        { changeDetails: updatedChangeDetails },
        resetMode ? true : false
      );
    }

    return updatedChangeDetails;
  } else {
    throw new Error(
      "There was an error submitting the form. Please try again."
    );
  }
};

const buildHoDataCommonForMailPro = (
  property,
  propertyData,
  updatedChangeDetails
) => {
  const {
    areaID,
    mailerProID,
    agentID,
    mailingStatus,
    imbarcode,
    isPropertyMapped,
    numericIMBarcode,
    parcelNumber,
  } = property;

  /**state vars */
  let totalUnblocked = propertyUtil.getAllUnblockedHOFromAllHomeowners(
    propertyData
  );
  let userData = GetStateStorage() || {};
  const hoData = {
    agentID,
    areaID,
    changeDetails: updatedChangeDetails,
    imbarcode,
    isPropertyMapped,
    mailerProID,
    mailingStatus,
    numericIMBarcode,
    parcelNumber,
    listgenUserID: userData.userID ? userData.userID : "",
    numOfPMAs: totalUnblocked,
  };
  return hoData;
};

const replacePSSuggestionsWithCorrectData = (
  mlsAreas,
  currentArea,
  psSuggestions = []
) => {
  const areaName = (currentArea =
    propertyUtil.getCurrentArea(mlsAreas, currentArea).mlsNeighborhood ||
    "Your area");
  return psSuggestions.map((ps) => ps.replace("%AREA_NAME%", areaName));
};

const isAreaUnapproved = (areaData = {}) => {
  return areaData.mailingStatus !== "Approved";
};

const isAnniversaryNotePSSkipped = ({ changeDetails }) => {
  return (changeDetails || {}).isAnniversaryNoteSkipped || false;
};

const updateDatatoSkipAnniversaryNotePS = (
  { changeDetails },
  skipActionValue
) => {
  return Object.assign({}, changeDetails, {
    isAnniversaryNoteSkipped: skipActionValue,
  });
};

export const noteUtils = {
  getPersonalNoteSizes,
  geGlobalNoteSizes,
  shouldOveridePersonalNote,
  getPersonalNoteTextFromCameFrom,
  sortPropertyByPersonalNoteType,
  sortPropertyByAnniversaryCount,
  checkPersonalNotePSForErrors,
  getPersonalNoteGreetingTexts,
  getPersonalNoteGreetingTextsWithFullName,
  getPlainTextFromRichEditorState,
  buildNotePSDataBeforeSubmit,
  isNotePSReadyForSubmit,
  buildHoDataCommonForMailPro,
  getNotePSFromPropertyandCameFrom,
  getAllPropertiesPendingAnniversaryNoteswrtHOFilter,
  getAPropertyPendingAnniversaryNoteStatuswrtHOFilter,
  isPropertyHasACameFromNoteWritten,
  getLastWrittenNotesandPS,
  replacePSSuggestionsWithCorrectData,
  isAreaUnapproved,
  fetchPropertySequenceNumberWhereNeedtoAddNote,
  getMailProIDsWhereAnniversaryNoteCanbeSkipped,
  isAnniversaryNotePSSkipped,
  updateDatatoSkipAnniversaryNotePS,
};
