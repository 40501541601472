import React, { Component } from "react";

class PrintV2DebSamuel extends Component {
  render() {
    return (
      <div className="senior-relocation">
        <img
          src="https://pma-coverphotos.s3.amazonaws.com/031123_DeborahSamual-DS_backPg2.png"
          alt="Backpage"
        />
      </div>
    );
  }
}

export default PrintV2DebSamuel;
