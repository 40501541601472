import React, { Component } from "react";

class BackpageIndividualBox extends Component {
  render() {
    const { mktValBoxTitle, mktValBoxText, mktValBoxSubText } = this.props;
    let boxStyles;
    if (mktValBoxText && mktValBoxText.length < 80) {
      boxStyles = "left-aligned-main-text";
    }
    return (
      <div className="backpage-boxes-box">
        {mktValBoxTitle && mktValBoxTitle !== "-" && (
          <div className="backpage-boxes-box-title-container">
            <p dangerouslySetInnerHTML={{ __html: mktValBoxTitle }}></p>
          </div>
        )}

        <div className="backpage-boxes-info-container">
          <div className={`backpage-boxes-info-col ${boxStyles}`}>
            {mktValBoxText && mktValBoxText !== "-" && (
              <p
                className="backpage-boxes-main-text"
                dangerouslySetInnerHTML={{ __html: mktValBoxText }}
              ></p>
            )}
            {mktValBoxSubText && mktValBoxSubText !== "-" && (
              <p
                className="backpage-boxes-sub-text"
                dangerouslySetInnerHTML={{ __html: mktValBoxSubText }}
              ></p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BackpageIndividualBox;
