import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import "./customStyles.scss";
import "./customPrintStyles.scss";

import BeachCliff from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/SB_SOS_Backcover.jpg";
import SeniorRelocation from "views/Pma/PmaEditor/CustomAgentPages/SeniorRelocation";

class CustomDeborahSamuelPage extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,

      coverPageDetails,
    } = this.props;
    return (
      <div className="mq3-first-left">
        <div className="custom-bg-image-container">
          <img
            src={BeachCliff}
            alt="Beach Cliff"
            className="beach-cliff-backpage-img"
          />
        </div>

        <div className="custom-white-top-backpage"></div>
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          printVersion={printVersion}
        />

        <HOAddress
          hoData={hoData}
          version={version}
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
          mailInfoClass="mail-info-container-print"
          versionType="custom"
          coverPageDetails={coverPageDetails}
        />
        <SeniorRelocation />
        <div className="ds-testimonial">
          <div className="ds-testimonial-text-container">
            <div className="ds-testimonial-text">
              <p>
                "They did an awesome job in the categorization of items to keep,
                sell or donate allowing me to downsize and move. The packing and
                clearing were done with extraordinary care and organization,
                leaving my home in pristine condition."
              </p>
            </div>
            <div className="ds-testimonial-homeowner">
              <p>— Lillian Rossol</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomDeborahSamuelPage;
