import React, { Component } from "react";
import BackcoverImage from "./backpage-images/backcover-image.jpg";
import ShortBackcoverImage from "./backpage-images/short-backcover-image.jpg";
import OverTheMoonRealtyLogo from "_img/brokerage-logos/over-the-moon-realty-brokerage-logo.png";
import CBGayDalesImage from "./backpage-images/higher-resolution-cb-gay-dales-brokerage-logo.png";

import NewCBBackpageImage from "./backpage-images/new-cb-backpage-image.png";
import SothebysBackpageImage from "./backpage-images/sothebys-backpage-image.png";
import CompassBackpageImage from "./backpage-images/compass-backpage-logo.png";
import { Link } from "react-router-dom";

class AgentAndBrokerageAdvantages extends Component {
  getBrokerageNickname = (brokerage) => {
    brokerage = brokerage.toLowerCase();

    let brokerageNickname;
    if (brokerage.includes("sotheby's")) {
      brokerageNickname = "sotheby";
    } else if (brokerage.includes("coldwell") && brokerage.includes("banker")) {
      brokerageNickname = "coldwell";
    } else if (brokerage.includes("compass")) {
      brokerageNickname = "compass";
    } else if (brokerage.includes("town") && brokerage.includes("country")) {
      brokerageNickname = "towncountry";
    } else if (brokerage.includes("over the moon")) {
      brokerageNickname = "overthemoon";
    } else if (brokerage.includes("berkshire")) {
      brokerageNickname = "berkshirehathaway";
    }
    return brokerageNickname;
  };
  determineBrokerageTitle = (brokerage, actualBrokerageName) => {
    brokerage = brokerage.toLowerCase();
    //console.log("brokerage", actualBrokerageName);
    actualBrokerageName = actualBrokerageName.toLowerCase();
    let hasGayDales =
      actualBrokerageName.includes("gay") &&
      actualBrokerageName.includes("dales");
    //console.log("hasGayDales", hasGayDales);
    let brokerObj = {
      sotheby: {
        title: "Sotheby's",
        subtitle: "International Realty",
        isImage: true,
        imgSrc: SothebysBackpageImage,
      },
      coldwell: {
        title: "Coldwell Banker Realty",
        isImage: true,
        imgSrc: hasGayDales ? CBGayDalesImage : NewCBBackpageImage,
      },
      compass: {
        title: "Compass",
        tagline: "A Real Estate Company with a Purpose",
        isImage: true,
        imgSrc: CompassBackpageImage,
      },
      towncountry: {
        title: "Town ‘N Country Realty",
      },
      overthemoon: {
        title: "Over the Moon Realty",
        isImage: true,
        imgSrc: OverTheMoonRealtyLogo,
      },
      berkshirehathaway: {
        title: "Berkshire Hathaway Home Services",
      },
    };
    let shortenedObj = brokerObj[brokerage];

    if (shortenedObj.isImage) {
      return (
        <div
          className={`agent-and-brokerage-top-title ${
            hasGayDales ? "gay-dales" : brokerage
          }-logo-container`}
        >
          <img
            src={shortenedObj.imgSrc}
            alt={shortenedObj.title}
            className={`${hasGayDales ? "gay-dales" : brokerage}-backpage-logo`}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`agent-and-brokerage-top-title ${brokerage}-logo-container`}
        >
          <div className={`brokerage-title-logo ${brokerage}-backpage-logo`}>
            {shortenedObj.title}
          </div>
        </div>
      );
    }
  };

  getBrokerageTitle = (brokerage, titleNum) => {
    let brokerObj = {
      sotheby: ["Market Leader", "Global Reach"],
      coldwell: ["Trusted Brand", "Powerful Plan"],
      compass: ["Empowered Agents", "Future of Real Estate"],
      towncountry: ["Hyper-Local", "Community Leader"],
      overthemoon: ["Hyper-Local", "Community Leader"],
      berkshirehathaway: ["Empowered Agents", "Future of Real Estate"],
    };
    // console.log("brokerObj", brokerObj[brokerage]);
    return brokerObj[brokerage][titleNum];
  };
  getBrokerageContent = (brokerage, titleNum, typeOfLine) => {
    let brokerObj = {
      sotheby: [
        [
          "Ranked #1 market share in 2020 in",
          "Monterey County for sales",
          "volume and units sold.",
        ],
        [
          "When your home is represented by the Sotheby's",
          "International Realty® brand it benefits from",
          "the worldwide recognition and prestige of the",
          "Sotheby's name and gains exclusive access to",
          "highly qualified global clientele.",
        ],
      ],
      coldwell: [
        [
          "The Coldwell Banker&#174; brand is one of",
          "the world’s best known and trusted",
          "names in real estate, giving you",
          "advantages most other",
          "brokerages can't.",
        ],
        [
          "Coldwell Banker&#174; offers one of the most powerful",
          "and comprehensive marketing programs for",
          "attracting buyers and getting homes sold. We use",
          "a proven combination of traditional and cutting-",
          "edge methods to showcase your home.",
        ],
      ],
      compass: [
        [
          "Compass empowers agents so they have more time for advising their clients. With the solutions-driven mindset of a startup and the sophistication of a luxury brand Compass is the future of real estate.",
        ],
        [
          "With more than 18,000 agents across the U.S., Compass is building the first modern real estate platform, pairing the industry’s top talent with technology to make the search and sell experience intelligent and seamless.",
        ],
      ],
      towncountry: [
        [
          "For over 40 years Town ‘N Country",
          "Realty has long established itself on",
          "the Central Coast as a Full-Service",
          "Real Estate Company that focuses",
          "on our client’s best interests.",
        ],
        [
          "We are a family-owned and operated",
          "business with a great passion for",
          "what we do. We love the communities",
          "we serve and as a result, we don’t",
          "compromise on anything.",
        ],
      ],

      overthemoon: [
        [
          "Over the Moon Realty is a local, woman-owned real estate brokerage that knows Pacific Grove and the micro-market. We pay local taxes, invest in the community, and market widely with the focus on selling client properties - not selling us.",
        ],

        [
          "For every listing sold, we give a percentage of the proceeds to local high school scholarships called Spread Your Wings. When you list with us, you are a part of helping students go further and obtain a higher degree or training.",
        ],
      ],
      berkshirehathaway: [
        [
          "When our name goes on a real estate sign, it means something. It’s a promise of trust, integrity, stability, longevity, and the highest standards of work. And we don’t take that promise lightly. That’s why you’ll see these values reflected in every office, every agent, every interaction.",
        ],
        [
          `"When people are making the decision of the`,
          "magnitude of buying a house, it’s the biggest",
          "decision a great many families will ever make. They",
          "want to know who they’re working with and we think",
          "that the Berkshire Hathaway name will be",
          `reassuring to many of those people."`,
          "Warren Buffett",
          "Chairman and CEO Berkshire Hathaway Inc.",
        ],
      ],
    };

    return brokerObj[brokerage][titleNum];
  };
  longerDesignationsFirst = (list) => {
    function compare(a, b) {
      if (a.designationName.length > b.designationName.length) {
        return -1;
      }
      if (a.designationName.length < b.designationName.length) {
        return 1;
      }
      return 0;
    }
    let sorted = list.sort(compare);
    return sorted;
  };
  render() {
    // console.log(this.props);
    const {
      brokerageName,
      listgenUserFirstName,
      listgenUserLastName,
      teamMembers,
      isMemberOfTeam,
    } = this.props.agentData;
    // console.log(this.props.agentData);
    const {
      getBrokerageTitle,
      getBrokerageNickname,
      getBrokerageContent,
      longerDesignationsFirst,
    } = this;
    //console.log(this.props);
    const { agentDesignations, demoAccountHOName } = this.props;
    //console.log("middle section props", this.props);
    let brokerageNickname = getBrokerageNickname(brokerageName);
    // console.log("brokerageNickname", brokerageNickname);
    let agentNameLength = `${listgenUserFirstName} ${listgenUserLastName}`
      .length;
    //console.log("agentNameLength", agentNameLength);
    let smallerNameFont;
    if (agentNameLength > 16 || isMemberOfTeam) smallerNameFont = true;

    let specialBrokerages = ["overthemoon"];
    let isSpecial;
    if (specialBrokerages.includes(brokerageNickname)) {
      isSpecial = true;
    }
    let reducedBG;
    let reducedBGBrokerages = [
      "sotheby",
      "overthemoon",
      "compass",
      "towncountry",
      "coldwell",
    ];
    if (reducedBGBrokerages.includes(brokerageNickname)) {
      reducedBG = true;
    }

    let sameLastName;
    if (isMemberOfTeam && listgenUserLastName === teamMembers[1].lastName)
      sameLastName = true;
    // console.log("sameLastName", sameLastName);
    let smallerDesignation =
      agentDesignations &&
      agentDesignations.some((designation) => {
        return designation.designationName.length > 45;
      });
    return (
      <div className="outer-advantages-container">
        <img
          className={`backpage-photo ${reducedBG ? "reduced-bg" : ""}`}
          src={reducedBG ? ShortBackcoverImage : BackcoverImage}
          alt="Clouds"
        />

        <div className="mq3-agent-and-brokerage-advantages">
          <div className="brokerage-advantages-container">
            {this.determineBrokerageTitle(brokerageNickname, brokerageName)}
            <div className="brokerage-advantage-body">
              <div className="advantage-container">
                <div className="advantage-title">
                  {getBrokerageTitle(brokerageNickname, 0)}
                </div>
                <div className="advantage-divider-left" />
                {isSpecial ? (
                  <div
                    className={`advantage-body-text-container ${brokerageNickname}-advantage-text left-textbox`}
                  >
                    <div>{getBrokerageContent(brokerageNickname, 0)[0]}</div>
                  </div>
                ) : (
                  <div
                    className={`advantage-body-text-container ${brokerageNickname}-advantage-text left-textbox`}
                  >
                    {getBrokerageContent(brokerageNickname, 0).map((adv) => (
                      <p
                        dangerouslySetInnerHTML={{ __html: adv }}
                        key={adv}
                      ></p>
                    ))}
                  </div>
                )}
              </div>

              <div className="advantage-container">
                <div className="advantage-title">
                  {getBrokerageTitle(brokerageNickname, 1)}
                </div>
                <div className="advantage-divider-right" />
                {isSpecial ? (
                  <div
                    className={`advantage-body-text-container ${brokerageNickname}-advantage-text right-textbox`}
                  >
                    <div>{getBrokerageContent(brokerageNickname, 1)[0]}</div>
                  </div>
                ) : (
                  <div
                    className={`advantage-body-text-container ${brokerageNickname}-advantage-text right-textbox`}
                  >
                    {getBrokerageContent(brokerageNickname, 1).map((adv) => (
                      <p
                        dangerouslySetInnerHTML={{ __html: adv }}
                        key={adv}
                      ></p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="agent-advantages-container">
            <div
              className={`agent-title-container ${brokerageNickname}-title-container`}
            >
              <div className="agent-top-title">
                {isMemberOfTeam ? (
                  <div className="team agent-title-logo smaller-name-font">
                    {listgenUserFirstName}{" "}
                    {sameLastName ? "" : listgenUserLastName} &{" "}
                    {teamMembers[1].firstName} {teamMembers[1].lastName}
                  </div>
                ) : (
                  <div
                    className={`agent-title-logo ${
                      smallerNameFont ? "smaller-name-font" : ""
                    }`}
                  >
                    {listgenUserFirstName} {listgenUserLastName}
                  </div>
                )}
              </div>

              {/*<div className="agent-title-tagline">Delivering Excellence</div>*/}
            </div>
            <div className="brokerage-advantage-body">
              <div className="advantage-container">
                <div className="advantage-title">Trusted Partner</div>
                <div className="advantage-divider-left" />
                {!agentDesignations ? (
                  <Link to="/agent/mystrengths">
                    <div
                      className={`advantage-body-text-container agent-only-advantage
                      smaller-designation advantage-add`}
                    >
                      <p>**Please Click here to add agent designations</p>
                    </div>
                  </Link>
                ) : demoAccountHOName && demoAccountHOName.length > 1 ? (
                  <div
                    className={`advantage-body-text-container agent-only-advantage ${
                      smallerDesignation && "smaller-designation"
                    }`}
                  >
                    <div>Certified Marketing and</div>
                    <div>Luxury Home Specialist</div>
                  </div>
                ) : (
                  <div
                    className={`advantage-body-text-container agent-only-advantage ${
                      smallerDesignation && "smaller-designation"
                    }`}
                  >
                    {longerDesignationsFirst(agentDesignations.slice(0, 2)).map(
                      (designation) => (
                        <div key={designation.designationName}>
                          {designation.designationName}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>

              <div className="advantage-container">
                <div className="advantage-title">Local Expert</div>
                <div className="advantage-divider-right" />
                {!agentDesignations ? (
                  <Link to="/agent/mystrengths">
                    <div
                      className={`advantage-body-text-container agent-only-advantage advantage-add ${
                        smallerDesignation && "smaller-designation"
                      }`}
                    >
                      <p>**Please Click here to add agent designations</p>
                    </div>
                  </Link>
                ) : demoAccountHOName && demoAccountHOName.length > 1 ? (
                  <div
                    className={`advantage-body-text-container agent-only-advantage ${
                      smallerDesignation && "smaller-designation"
                    }`}
                  >
                    <p>Top Producing Agent for 5 Consecutive Years</p>
                    <p>Realtor/Broker for over 20 Years</p>
                  </div>
                ) : (
                  <div
                    className={`advantage-body-text-container agent-only-advantage ${
                      smallerDesignation && "smaller-designation"
                    }`}
                  >
                    {longerDesignationsFirst(agentDesignations.slice(2)).map(
                      (designation) => (
                        <p key={designation.designationName}>
                          {designation.designationName}
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentAndBrokerageAdvantages;
