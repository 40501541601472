import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { GetStateStorage } from "common/storage";
import moment from "moment";

export const propertyUtil = {
  capitalizeWords,
  shortenedDemoBrokerage,
  formatDemoBrokerage,
  formatDemoEmail,
  isUhrig,
  isScar,
  agentSignoffForGlobalNote,
  formatAddress,
  getSalutation,
  getCounty,
  changeDetailsHOName,
  removeTrust,
  calculateQSellersAdvantages,
  formatDaySuffix,
  getSubdivisionName,
  coverPageAreaFontSize,
  speedometerAreaFontSize,
  formatYENeighborhoodTitle,
  useAreaOrCityYEGraph,
  shortenToYourNeighborhood,
  calculateYESellersAdvantages,
  calculateThreeYEGraphs,
  highestSalesRange,
  removeSpaces,
  filterOutSubdivision,
  calculateDiff,
  roundedPercChange,
  headerStyles,
  countAreaChars,
  blackBorder,
  plusOrMinus,
  infinityCheck,
  statusContingentToPending,
  acreOrSqft,
  getLongMonth,
  removeInitials,
  agentFirstNames,
  shortenYear,
  splitAreas,
  slashSeparatedArea,
  commaSeparatedArea,
  monthOrMonths,
  endsWithS,
  shortenPresentedBy,
  shortenPropType,
  shortenStatus,
  propTypeAcronym,
  formatPhoneNo,
  formatPhoneNoWithDashes,
  formatPhoneNoWithPeriods,
  splitPlace,
  calculateCityAreaLength,
  connectedHOName,
  emptyValueCheck,
  // originalHOName,
  originalHONameComplete,
  originalHOSecondNameComplete,
  HONickname,
  EditedHomeownerName,
  nicknameWithOwnerLastName,
  determineInventory,
  posOrNeg,
  currentQuarterAvg,
  categoryPercentage,
  extractQuarterName,
  extractQuarterYear,
  extractQuarterNumber,
  marketTrendShowPercent,
  marketTrendShowPrice,
  determineTrendHeader,
  determineArrow,
  tenthsPlace,
  putDash,
  cutZeroAddress,
  formatBaths,
  homesNumber,
  filterBrokerage,
  hasWaterView,
  getPropView,
  filterAddress,
  marketHighlights,
  daySuffix,
  formatSuffix,
  toAcre,
  cutName,
  calculateRoundedTenths,
  roundSalePriceTenths,
  roundSalePrice,
  getCurrentArea,
  getActualHomeowners,
  getActualAgents,
  getActualUnblockedHomeowners,
  getActualBlockedHomeowners,
  getActualDeletedHomeowners,
  getAllUnblockedHOFromAllHomeowners,
  filterPropertyByStatus,
  getBlockedReasonDescription,
  getBlockedReasonGroup,
  iTextTruncater,
  iHavingAllValueEmpty,
  splitandGetFirstName,
  splitandGetLastName,
  brokerageFirstLineNameHandler,
  brokerageSecondLineNameHandler,
  isValidDate,
  isValidHOName,
  getLastPrintDate,
  reverseNotes,
  daySuffixMailPro,
  checkIsSoldDateBwLastPrintandToday,
  checkIsSoldDateBwLastPrintMinus3MonthsandToday,
  checkCongratsLogic,
  checkAnniversaryLogic,
  valueCorrector,
  getRemainingSecondInaDay,
  shouldBlockMailPro,
  getUnapprovedAreaCount,
  getOneUnapprovedAreaData,
  capitalizeString,
  refactorNotePSWithRemovalBeforeSubmit,
};

function capitalizeWords(address) {
  function capitalizeFirstLetter(word) {
    word = word.toLowerCase();

    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1);
    const capitalizedWord = firstLetterCap + remainingLetters;

    return capitalizedWord;
  }

  let split = address.split(" ");
  let mappedSplit = split.map((name) => {
    let isAllUpperCase = name === name.toUpperCase();
    if (isAllUpperCase) {
      return capitalizeFirstLetter(name);
    }
    return name;
  });
  return mappedSplit.join(" ");
}
function shortenedDemoBrokerage(brokerage) {
  brokerage = brokerage.toLowerCase();

  if (brokerage.includes("berkshire")) {
    return "berkshire";
  } else if (brokerage.includes("compass")) {
    return "compass";
  } else if (brokerage.includes("coldwell")) {
    return "cb";
  } else if (
    brokerage.includes("village") &&
    brokerage.includes("properties")
  ) {
    return "village-properties";
  } else if (brokerage.includes("intero")) {
    return "intero";
  } else if (brokerage.includes("sotheby's")) {
    return "sothebys";
  }
}
function formatDemoBrokerage(brokerage) {
  if (brokerage === "berkshire") {
    return "Berkshire Hathaway Home Services California Properties";
  } else if (brokerage === "compass") {
    return "COMPASS";
  } else if (brokerage === "village-properties") {
    return "Village Properties";
  }
}
function formatDemoEmail(brokerage) {
  if (brokerage === "berkshire") {
    return "sarah@berkshirehathaway.com";
  } else if (brokerage === "compass") {
    return "sarah@compass.com";
  } else if (brokerage === "village-properties") {
    return "sarah@villageproperties.com";
  }
}
function isUhrig(listgenUserID) {
  return listgenUserID === "100568";
}
function isScar(listgenUserID) {
  return listgenUserID === "100588";
}

function agentSignoffForGlobalNote(
  agentData,
  mailproAgentSignoff,
  useTeamName
) {
  const {
    isMemberOfTeam,
    teamMembers,
    listgenUserID,
    listgenUserFirstName,
    teamName,
  } = agentData;
  let agentSignoff = "";

  if (listgenUserID === "102843") {
    agentSignoff = "The Galli Team";
  } else if (listgenUserID === "100190") {
    agentSignoff = "Kathleen and Paul";
  } else if (isMemberOfTeam && teamMembers[1] === "singleagentteam") {
    agentSignoff = listgenUserFirstName;
  } else if (listgenUserID === "104626") {
    agentSignoff = "Kristen and Mary Ann";
  } else if (listgenUserID === "102712") {
    agentSignoff = "Kazemi Team";
  } else if (listgenUserID === "102340") {
    agentSignoff = "The Bonafede Team";
  } else if (listgenUserID === "104504") {
    agentSignoff = `Gina and Samantha`;
  } else if (isMemberOfTeam && teamMembers.length >= 2) {
    if (teamName && teamName.length > 3) {
      if (useTeamName) {
        agentSignoff = teamName;
      } else {
        agentSignoff = agentFirstNames(listgenUserFirstName, teamMembers);
      }
    } else if (listgenUserID === "100329") {
      agentSignoff = "Kit & Sue";
    } else if (isScar(listgenUserID)) {
      agentSignoff = "Team Scarborough";
    } else if (mailproAgentSignoff) {
      agentSignoff = mailproAgentSignoff;
    } else {
      agentSignoff = agentFirstNames(listgenUserFirstName, teamMembers);
    }
  } else if (isUhrig(listgenUserID)) {
    agentSignoff = "Bryan";
  } else if (listgenUserID === "100329") {
    agentSignoff = "Kit & Sue";
  } else if (mailproAgentSignoff) {
    agentSignoff = mailproAgentSignoff;
  } else {
    // agentSignoff = agentSignoffForGlobalNote(agentData, null, false);
    agentSignoff = listgenUserFirstName;
  }
  return agentSignoff;
}
function formatAddress(list, mlsAreaID) {
  let isCountryClubGate = mlsAreaID === "132" ? true : false;
  return `${emptyValueCheck(list.streetNumber)}   ${emptyValueCheck(
    list.streetName
  )} ${list.streetSuffix && formatSuffix(list.streetSuffix, mlsAreaID)} ${
    (!isCountryClubGate &&
      list.propertyType === "Condominium" &&
      list.unitNumber !== "-") ||
    (list.propertyType === "Condo/Co-Op" && list.unitNumber !== "-") ||
    (!isCountryClubGate &&
      list.propertyType === "Townhouse" &&
      list.unitNumber !== "-")
      ? `, #${list.unitNumber}`
      : ""
  }`;
}
function getCounty(city) {
  if (
    city.includes("Carmel Valley") ||
    city.includes("Pacific Grove") ||
    city.includes("Pebble Beach") ||
    city.includes("Monterey") ||
    city.includes("Salinas") ||
    city.includes("Seaside") ||
    city.includes("Marina") ||
    city.includes("Carmel Highlands") ||
    city.includes("Carmel")
  ) {
    return "Monterey";
  } else if (
    city.includes("APTOS") ||
    city.includes("SANTA CRUZ") ||
    city.includes("WATSONVILLE")
  ) {
    return "Santa Cruz";
  } else {
    return "Santa Barbara";
  }
}
function isValidHOName(name) {
  if (name !== "-" && name && name.length >= 1) {
    return true;
  } else {
    return false;
  }
}
function getSalutation(version, listgenUserID) {
  if (listgenUserID === "105789") {
    return "Kindly";
  } else if (listgenUserID === "102843") {
    return "We hope to work with you in the future";
  } else if (listgenUserID === "102808") {
    return "Here's to new beginnings";
  } else if (listgenUserID === "100374" || listgenUserID === "100656") {
    return "Your Neighbor";
  } else if (listgenUserID === "106543") {
    return "Warm Regards";
  } else if (listgenUserID === "104835") {
    return "Best";
  } else {
    return "Best regards";
  }
}

function refactorNotePSWithRemovalBeforeSubmit(inputNoteorPS) {
  //we need to remove all line breaks that we left in end.. not in middle.
  //Also, there is case where agent wants multiple line breaks in middle
  //In future we can have multiple break by indentifier
  //wrost case example
  //"<p>California has turned green practically overnight and it feels wonderful to have a fresh and vibrant atmosphere back. This year we’re expecting more stability in the market.&nbsp;</p><p><br></p><p>The number of available homes for sale will continue to remain LOW which will help keep home prices UP. &nbsp;Low inventory throughout the year will continue to give sellers an edge.&nbsp;</p><p><br></p><p><br></p>"
  const identifier = "<p><br></p>";
  if (inputNoteorPS && inputNoteorPS.includes(identifier)) {
    let breakByIdentifier = inputNoteorPS.split(identifier);
    inputNoteorPS = breakByIdentifier[0];
    let isNoteAppendStart = true,
      identifierRepeatStringInMiddle = identifier,
      remainingArray = breakByIdentifier.slice(1);
    remainingArray.map((item, key) => {
      if (item === "" || item === "\n" || !item) {
        isNoteAppendStart = false;
      } else {
        isNoteAppendStart = true;
      }

      if (isNoteAppendStart) {
        inputNoteorPS += identifierRepeatStringInMiddle + item;
        identifierRepeatStringInMiddle = identifier;
      } else {
        identifierRepeatStringInMiddle += identifier;
      }
    });
  }
  return inputNoteorPS;
}

function changeDetailsHOName(details, fullName, mailingDetails) {
  //console.log("*mailingdetails", mailingDetails);
  function removeHOTrust(name, hoNum) {
    if (name.includes("(te)") || name.includes("Te")) {
      let newFirst = `${name.split(" ")[1]}`;
      let newLast = name.split(" ")[0];
      //console.log("newLast", newLast);
      // }`;
      //console.log("new name is", newFirst);
      let organizedFirst = newFirst.replace(/(\(te)\)/g, "");
      let organizedLast = newLast.replace(/(\(te)\)/g, "");
      // console.log("inside organized", organizedFirst);
      // console.log("organizedFirst", organizedFirst);
      // console.log("organizedLast", organizedLast);
      if (hoNum === 1) {
        lgOwnerFirstName = organizedFirst;
        if (isValidHOName(organizedLast)) {
          lgOwnerLastName = organizedLast;
        }
      } else if (hoNum === 2) {
        lgSecondaryOwnerFirstName = organizedFirst;
        if (isValidHOName(organizedLast)) {
          lgSecondaryOwnerLastName = organizedLast;
        }
      }
      return organizedFirst;
    }
  }

  //console.log("details", details);
  let {
    lgOwnerFirstName,
    lgOwnerLastName,
    lgSecondaryOwnerFirstName,
    lgSecondaryOwnerLastName,
  } = details || {};
  // console.log("lgOwnerFirstName", lgOwnerFirstName);
  // console.log("lgOwnerLastName", lgOwnerLastName);
  // console.log("lgSecondaryOwnerFirstName", lgSecondaryOwnerFirstName);
  // console.log("lgSecondaryOwnerLastName", lgSecondaryOwnerLastName);

  function trimInitial(name) {
    //console.log("****name", name.split(""));
    name = name.trim();
    let removedInitial = name.split(" ")[0];
    //console.log("removedInitial", removedInitial);
    return removedInitial;
  }
  function separateNames(name, hoNum) {
    if (name.includes("&")) {
      let split = name.split("&");
      // let name1 = split[0].split(" ")[0];
      let name1 = trimInitial(split[0]);

      let name2 = trimInitial(split[1]);
      //.split(" ")[0].join("");
      lgOwnerFirstName = name1;
      if (isValidHOName(name2)) {
        lgSecondaryOwnerFirstName = name2;
      }

      if (isValidHOName(lgOwnerLastName)) {
        lgSecondaryOwnerLastName = lgOwnerLastName;
      }

      // console.log("name1", name1);
      // console.log("name2", name2);
      // console.log("lgSecondaryOwnerFirstName", lgSecondaryOwnerFirstName);
      // console.log("lgSecondaryOwnerLastName", lgSecondaryOwnerLastName);
    } else {
      let name1 = trimInitial(name);
      //  console.log("----name1", name1);
      lgOwnerFirstName = name1;
    }
  }

  //let determineSeparateNames1 = separateNames(lgOwnerFirstName, 1);

  //let determineSeparateNames2 = separateNames(lgSecondaryOwnerFirstName);
  let constructedName;
  if (fullName) {
    //if homeowners have the same last name, then "Kyle & Kristen Morrison"
    if (
      isValidHOName(lgOwnerFirstName) &&
      lgOwnerLastName &&
      isValidHOName(lgOwnerLastName) &&
      isValidHOName(lgSecondaryOwnerFirstName) &&
      lgSecondaryOwnerLastName &&
      isValidHOName(lgSecondaryOwnerLastName) &&
      lgOwnerLastName === lgSecondaryOwnerLastName
    ) {
      constructedName = [
        `${lgOwnerFirstName} & ${lgSecondaryOwnerFirstName} ${lgOwnerLastName}`,
      ];
    } else {
      if (isValidHOName(lgOwnerFirstName)) {
        let isLNValid = isValidHOName(lgOwnerLastName) || "";

        constructedName = [
          `${lgOwnerFirstName} ${isLNValid && lgOwnerLastName}`,
        ];
        //console.log("constructedName", constructedName);
      }
      if (isValidHOName(lgSecondaryOwnerFirstName)) {
        let isLNValid = isValidHOName(lgSecondaryOwnerLastName) || "";
        constructedName = [
          ...constructedName,
          `${lgSecondaryOwnerFirstName} ${
            isLNValid && lgSecondaryOwnerLastName
          }`,
        ];
        //  console.log("constructedName", constructedName);
      }
    }
  } else {
    if (isValidHOName(lgOwnerFirstName)) {
      let isLNValid = isValidHOName(lgOwnerLastName) || "";

      constructedName = lgOwnerFirstName;
      //console.log("constructedName", constructedName);
    }
    if (isValidHOName(lgSecondaryOwnerFirstName)) {
      let isLNValid = isValidHOName(lgSecondaryOwnerLastName);
      if (isLNValid) {
        constructedName += ` & ${lgSecondaryOwnerFirstName}`;
      }

      //  console.log("constructedName", constructedName);
    }
  }

  //console.log("lgOwnerFirstName", lgOwnerFirstName);
  return constructedName;
}
function removeTrust(hoName, lastName) {
  // console.log("hoName", hoName);
  // console.log("last name is", lastName);
  let organizedName;
  if (hoName.includes("(te)") || hoName.includes("Te")) {
    let newName = `${hoName.split(" ")[1]} ${
      lastName ? hoName.split(" ")[0] : ""
    }`;
    organizedName = newName.replace(/(\(te)\)/g, "");
  } else {
    organizedName = hoName;
  }

  return organizedName;
}

function formatDaySuffix(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function getSubdivisionName(subdivisionName, mlsNeighborhood, slashSeparated) {
  let areaSplit = splitAreas(mlsNeighborhood).length;

  let area;

  // console.log("slashSeparated", slashSeparated);
  if (subdivisionName && subdivisionName.length > 1) {
    area = subdivisionName;
  } else if (areaSplit > 1) {
    if (slashSeparated) {
      area = slashSeparatedArea(mlsNeighborhood);
    } else {
      area = "Your Neighborhood";
    }
  } else {
    area = mlsNeighborhood;
  }

  return area;
}

function coverPageAreaFontSize(area) {
  let split = splitAreas(area);
  // console.log('split', split);
  let len = area.length;
  // console.log('area.length', len);
  if (len <= 19) {
    return "xl-cover-page-area-text";
  } else if (len <= 22) {
    return "large-cover-page-area-text";
  } else if (len <= 37) {
    return "medium-cover-page-area-text";
  } else if (len <= 45) {
    return "small-cover-page-area-text";
  } else if (len <= 100) {
    return "xs-cover-page-area-text";
  }
}

function speedometerAreaFontSize(area) {
  let split = splitAreas(area);
  // console.log('split', split);
  let len = area.length;
  // console.log('area.length', len);
  if (len <= 19) {
    return "xl-speedometer-area-text";
  } else if (len <= 22) {
    return "large-speedometer-area-text";
  } else if (len <= 37) {
    return "medium-speedometer-area-text";
  } else if (len <= 45) {
    return "small-speedometer-area-text";
  } else if (len <= 100) {
    return "xs-speedometer-area-text";
  }
}
function formatYENeighborhoodTitle(area) {
  let split = splitAreas(area);
  // console.log('split', split);
  let len = area.length;
  // console.log('area.length', len);
  if (len <= 19) {
    return "xl-ye-highlight-area-text";
  } else if (len <= 22) {
    return "large-ye-highlight-area-text";
  } else if (len <= 37) {
    return "medium-ye-highlight-area-text";
  } else if (len <= 45) {
    return "small-ye-highlight-area-text";
  } else if (len <= 100) {
    return "xs-ye-highlight-area-text";
  }
}

function shortenToYourNeighborhood(area, special, capitalize) {
  let areaCount = splitAreas(area).length;
  if (areaCount > 1) {
    if (special) {
      return special;
    } else {
      if (capitalize) {
        return "Your neighborhood";
      } else {
        return "your neighborhood";
      }
    }
  } else {
    return area;
  }
}

function calculateQSellersAdvantages(
  aCurrentPerc,
  aCurrentYear,
  cCurrentPerc,
  cCurrentYear
) {
  // console.log("aCurrentPerc", aCurrentPerc);
  // console.log("aCurrentYear", aCurrentYear);
  // console.log("cCurrentPerc", cCurrentPerc);
  // console.log("cCurrentYear", cCurrentYear);
  let areaSellersAdvantages = [];
  let citySellersAdvantages = [];
  // return "test";
  function salePriceAdvantage(perc, current, area) {
    perc = +perc;
    current = +current;

    // console.log('past', past);
    // console.log('current', current);
    if (perc >= -1) {
      if (area) {
        areaSellersAdvantages = [...areaSellersAdvantages, "AVG SALE PRICE"];
      } else {
        citySellersAdvantages = [...citySellersAdvantages, "AVG SALE PRICE"];
      }
    }
    // console.log('areaSellersAdvantages', areaSellersAdvantages);
    // console.log('citySellersAdvantages', citySellersAdvantages);
  }

  function domAdvantage(perc, current, area) {
    perc = +perc;
    current = +current;
    // console.log('past', past);
    // console.log('current', current);
    if (perc <= 1) {
      if (area) {
        areaSellersAdvantages = [...areaSellersAdvantages, "DAYS TO SELL"];
      } else {
        citySellersAdvantages = [...citySellersAdvantages, "DAYS TO SELL"];
      }
    }
    // console.log('areaSellersAdvantages', areaSellersAdvantages);
    // console.log('citySellersAdvantages', citySellersAdvantages);
  }

  function percentReceivedAdvantage(perc, current, area) {
    perc = +perc;
    current = +current;
    if (perc >= -1 || current >= 98) {
      if (area) {
        areaSellersAdvantages = [
          ...areaSellersAdvantages,
          "% RECEIVED OF ASKING",
        ];
      } else {
        citySellersAdvantages = [
          ...citySellersAdvantages,
          "% RECEIVED OF ASKING",
        ];
      }
    }
    // console.log('areaSellersAdvantages', areaSellersAdvantages);
    // console.log('citySellersAdvantages', citySellersAdvantages);
  }
  // function unitsSoldAdvantage(perc, current, area) {
  //   perc = +perc;
  //   current = +current;
  //
  //   if (perc >= 1) {
  //     if (area) {
  //       areaSellersAdvantages = [...areaSellersAdvantages, "UNITS SOLD"];
  //     } else {
  //       citySellersAdvantages = [...citySellersAdvantages, "UNITS SOLD"];
  //     }
  //   }
  // }
  //
  // console.log('Past year', aPastYear.AvgDaysOnMarket);
  // console.log('aCurrentYear', aCurrentYear);
  salePriceAdvantage(
    aCurrentPerc.PercentageAvgSalePrice,
    aCurrentYear.AvgSalePrice,
    true
  );
  domAdvantage(
    aCurrentPerc.PercentageAvgDaysOnMarket,
    aCurrentYear.AvgDaysOnMarket,
    true
  );
  percentReceivedAdvantage(
    aCurrentPerc.PercentageAvgPercentOfReceiving,
    aCurrentYear.AvgPercentOfReceiving,
    true
  );
  // unitsSoldAdvantage(
  //   aCurrentPerc.PercentageNumberOfHomesSold,
  //   aCurrentYear.HomesSold,
  //   true
  // );
  //FOR CITY
  salePriceAdvantage(
    cCurrentPerc.PercentageAvgSalePrice,
    cCurrentYear.AvgSalePrice,
    false
  );
  domAdvantage(
    cCurrentPerc.PercentageAvgDaysOnMarket,
    cCurrentYear.AvgDaysOnMarket,
    false
  );
  percentReceivedAdvantage(
    cCurrentPerc.PercentageAvgPercentOfReceiving,
    cCurrentYear.AvgPercentOfReceiving,
    false
  );
  // unitsSoldAdvantage(
  //   cCurrentPerc.PercentageNumberOfHomesSold,
  //   cCurrentYear.HomesSold,
  //   false
  // );
  // console.log('areaSellersAdvantages', areaSellersAdvantages);
  // console.log('citySellersAdvantages', citySellersAdvantages);
  return { areaSellersAdvantages, citySellersAdvantages };
}

function useAreaOrCityYEGraph(areaCityObj, mlsArea) {
  // console.log('areaCityObj', areaCityObj);
  const { areaSellersAdvantages, citySellersAdvantages } = areaCityObj;
  // let defaultToArea = ["1522155", "157", "108"];
  let defaultToArea = ["157"];
  if (defaultToArea.includes(mlsArea) || areaSellersAdvantages.length >= 2) {
    // console.log('returning area');
    return "area";
  } else {
    // console.log('returning city');
    return "city";
  }
  // let areaSellersAdvantages = (aPastYear, aCurrentYear);
}
function calculateThreeYEGraphs(placeToUse, areaCityObj) {
  // console.log('3 ye graphs');
  // console.log('place to use', placeToUse);
  // console.log('areaCityObj', areaCityObj);

  // console.log('sellersADvantages', sellersAdvantages);
  function addToList(list) {
    // console.log('***about to add to list', list);
    // if (list.length >= 3) {
    //   console.log('list is 3', list.length);
    //   return list;
    // }
    // list.slice(0, 3);
    // if (list.length <= 2 || !list.includes("AVG SALE PRICE")) {
    //   // console.log('*** LESS THAN 2', list);
    //   if (!list.includes("AVG SALE PRICE")) {
    //     list = ["AVG SALE PRICE", ...list.slice(0, 2)];
    //     addToList(list.slice(0, 3));
    //   }
    //   if (!list.includes("DAYS TO SELL") && list.length < 3) {
    //     // let modified = list.splice(1, 0, 'DAYS TO SELL');
    //     // console.log('modified', modified);
    //     list = [...list, "DAYS TO SELL"];
    //     // console.log('inside days to sell', list);
    //     addToList(list);
    //   }
    //   if (!list.includes("% RECEIVED OF ASKING") && list.length < 3) {
    //     // console.log('inside received');
    //     // let modified = list.splice(2, 0, '% RECEIVED OF ASKING');
    //
    //     list = [...list, "% RECEIVED OF ASKING"];
    //     addToList(list);
    //   }
    //   if (!list.includes("UNITS SOLD") && list.length < 3) {
    //     list = [...list, "UNITS SOLD"];
    //     addToList(list);
    //   }
    // } else {
    //   // console.log('goign to return list', list);
    //   return list;
    // }
    return ["AVG SALE PRICE", "DAYS TO SELL", "% RECEIVED OF ASKING"];
  }
  const { areaSellersAdvantages, citySellersAdvantages } = areaCityObj;
  if (placeToUse === "area") {
    // console.log('area');
    if (areaSellersAdvantages.length >= 3) {
      let sliced = addToList(areaSellersAdvantages.slice(0, 3));
      // let sliced = areaSellersAdvantages.slice(0, 3);
      // console.log('***area sliced', sliced);
      return sliced;
    } else {
      let filled = addToList(areaSellersAdvantages);
      // console.log('filled', filled);
      return filled;
    }
  } else {
    // console.log('city');
    //IF City
    if (citySellersAdvantages.length >= 3) {
      let sliced = citySellersAdvantages.slice(0, 3);
      // console.log('*** city sliced', sliced);
      return sliced;
    } else {
      // console.log('needs to be filled');
      let filled = addToList(citySellersAdvantages);
      // console.log('filled', filled);
      return filled;
    }
  }
}

function calculateYESellersAdvantages(
  aPastYear,
  aCurrentYear,
  cPastYear,
  cCurrentYear
) {
  // console.log('aPastYear', aPastYear);
  // console.log('aCurrentYear', aCurrentYear);
  // console.log('cPastYear', cPastYear);
  // console.log('cCurrentYear', cCurrentYear);
  let areaSellersAdvantages = [];
  let citySellersAdvantages = [];

  function salePriceAdvantage(past, current, area) {
    past = +past;
    current = +current;
    // console.log('past', past);
    // console.log('current', current);
    if (past < current) {
      if (area) {
        areaSellersAdvantages = [...areaSellersAdvantages, "AVG SALE PRICE"];
      } else {
        citySellersAdvantages = [...citySellersAdvantages, "AVG SALE PRICE"];
      }
    }
    // console.log('areaSellersAdvantages', areaSellersAdvantages);
    // console.log('citySellersAdvantages', citySellersAdvantages);
  }

  function domAdvantage(past, current, area) {
    past = +past;
    current = +current;
    // console.log('past', past);
    // console.log('current', current);
    if (past > current) {
      if (area) {
        areaSellersAdvantages = [...areaSellersAdvantages, "DAYS TO SELL"];
      } else {
        citySellersAdvantages = [...citySellersAdvantages, "DAYS TO SELL"];
      }
    }
    // console.log('areaSellersAdvantages', areaSellersAdvantages);
    // console.log('citySellersAdvantages', citySellersAdvantages);
  }

  function percentReceivedAdvantage(past, current, area) {
    past = +past;
    current = +current;
    // console.log('past', past);
    // console.log('current', current);
    let diff = calculateDiff(current, past);
    // console.log('diff', diff);
    if (diff >= -1) {
      if (area) {
        areaSellersAdvantages = [
          ...areaSellersAdvantages,
          "% RECEIVED OF ASKING",
        ];
      } else {
        citySellersAdvantages = [
          ...citySellersAdvantages,
          "% RECEIVED OF ASKING",
        ];
      }
    }
    // console.log('areaSellersAdvantages', areaSellersAdvantages);
    // console.log('citySellersAdvantages', citySellersAdvantages);
  }
  function unitsSoldAdvantage(past, current, area) {
    past = +past;
    current = +current;
    let diff = calculateDiff(current, past);
    if (diff >= 1) {
      if (area) {
        areaSellersAdvantages = [...areaSellersAdvantages, "UNITS SOLD"];
      } else {
        citySellersAdvantages = [...citySellersAdvantages, "UNITS SOLD"];
      }
    }
  }
  //
  // console.log('Past year', aPastYear.AvgDaysOnMarket);
  // console.log('aCurrentYear', aCurrentYear);
  salePriceAdvantage(aPastYear.AvgSalePrice, aCurrentYear.AvgSalePrice, true);
  domAdvantage(aPastYear.AvgDaysOnMarket, aCurrentYear.AvgDaysOnMarket, true);
  percentReceivedAdvantage(
    aPastYear.AvgPercentOfReceiving,
    aCurrentYear.AvgPercentOfReceiving,
    true
  );
  unitsSoldAdvantage(aPastYear.HomesSold, aCurrentYear.HomesSold, true);
  //FOR CITY
  salePriceAdvantage(cPastYear.AvgSalePrice, cCurrentYear.AvgSalePrice, false);
  domAdvantage(cPastYear.AvgDaysOnMarket, cCurrentYear.AvgDaysOnMarket, false);
  percentReceivedAdvantage(
    cPastYear.AvgPercentOfReceiving,
    cCurrentYear.AvgPercentOfReceiving,
    false
  );
  unitsSoldAdvantage(cPastYear.HomesSold, cCurrentYear.HomesSold, false);
  // console.log('areaSellersAdvantages', areaSellersAdvantages);
  // console.log('citySellersAdvantages', citySellersAdvantages);
  return { areaSellersAdvantages, citySellersAdvantages };
}

function highestSalesRange(range, highestSalePrice, lowercase) {
  // console.log("range", range);
  // console.log("highestSalePrice", highestSalePrice);
  if (range === "$100M" || range === "$100m" || +range > 10000000) {
    if (highestSalePrice) {
      return ` - ${roundSalePriceTenths(highestSalePrice, false, false)}`;
    } else {
      return "+";
    }
  } else {
    return ` - ${range}`;
  }
}
function removeSpaces(word) {
  let noSpaces = word.replace(/\s/g, "");
  // console.log('no Spaces', noSpaces);
  return noSpaces;
}
function filterOutSubdivision(areas, subdivision) {
  let theSplitAreas = splitAreas(areas);
  // console.log('**inside subdivision', subdivision);
  let noSpaceSubdivision = removeSpaces(subdivision);
  // console.log('noSpaceSubdivision', noSpaceSubdivision);

  let filteredOut = theSplitAreas.filter((area) => {
    // console.log('area is ', area);
    return removeSpaces(area) !== removeSpaces(subdivision);
  });
  let mappedUpperCase = filteredOut.map((area) => area.toUpperCase());
  // console.log('mappedUpperCase', mappedUpperCase);
  let joined = mappedUpperCase.join(" + ");

  // console.log('**the split Areas', theSplitAreas);
  // console.log('filteredOut', filteredOut);
  return joined;
}
function calculateDiff(curr, prev) {
  // console.log("calculateDiff", curr, prev);
  if (curr === "0" && prev === "0") {
    return "0";
  } else if (curr === "0") {
    return -100;
  } else if (prev === "0") {
    return 100;
  } else {
    curr = Math.round(curr);
    prev = Math.round(prev);
    let res = infinityCheck(Math.round(((curr - prev) / Math.abs(prev)) * 100));
    return res;
  }
}

function roundedPercChange(p, c) {
  let prev = Math.round(p);
  let curr = Math.round(c);
  let diff = ((curr - prev) / Math.abs(prev)) * 100;
  return Math.round(diff) || 0;
}
function countAreaChars(area) {
  //  console.log("area is", area);
  let len = area.length;
  // console.log('area.length', len);
  if (len <= 19) {
    return "xl-area-text";
  } else if (len <= 23) {
    return "large-area-text";
  } else if (len <= 42) {
    //34
    return "medium-area-text";
  } else if (len <= 100) {
    return "small-area-text";
  }
  return;
}

function headerStyles(area) {
  let len = area.length;
  // console.log('***len', len);
  if (len <= 19) {
    return "xl-area-text";
  } else if (len <= 22) {
    return "large-area-text";
  } else if (len <= 34) {
    return "medium-area-text";
  } else if (len <= 40) {
    return "small-area-text";
  } else if (len <= 45) {
    return "xs-area-text";
  } else if (len <= 100) {
    return "xxs-area-text";
  }
  return;
}

function blackBorder(brokerage) {
  if (
    brokerage === "Over the Moon Realty" ||
    brokerage === "Davi Properties Group" ||
    brokerage === "Shankle Real Estate Inc." ||
    brokerage === "Keller Williams"
  ) {
    return true;
  } else {
    return false;
  }
}
function plusOrMinus(num, special) {
  num = +num;
  if (special && num === -100) {
    return "";
  }
  if (num == 0 || num === Infinity) {
    if (special === "noChange") {
      return "No Change";
    } else {
      return "";
    }
  } else if (num > 0) {
    return "+";
  } else if (num < 0) {
    num = String(num);
    return "-";
  }
}
function infinityCheck(num, special) {
  if (special && num === -100) return 0;
  if (num === Infinity || isNaN(num) || num == -0) {
    return 0;
  } else {
    num = +num;
    if (num > 100) {
      return 100;
    } else {
      return +num;
    }
  }
}

function statusContingentToPending(status) {
  if (status === "Contingent" || status === "Pending") {
    return "Pending Sale";
  } else {
    return status;
  }
}

function acreOrSqft(lot, capitalized) {
  lot = Number(lot);
  if (lot === 43560) {
    return "Acre";
  } else if (lot >= 43560) {
    if (capitalized) {
      return "Acres";
    } else {
      return "ACRES";
    }
  } else {
    if (capitalized) {
      return "Sqft";
    } else {
      return "SQFT";
    }
  }
}
function getLongMonth(monthsAgo) {
  let formattedDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - monthsAgo,
    new Date().getDate()
  );
  return formattedDate.toLocaleString("default", {
    month: "long",
  });
}

function removeInitials(name) {
  if (name.includes(" ")) {
    let split = name.split(" ");
    let lastEl = split[split.length - 1];
    if (lastEl.length <= 2) {
      return split[0];
    } else {
      return split.join(" ");
    }
  } else {
    return name;
  }
}
function agentFirstNames(firstName, teamMembers) {
  if (!teamMembers) return firstName;
  if (teamMembers.length === 2) {
    return `${removeInitials(teamMembers[0].firstName)} and
    ${removeInitials(teamMembers[1].firstName)}`;
  }
  if (teamMembers.length === 3) {
    return `${removeInitials(teamMembers[0].firstName)},
    ${removeInitials(teamMembers[1].firstName)}, and ${removeInitials(
      teamMembers[2].firstName
    )}`;
  }
  if (teamMembers.length === 4) {
    return `${teamMembers[0].firstName}, ${teamMembers[1].firstName}, ${teamMembers[2].firstName}, and ${teamMembers[3].firstName}`;
  }
}

function shortenYear(year) {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "numeric",
  };
  let shortenedYear = new Date(year).toLocaleDateString("en", options);
  return shortenedYear;
}

function splitAreas(area) {
  // console.log('area', area);
  let areaSplit = area
    .split(" & ")
    .join("/")
    .split(", ")
    .join("/")
    .split(",")
    .join("/")
    .split(" / ")
    .join("/")
    .split("/");
  if (areaSplit[areaSplit.length - 1] === "") {
    areaSplit = areaSplit.slice(0, -1);
  }
  //console.log("areaSplit", areaSplit);
  return areaSplit;
}

function slashSeparatedArea(area, noSpace) {
  let splitArea = splitAreas(area);
  let areaLength = splitArea.length;
  if (noSpace) {
    // console.log('inside no space');
    if (areaLength === 1) {
      return area;
    } else if (areaLength === 2) {
      return `${splitArea[0]}/${splitArea[1]}`;
    } else if (areaLength === 3) {
      return `${splitArea[0]}/${splitArea[1]}/${splitArea[2]}`;
    } else if (areaLength === 4) {
      return `${splitArea[0]}/${splitArea[1]}/${splitArea[2]}/${splitArea[3]}`;
    } else {
      return area;
    }
  } else {
    if (areaLength === 1) {
      return area;
    } else if (areaLength === 2) {
      return `${splitArea[0]} / ${splitArea[1]}`;
    } else if (areaLength === 3) {
      return `${splitArea[0]} / ${splitArea[1]} / ${splitArea[2]}`;
    } else if (areaLength === 4) {
      return `${splitArea[0]} / ${splitArea[1]} / ${splitArea[2]} / ${splitArea[3]}`;
    } else {
      return area;
    }
  }
}

function commaSeparatedArea(area) {
  let splitArea = splitAreas(area);
  let areaLength = splitArea.length;
  if (areaLength === 1) {
    return area;
  } else if (areaLength === 2) {
    return `${splitArea[0]} and ${splitArea[1]}`;
  } else if (areaLength === 3) {
    return `${splitArea[0]}, ${splitArea[1]}, and ${splitArea[2]}`;
  } else if (areaLength === 4) {
    return `${splitArea[0]}, ${splitArea[1]}, ${splitArea[2]}, and ${splitArea[3]}`;
  } else {
    return area;
  }
}
function monthOrMonths(monthSupply, short) {
  if (parseInt(monthSupply) === 1) {
    if (short) {
      return "mo";
    } else {
      return "month";
    }
  } else {
    if (short) {
      return "mos";
    } else {
      return "months";
    }
  }
}

function endsWithS(name, caps, special, hasS) {
  if (hasS) {
    return "";
  }
  if (special) {
    if (special.slice(-1).toLowerCase() === "s") {
      return "'";
    }
  }
  if (name.slice(-1).toLowerCase() === "s") {
    return "'";
  } else {
    if (caps) {
      return "'S";
    } else {
      return "'s";
    }
  }
}
function shortenPresentedBy(name, brokerage, printVersion, presentedText) {
  console.log("presentedText", presentedText);
  let max;
  if (printVersion) {
    max = 66;
  } else {
    max = 57;
  }
  let presented;
  if (presentedText.length) {
    presented = presentedText;
  } else {
    presented = "Listing provided by "; //13 w/ space
  }
  let presentedCount = presented.length + 1; //adding space
  // let nameCount = name.split("").length + 1; //adding space
  let brokerageCount = brokerage.split("").length + 1;
  let otherCount = 4; //length of 'of' and 'by' with spaces
  let credit = "Source: MLSListings";
  let allButBrokerageCount = presentedCount + credit + otherCount;
  let leftover = max - allButBrokerageCount;

  if (brokerageCount >= leftover) {
    return `${presented} ${brokerage.slice(
      0,
      Number(leftover) - 3
    )} ${credit}...`;
  } else {
    return `${presented} ${brokerage} ${`       `} ${credit}`;
  }
}

function shortenPropType(type) {
  if (type.includes("Single")) return "(SF)";
  if (type === "Condominium") return "(C)";
  if (type.includes("Town")) return "(TH)";
  if (type === "Duplex") return "(2X)";
  if (type === "Triplex") return "(3X)";
  if (type === "Four") return "(4+)";
  if (type === "Other Residential ") return "(Other)";
  return type;
}

function shortenStatus(status, special, fullPending) {
  if (status === "Active") return "Active";
  if ((status === "Pending" || status == "Contingent") && fullPending) {
    return "Pending";
  }

  if (status === "Pending") return "Pend";
  if (status === "Contingent" && special === "pendingOnly") return "Pend";
  if (status === "Contingent") return "Contg";
  if (status === "Sold") return "Sold";
  return status;
}

function propTypeAcronym(type) {
  // console.log("type", type);
  if (type === "Apartment") {
    return "Apt";
  } else if (type === "Condominium") {
    return "Condo";
  } else if (type === "Single Family Home") {
    return "Sgl Family";
  } else if (type === "Duplex") {
    return "Duplex";
  } else if (type === "Triplex") {
    return "Triplex";
  } else if (type === "Quadplex") {
    return "4-Plex";
  } else if (type === "Five") {
    return "5-Plex";
  } else if (type === "Townhouse") {
    return "Townhouse";
  } else if (type === "Other Residential ") {
    return "Other";
  } else if (type === "ManufacturedHousing") {
    return "Manufactured";
  } else if (type === "Commercial Property") {
    return "Commercial";
  } else if (type === "PUD") {
    return "PlanUnitDev";
  } else if (
    type === "Residential Lot" ||
    type === "Residential Development Land"
  ) {
    return "Vacant Lot";
  }
  return type;
}

function formatPhoneNo(mobilePhoneNo) {
  if (mobilePhoneNo) {
    return `(${mobilePhoneNo.slice(0, 3)}) ${mobilePhoneNo.slice(
      3,
      6
    )} - ${mobilePhoneNo.slice(6)}`;
  } else {
    return "";
  }
}

function formatPhoneNoWithDashes(mobilePhoneNo) {
  if (mobilePhoneNo) {
    return `${mobilePhoneNo.slice(0, 3)}-${mobilePhoneNo.slice(
      3,
      6
    )}-${mobilePhoneNo.slice(6)}`;
  } else {
    return "";
  }
}
function formatPhoneNoWithPeriods(mobilePhoneNo) {
  if (mobilePhoneNo) {
    return `${mobilePhoneNo.slice(0, 3)}.${mobilePhoneNo.slice(
      3,
      6
    )}.${mobilePhoneNo.slice(6)}`;
  } else {
    return "";
  }
}

function splitPlace(place) {
  if (place) {
    let sliced = place.slice().split(",").join("/").split("/");
    return sliced;
  } else {
    return "";
  }
}
function calculateCityAreaLength(place) {
  if (place) {
    let sliced = place.slice().split(",").join("/").split("/");

    return sliced.length;
  } else {
    return "";
  }
}
function emptyValueCheck(inputString) {
  if (
    !inputString ||
    inputString === "" ||
    inputString === " " ||
    inputString === "0" ||
    inputString === "EmptyValue" ||
    inputString === "-" ||
    inputString === "NaN" ||
    inputString === "-" ||
    inputString === "null" ||
    inputString === null
  ) {
    return "";
  }
  return inputString;
}

function connectedHOName(mailingDetails, showLastName) {
  const { OwnerFirst, OwnerLast } = mailingDetails;

  let hasSecondHO = false;
  if (OwnerFirst.includes("&")) {
    hasSecondHO = true;
  }
  let oneHO, twoHO;
  oneHO = OwnerFirst.split("&")[0];
  if (oneHO.includes(" ")) {
    let split = oneHO.split(" ");
    let lastEl = split[split.length - 1];
    if (lastEl.length <= 1) {
      oneHO = split[0];
    } else {
      oneHO = split.join(" ");
    }
  }
  if (hasSecondHO) {
    twoHO = OwnerFirst.split("&")[1].split(" ")[1];
    return `${emptyValueCheck(oneHO)} & ${emptyValueCheck(twoHO)} ${
      showLastName ? emptyValueCheck(OwnerLast) : ""
    }`;
  } else {
    return `${emptyValueCheck(oneHO)} ${
      showLastName ? emptyValueCheck(OwnerLast) : ""
    }`;
  }
}

//original ho names wihtout nicknames. return full name
function originalHOName(mailingDetails) {
  const { OwnerFirst, OwnerLast } = mailingDetails;
  let hasSecondHO = false;
  let hasTwoSecondName = false;
  let twoHoData = {};
  //check for first name
  if (OwnerFirst.includes("&")) {
    hasSecondHO = true;
  }
  //check for last name
  if (OwnerLast.includes("&")) {
    hasTwoSecondName = true;
    twoHoData = originalHOSecondNameComplete(mailingDetails);
  }
  let oneHO, twoHO;
  oneHO = OwnerFirst.split("&")[0];
  //we want complete name so uncomment this one
  // if (oneHO.includes(" ")) {
  //   //oneHO = oneHO.split(' ')[0];
  // }
  if (hasSecondHO) {
    twoHO = OwnerFirst.split("&")[1]; //.split(' ')[1];
  }
  //put two second name check. If we have both second name then show one by one otherwise use ownerlast name
  let hos = {
    oneHO: `${emptyValueCheck(oneHO)} ${
      hasTwoSecondName ? twoHoData.oneHO : emptyValueCheck(OwnerLast)
    }`,
    twoHO: "",
  };
  if (hasSecondHO) {
    hos.twoHO = `${emptyValueCheck(twoHO)} ${
      hasTwoSecondName ? twoHoData.twoHO : emptyValueCheck(OwnerLast)
    }`;
  }
  return hos;
}

/**Return only first name */
function originalHONameComplete(mailingDetails) {
  const { OwnerFirst } = mailingDetails;
  let hasSecondHO = false;
  if (OwnerFirst.includes("&")) {
    hasSecondHO = true;
  }
  let oneHO, twoHO;
  oneHO = OwnerFirst.split("&")[0];
  if (hasSecondHO) {
    twoHO = OwnerFirst.split("&")[1];
  }
  return {
    oneHO: oneHO ? emptyValueCheck(oneHO.trim()) : "",
    twoHO: twoHO ? emptyValueCheck(twoHO.trim()) : "",
  };
}

/**Returns only second name */
function originalHOSecondNameComplete(mailingDetails) {
  const { OwnerLast } = mailingDetails;
  let hasSecondHO = false;
  if (OwnerLast.includes("&")) {
    hasSecondHO = true;
  }
  let oneHO, twoHO;
  oneHO = OwnerLast.split("&")[0];
  if (hasSecondHO) {
    twoHO = OwnerLast.split("&")[1];
  }
  //trim and apply the empty value check
  return {
    oneHO: oneHO ? emptyValueCheck(oneHO.trim()) : "",
    twoHO: twoHO ? emptyValueCheck(twoHO.trim()) : "",
  };
}

//for the note section. includes nickname also. returns first word only not full name in case of no nickname
function HONickname(changeDetails, mailingDetails, symbol) {
  const { OwnerFirst, OwnerLast } = mailingDetails;
  const { nickName } = changeDetails ? changeDetails : {};
  let newNickname1 =
    nickName && nickName[0] ? emptyValueCheck(nickName[0]) : "";
  let newNickname2 =
    nickName && nickName[1] ? emptyValueCheck(nickName[1]) : "";
  //handle special case first check special case where we reported that nickname is array and have two value. nickname 1 is "" and nickname is having nickname 1 values. in that case swap both values
  if (Array.isArray(nickName) && newNickname1 === "" && newNickname2 !== "") {
    newNickname1 = newNickname2;
    newNickname2 = "";
  }
  //build names now
  let hasSecondHO = false;
  // console.log(OwnerFirst, typeof OwnerFirst);
  if (
    OwnerFirst.includes("&") &&
    OwnerFirst.split("&")[1] &&
    OwnerFirst.split("&")[1].split(" ")[1] &&
    OwnerFirst.split("&")[1].split(" ")[1].length > 1
  ) {
    hasSecondHO = true;
  }
  let oneHO, twoHO;
  // console.log(OwnerFirst, newNickname1, newNickname2);
  // console.log(OwnerFirst.split("&"));
  // console.log(newNickname1.includes("&"), OwnerFirst.split("&"));
  //names are edited or having &
  if (changeDetails && newNickname1) {
    oneHO = newNickname1;
  } else {
    oneHO = OwnerFirst.split("&")[0];
    if (oneHO.includes(" ")) {
      oneHO = oneHO.split(" ")[0];
    }
  }
  //just put a check here if nickname[0] contains & then no need to show second name. In recent update server side started putting & if there's no nicknames. Otherwise server will sort the nickname in alphbetical order.
  if (hasSecondHO && !(changeDetails && newNickname1.includes("&"))) {
    if (changeDetails && newNickname2) {
      twoHO = newNickname2;
    } else {
      //" "[1] we are using because of this one: ["", "Sophia", "H"]
      twoHO = OwnerFirst.split("&")[1].split(" ")[1];
    }
    if (symbol) {
      return `${emptyValueCheck(oneHO)} ${symbol} ${emptyValueCheck(twoHO)}`;
    }
    return `${emptyValueCheck(oneHO)} & ${emptyValueCheck(twoHO)}`;
  }
  return `${emptyValueCheck(oneHO)}`;
}

//complete nicknames based html name
function EditedHomeownerName(nickName) {
  let newNickname1 =
    nickName && nickName[0] ? emptyValueCheck(nickName[0]) : "";
  let newNickname2 =
    nickName && nickName[1] ? emptyValueCheck(nickName[1]) : "";
  //handle special case first check special case where we reported that nickname is array and have two value. nickname 1 is "" and nickname is having nickname 1 values. in that case swap both values
  if (Array.isArray(nickName) && newNickname1 === "" && newNickname2 !== "") {
    newNickname1 = newNickname2;
    newNickname2 = "";
  }
  //now send data
  /**If edited by agent then send nicknames data otherwise empty */
  if (newNickname1 !== "" && newNickname2 !== "") {
    return (
      emptyValueCheck(newNickname1) + " & " + emptyValueCheck(newNickname2)
    );
  } else if (newNickname1 !== "" && newNickname2 === "") {
    return emptyValueCheck(newNickname1);
  } else if (newNickname1 === "") {
    //we dont have to check newNickname2 === " case becuase it wont be possible. if newNickname1 is empty then send "" in the table because nothing to show
    return "";
  }
}

function nicknameWithOwnerLastName(mailingDetails, changeDetails) {
  const { OwnerFirst, OwnerLast } = mailingDetails;

  const { nickName } = changeDetails ? changeDetails : {};
  let newNickname1 =
    nickName && nickName[0] ? emptyValueCheck(nickName[0]) : "";
  let newNickname2 =
    nickName && nickName[1] ? emptyValueCheck(nickName[1]) : "";
  //handle special case first check special case where we reported that nickname is array and have two value. nickname 1 is "" and nickname is having nickname 1 values. in that case swap both values
  if (Array.isArray(nickName) && newNickname1 === "" && newNickname2 !== "") {
    newNickname1 = newNickname2;
    newNickname2 = "";
  }
  //there is a special case whiel getting back data from server both nickname will come with & and array elngth would be 1 so in that case break and use as nicknames e.g ["Hari1 & Hari2"] nad while sending back we are sending back as ["Hari1", "Hari2"]
  [newNickname1, newNickname2] =
    newNickname1.includes("&") &&
    Array.isArray(nickName) &&
    nickName.length === 1
      ? newNickname1.split("&")
      : [newNickname1, newNickname2];
  //trim the nicknames
  newNickname1 = newNickname1.trim();
  newNickname2 = newNickname2.trim();
  //get the last names
  let secondHoNames = originalHOSecondNameComplete(mailingDetails);
  return {
    oneHO: newNickname1 ? `${newNickname1} ${secondHoNames.oneHO}`.trim() : "",
    twoHO: newNickname2
      ? `${newNickname2} ${
          secondHoNames.twoHO ? secondHoNames.twoHO : secondHoNames.oneHO
        }`.trim()
      : "",
  };
}

function posOrNeg(num, category, miniNeg) {
  num = Math.round(+num);
  // if (category === "% REC'D OF ASKING") {
  //   if (num === 0) {
  //     return num;
  //   } else if (num > 0) {
  //     return `${num}`;
  //   } else {
  //     num = String(tenthsPlace(num)).slice(1);
  //     return `${num}`;
  //   }
  // }

  if (num === 0) {
    return num;
  } else if (num > 0) {
    return `+${num}`;
  } else {
    num = String(num);
    if (miniNeg) {
      return `-${num.slice(1)}`;
    } else {
      return `—${num.slice(1)}`;
    }
  }
}

function currentQuarterAvg(category) {
  if (category === "SALE PRICE") {
    return "AverageSalePrice";
  } else if (category === "% REC'D OF ASKING") {
    return "AveragePercentOfReceiving";
  } else if (category === "PRICE PER SQ FT") {
    return "AveragePricePerSqFt";
  } else if (category === "DAYS ON MARKET") {
    return "AverageDaysOnMarket";
  }
}

function categoryPercentage(category) {
  if (category === "SALE PRICE") {
    return "AvgSalePricePercentage";
  } else if (category === "% REC'D OF ASKING") {
    return "AvgPercentOfReceivingPercentage";
  } else if (category === "PRICE PER SQ FT") {
    return "AvgPricePerSqFtPercentage";
  } else if (category === "DAYS ON MARKET") {
    return "AvgDaysOnMarketPercentage";
  }
}
function extractQuarterName(quarter) {
  return quarter.slice(-2);
}
function extractQuarterNumber(quarter) {
  let num = +quarter.slice(-1);
  if (num === 1) {
    return "1ST";
  } else if (num === 2) {
    return "2ND";
  } else if (num === 3) {
    return "3RD";
  } else if (num === 4) {
    return "4TH";
  }
}
function extractQuarterYear(quarter) {
  let year = quarter.slice(0, 4);
  return year;
}

function marketTrendShowPercent(category) {
  if (category === "SALE PRICE" || category === "% REC'D OF ASKING") {
    return true;
  }
  return false;
}

function marketTrendShowPrice(category) {
  if (
    category === "SALE PRICE" ||
    category === "PRICE PER SQ FT" ||
    category === "DAYS ON MARKET"
  ) {
    return true;
  }
  return false;
}
//DOM (days on market), REC ()
function determineTrendHeader(salePrice, dom, rec, inventory, unitsSold) {
  let headers = {
    posForSellers: 0,
    posForBuyers: 0,
    balancedMarket: 0,
  };
  // console.log('salePrice', salePrice);
  // console.log('dom', dom);
  // console.log('rec', rec);
  // console.log('inventory', inventory);
  // console.log('unitsSold', unitsSold);

  // BALANCED MARKET
  if (salePrice > -5 && salePrice < 5) {
    headers.balancedMarket += 60;
  }
  if (dom > -20 && dom < 20) {
    headers.balancedMarket += 20;
  }
  if (unitsSold && unitsSold > -10 && unitsSold < 10) {
    headers.balancedMarket += 10;
  } else if (rec > -10 && rec < 10) {
    headers.balancedMarket += 10;
  }
  if (inventory === "BALANCED") {
    headers.balancedMarket += 10;
  }

  // POS FOR SELLERS
  if (salePrice > 5) {
    headers.posForSellers += 60;
  }
  if (dom < -20) {
    headers.posForSellers += 20;
  }
  if (unitsSold && unitsSold > 10) {
    headers.posForSellers += 10;
  } else if (rec > 10) {
    headers.posForSellers += 10;
  }

  if (inventory === "LOW") {
    headers.posForSellers += 10;
  }

  // POS FOR BUYERS
  if (salePrice < -5) {
    headers.posForBuyers += 60;
  }
  if (dom > 20) {
    headers.posForBuyers += 20;
  }
  if (unitsSold && unitsSold < -10) {
    headers.posForBuyers += 10;
  } else if (rec < -10) {
    headers.posForBuyers += 10;
  }

  if (inventory === "HIGH") {
    headers.posForBuyers += 10;
  }

  let finalHeader = Object.keys(headers).reduce(function (a, b) {
    return headers[a] > headers[b] ? a : b;
  });

  return finalHeader;
  // console.log('headers', headers);
  // console.log('finalHeader', finalHeader);
  // if (finalHeader === 'posForBuyers') {
  //   return 'TRENDING POSITIVELY FOR BUYERS';
  // } else if (finalHeader === 'posForSellers') {
  //   return 'TRENDING POSITIVELY FOR SELLERS';
  // } else if (finalHeader === 'balancedMarket') {
  //   return 'BALANCED MARKET - NO SPECIFIC ADVANTAGE TO BUYERS OR SELLERS';
  // }
}

function determineArrow(num) {
  num = Number(num);
  if (num > 0) {
    return eval("<MiniUpArrow />");
  } else if (num < 0) {
    return "<MiniDownArrow />";
  } else if (num === 0) {
    return "<MiniTwoWayArrow />";
  }
}

function determineInventory(supply, short) {
  supply = Number(supply);
  if (supply < 4) {
    return "LOW";
  } else if (supply >= 4 && supply <= 6) {
    if (short) {
      return "BAL";
    } else {
      return "BALANCED";
    }
  } else {
    return "HIGH";
  }
}

function tenthsPlace(num) {
  if (putDash(num) === "-") {
    return "-";
  } else {
    let res = parseFloat(((num * 10) / 10).toFixed(1));
    if (res % 1 == 0) {
      return Math.round(res);
    } else {
      return res;
    }
  }
}
function putDash(num, commas, special) {
  if (
    num === 0 ||
    num === "0" ||
    num === undefined ||
    !num ||
    num === NaN ||
    num === "0.0" ||
    num === "$0K"
  ) {
    if (special === "space-left") {
      return `   ---`;
    } else if (special === "space-right") {
      return `---   `;
    } else {
      return "---";
    }
  } else {
    if (commas) {
      return Number(num).toLocaleString();
    } else {
      return num;
    }
  }
}
function calculateRoundedTenths(price, noSign) {
  if (price >= 999000) {
    if (price >= 10000000) {
      return `${Number((price / 1000000).toFixed(1)) * 1000}`;
    } else {
      return `${Number((price / 1000000).toFixed(1)) * 1000}`;
    }
  } else {
    if (noSign) {
      return `${Math.round(price / 1000)}`;
    } else {
      return `${Math.round(price / 1000)}`;
    }
  }
}
function roundSalePriceTenths(
  price,
  noSign,
  lowerCase,
  addSpace,
  onlyLCK,
  fixedNum
) {
  // console.log('price**', price);
  let numFixed = fixedNum ? fixedNum : 1;
  if (price >= 999000) {
    if (noSign) {
      if (price >= 10000000) {
        return `${Number((price / 1000000).toFixed(numFixed))}M`;
      } else {
        return `${Number((price / 1000000).toFixed(numFixed))}M`;
      }
    } else {
      if (price >= 10000000) {
        return addSpace
          ? `$${Number((price / 1000000).toFixed(numFixed))} ${
              lowerCase ? "m" : "M"
            }`
          : `$${Number((price / 1000000).toFixed(numFixed))}${
              lowerCase ? "m" : "M"
            }`;
      } else {
        return addSpace
          ? `$${Number((price / 1000000).toFixed(numFixed))} ${
              lowerCase ? "m" : "M"
            }`
          : `$${Number((price / 1000000).toFixed(numFixed))}${
              lowerCase ? "m" : "M"
            }`;
      }
    }
  } else {
    if (noSign) {
      return `${Math.round(price / 1000)}K`;
    } else {
      return addSpace
        ? `$${Math.round(price / 1000)} ${lowerCase || onlyLCK ? "k" : "K"}`
        : `$${Math.round(price / 1000)}${lowerCase || onlyLCK ? "k" : "K"}`;
    }
  }
}

function roundSalePrice(
  price,
  noSign,
  special,
  lowerCase,
  onlyLCK,
  writtenOut
) {
  if (special === "zero-dash" && price === "0") {
    return 0;
  }
  if (price >= 999000) {
    if (noSign) {
      if (price >= 10000000) {
        return `${Number((price / 1000000).toFixed(1))}M`;
      } else {
        return `${Number((price / 1000000).toFixed(2))}M`;
      }
    } else {
      if (price >= 10000000) {
        if (special === "writtenOut") {
          return `$${Number((price / 1000000).toFixed(1))} Million`;
        } else {
          return `$${Number((price / 1000000).toFixed(1))}${
            lowerCase ? "m" : "M"
          }`;
        }
      } else {
        return `$${Number((price / 1000000).toFixed(2))}${
          lowerCase ? "m" : "M"
        }`;
      }
    }
  } else {
    if (noSign) {
      return `${Math.round(price / 1000)}K`;
    } else {
      if (special === "writtenOut") {
        return `$${Math.round(price / 1000)} Thousand`;
      } else {
        return `$${Math.round(price / 1000)}${
          lowerCase || onlyLCK ? "k" : "K"
        }`;
      }
    }
  }
}

function cutName(name) {
  if (name === "EmptyValue") {
    return "";
  } else if (name.length > 15) {
    return `${name.slice(0, 17)}. `;
  } else {
    return name;
  }
}
function toAcre(sqft, justNumber, alwaysAcre, showSqft, toAC) {
  if (sqft === "-") return "—";
  sqft = parseInt(sqft);
  if (sqft >= 43560 || alwaysAcre) {
    let acre = Number((sqft / 43560).toFixed(1));
    if (justNumber) {
      return acre;
    } else {
      if (toAC) {
        return `${acre} ac`;
      }
      return `${acre} acre${acre === 1 ? "" : "s"}`;
    }
  } else {
    if (showSqft) {
      return `${Math.round(sqft).toLocaleString()} SqFt`;
    }
    return Math.round(sqft).toLocaleString();
  }
}
function cutZeroAddress(add, areaID) {
  let split = add.split(" ");
  //if within carmel
  if (
    areaID === "141" ||
    areaID === "142" ||
    areaID === "143" ||
    areaID === "144"
  ) {
    //if the address starts with a 0
    if (split[0] === "0") {
      return split.slice(1).join(" ");
    } else {
      return add;
    }
  } else {
    if (add.length >= 20) {
      return `${add.slice(0, 20)}...`;
    } else {
      return add;
    }
  }
}
function formatBaths(bath, getObj, showHalf) {
  if (bath === "-/-") {
    return "—";
  }

  let bed = bath.split("/")[0];
  let baths = bath.split("/")[1];
  let newBaths;
  // else if (baths % 1 !== 0) {
  //   newBaths = `${parseInt(baths)}+`;
  // }
  if (!baths) {
    newBaths = 0;
  } else if (baths == "null") {
    newBaths = "—";
  } else {
    if (showHalf) {
      newBaths = parseFloat(baths);
    } else {
      newBaths = parseInt(baths);
    }
  }
  let newBeds;
  if (bed === "null" || !bed) {
    newBeds = "—";
  } else {
    newBeds = bed;
  }
  let obj = {
    bed: newBeds,
    bath: newBaths,
  };
  if (baths === "2.0") {
    let updatedBath = baths;
  }
  if (getObj) return obj;
  return `${newBeds} / ${newBaths}`;
}

function formatSuffix(suffix, areaID) {
  // if (
  //   areaID === '141' ||
  //   areaID === '142' ||
  //   areaID === '143' ||
  //   areaID === '144'
  // ) {
  //   if (suffix === 'AVE' || suffix === 'Avenue') {
  //     return 'AVE';
  //
  //   } else {
  //     return suffix;
  //   }
  // } else
  //
  if (suffix === "del Ora") {
    return " del Ora";
  } else if (suffix === "-") {
    return "";
  } else {
    let split;
    if (!suffix) {
      return "";
    } else {
      split = ` ${suffix.slice(0, 1)}${suffix.slice(1).toLowerCase()}`;
    }

    return split;
  }
  return suffix;
}
function homesNumber(
  homesForSale,
  lastNum,
  background,
  city,
  maxCity,
  citySale,
  avgCity,
  avgArea
) {
  let max;
  let scale;
  if (city) {
    if (Number(avgCity) === 0) {
      scale = 1;
    } else {
      scale = Number(avgCity);
    }
  } else {
    if (Number(avgArea) === 0) {
      scale = 1;
    } else {
      scale = Number(avgArea);
    }
  }

  const fullBar = scale * 10;
  // console.log("fullBar", fullBar);
  const firstThird = Math.max(scale * 4, 0.1);
  // console.log("firstThird", firstThird);
  const secondThird = Math.max(scale * 6, 4.1);
  // console.log("secondThird", secondThird);
  // console.log("background", background);
  // console.log("homesForSale", homesForSale);
  // console.log("firstThird", firstThird);
  if (background) {
    if (homesForSale < firstThird) {
      return ["sold", "Low"];
    } else if (homesForSale <= secondThird) {
      return ["active", "Balanced"];
    } else {
      return ["primary", "High"];
    }
  } else {
    if (Number(homesForSale) === 0) {
      return 1;
    } else {
      return Math.round((100 / fullBar) * homesForSale);
    }
  }
}

function filterBrokerage(firm, short) {
  //  console.log("firm is", firm);
  if (firm === "intero") {
    return "INTERO Real Estate Services";
  } else if (firm === "village-properties") {
    return "Village Properties";
  } else if (firm === "cb") {
    return "Coldwell Banker Realty";
  } else if (firm === "compass") {
    return "COMPASS";
  } else if (firm.includes("Town") && firm.includes("Country")) {
    return "Town ‘N Country Realty";
  } else if (firm.includes("Sotheby's")) {
    return "Sotheby's International Realty";
  } else if (firm.includes("Compass")) {
    return "COMPASS";
  } else if (firm.includes("Coldwell") && firm.includes("Dales")) {
    return "Coldwell Banker/Gay Dales Inc.";
    // return 'Coldwell Banker';
  } else if (firm.includes("Coldwell")) {
    return "Coldwell Banker Realty";
  } else if (firm.includes("Carmel")) {
    return "Carmel Realty";
  } else if (firm.includes("Keller")) {
    return "Keller Williams";
  } else if (firm.includes("Shankle")) {
    return "Shankle Real Estate, Inc.";
  } else if (firm.includes("Intero")) {
    return "Intero Real Estate";
  } else if (firm.includes("Mont") && firm.includes("Coast")) {
    return "Mont. Coast Realty";
  } else if (firm.includes("Centry") && firm.includes("21")) {
    return "Century 21 RE";
  } else if (firm.includes("Legacy")) {
    return "Legacy Real Estate";
  } else if (firm.includes("Jones") && firm.includes("Group")) {
    return "The Jones Group";
  } else if (firm.includes("Over") && firm.includes("Moon")) {
    return "Over the Moon Realty, Inc.";
  } else if (firm.includes("San Carlos")) {
    return "San Carlos Agency";
  } else if (firm.includes("Davi") && firm.includes("Properties")) {
    return "Davi Properties";
  } else if (firm.includes("Cardinallli")) {
    return "Cardinalli Realty";
  } else if (firm.includes("Preferred") && firm.includes("Properties")) {
    return "Preferred Properties";
  } else if (firm.includes("Mont") && firm.includes("Grove")) {
    return "Mont Grove Realty";
  } else if (firm.includes("D.") && firm.includes("Lyng")) {
    return "D. Lyng Real Estate";
  } else if (firm.includes("Sereno") && firm.includes("Group")) {
    return "Sereno Group";
  } else if (firm.includes("Berkshire") || firm.includes("berkshire")) {
    return "Berkshire Hathaway HomeServices California Properties";
  } else if (firm.includes("Village Properties")) {
    return "Village Properties";
  } else {
    return firm;
  }
}

function filterAddress(add) {
  let cardinals = ["N", "NE", "NW", "S", "SE", "SW", "E", "W"];
  let firstEl = add.split(" ")[0];
  let removeCardinal;
  if (cardinals.includes(firstEl)) {
    removeCardinal = add.split(" ").slice(1);
  } else {
    removeCardinal = add.split(" ");
  }
  let lastEl = removeCardinal[removeCardinal.length - 1];
  let suffixes = ["LN", "DR", "CT", "AVE", "WAY", "RD", "ST"];
  let final;
  if (suffixes.includes(lastEl)) {
    final = removeCardinal.slice(0, removeCardinal.length - 1);
  } else {
    final = removeCardinal.slice();
  }
  return final.join(" ");
}

function hasWaterView(view) {
  let viewPriority = false;

  if (
    view.includes("Ocean") ||
    view.includes("Bay") ||
    view.includes("Golden Gate") ||
    view.includes("Waterfront") ||
    view.includes("Marina") ||
    view.includes("Lake")
  ) {
    viewPriority = true;
  }
  return viewPriority;
}
function getPropView(propView) {
  let viewPriority;
  if (propView.includes("Waterfront")) {
    viewPriority = "Waterfront";
  } else if (propView.includes("Golden Gate")) {
    viewPriority = "Golden Gate";
  } else if (propView.includes("Bay Bridge")) {
    viewPriority = "Bay Bridge";
  } else if (propView.includes("Carquinez")) {
    viewPriority = "Carquinez";
  } else if (propView.includes("Ocean")) {
    viewPriority = "Ocean";
  } else if (propView.includes("Marina")) {
    viewPriority = "Marina";
  } else if (propView.includes("Bay")) {
    viewPriority = "Bay";
  } else if (propView.includes("Lake")) {
    viewPriority = "Lake";
  } else if (propView.includes("Golf Course")) {
    viewPriority = "Golf Course";
  } else if (propView.includes("River/Stream")) {
    viewPriority = "River/Stream";
  } else if (propView.includes("Delta")) {
    viewPriority = "Delta";
  } else if (propView.includes("Las Trampas Foothills")) {
    // viewPriority = 'Las Trampas Foothills';
    viewPriority = "Las Trampas";
  } else if (propView.includes("Mt.Diablo")) {
    viewPriority = "Mt. Diablo";
  } else if (propView.includes("Mt. Tamalpias")) {
    viewPriority = "Mt. Tamalpias";
  } else if (propView.includes("Twin Peaks")) {
    viewPriority = "Twin Peaks";
  } else if (propView.includes("Mountain")) {
    viewPriority = "Mountains";
  } else if (propView.includes("Downtown")) {
    viewPriority = "Downtown";
  } else if (propView.includes("Bridge")) {
    viewPriority = "Bridge";
  } else if (propView.includes("City Lights")) {
    viewPriority = "City Lights";
  } else if (propView.includes("Park")) {
    viewPriority = "Park";
  } else if (propView.includes("Canyon")) {
    viewPriority = "Canyon";
  } else if (propView.includes("Forest/Woods")) {
    viewPriority = "Forest/Woods";
  } else if (propView.includes("Pasture")) {
    viewPriority = "Pasture";
  } else if (propView.includes("Hills")) {
    viewPriority = "Hills";
  } else if (propView.includes("Green Belt")) {
    viewPriority = "Green Belt";
  } else if (propView.includes("Setting")) {
    viewPriority = "Setting";
  } else if (propView.includes("City")) {
    viewPriority = "City";
  } else {
    viewPriority = " —";
  }
  return viewPriority;
}

function marketHighlights(
  cityData,
  cityName,
  areaData,
  mlsNeighborhood,
  areaSupplyTable
) {
  let city1 = "";
  let city2 = "";
  let city3 = "";
  let city4 = "";
  let area1 = "";
  let area2 = "";
  let area3 = "";
  let area4 = "";
  const {
    current90DaysAvgDOM: cCurrent90DaysAvgDOM,
    current90DaysUnitsSold: cCurrent90DaysUnitsSold,
    last12MonthsAverageSalePrice: cLast12MonthsAverageSalePrice,
    previous12MonthsAverageSalePrice: cPrevious12MonthsAverageSalePrice,
    previous90DaysAvgDOM: cPrevious90DaysAvgDOM,
    previous90DaysUnitsSold: cPrevious90DaysUnitsSold,
    prevToPrevious90DaysAvgDOM: cPrevToPrevious90DaysAvgDOM,
    prevToPrevious90DaysUnitsSold: cPrevToPrevious90DaysUnitsSold,
    maxSalePrice: cMaxSalePrice,
    maxSaleDate: cMaxSaleDate,
    maxSaleStreetDirPrefix: cMaxSaleStreetDirPrefix,
    maxSaleStreetName: cMaxSaleStreetName,
    maxSaleStreetNumber: cMaxSaleStreetNumber,
    maxSaleStreetSuffix: cMaxSaleStreetSuffix,
  } = cityData.marketHighLightsData;

  const { pinkBoxData } = cityData;
  //formulas for city 1 point
  const sortedPinkData = pinkBoxData.sort((a, b) => b.salePrice - a.salePrice);
  let todayDate = new Date();
  let startDate = new Date(sortedPinkData[0].saleDate);

  let cDiff =
    (todayDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24.0);
  const cHighestSaleWithinLast6 = cDiff <= 6 * 30 ? true : false;
  const cHighestListPriceDiff = Math.round(
    ((sortedPinkData[0].salePrice - sortedPinkData[0].listPrice) /
      sortedPinkData[0].listPrice) *
      100
  );
  if (cHighestSaleWithinLast6) {
    city1 += `A new record sale price has just been set: ${
      sortedPinkData[0].address
    } just sold on
     ${new Date(
       sortedPinkData[0].saleDate
     ).toLocaleDateString()}. The sale price was
 $${Number(sortedPinkData[0].salePrice).toLocaleString()}
  ${
    cHighestListPriceDiff === 0
      ? "showing the same price as the original"
      : cHighestListPriceDiff > 0
      ? "showing an " +
        Math.abs(cHighestListPriceDiff) +
        "% " +
        "increase above the "
      : "which was " + Math.abs(cHighestListPriceDiff) + "% " + "off the "
  }


   list price. The previous highest sale price was set by ${
     sortedPinkData[1].address
   } which sold for
  $${Number(sortedPinkData[1].salePrice).toLocaleString()}. (in
     ${sortedPinkData[1].daysOnMarket} days)
`;
  }

  //formulas for city 2 point
  const cPrev12MoAvgSalePrice =
    (cLast12MonthsAverageSalePrice - cPrevious12MonthsAverageSalePrice) /
    cPrevious12MonthsAverageSalePrice;
  const roundedSalePrice =
    Math.round(cLast12MonthsAverageSalePrice / 1000) * 1000;

  const prev12RoundedSalePrice =
    Math.round(cPrevious12MonthsAverageSalePrice / 1000) * 1000;

  const cAvgSalePricePerc =
    cPrev12MoAvgSalePrice > 1
      ? Math.round((cPrev12MoAvgSalePrice - 1) * 100)
      : Math.round(cPrev12MoAvgSalePrice * 100);

  //0
  if (cAvgSalePricePerc === 0) {
    city2 += `Over the last 12 months, the average sale price in ${cityName} remained roughly the same as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was
       $${Number(cPrevious12MonthsAverageSalePrice).toLocaleString()}].`;
  } else if (cAvgSalePricePerc < 1 && cAvgSalePricePerc > -1) {
    city2 += `Over the last 12 months, the average sale price in ${cityName} was fairly flat with just a ${Math.abs(
      cAvgSalePricePerc
    )}% ${cAvgSalePricePerc > 0 ? "increase" : ""}
      ${
        cAvgSalePricePerc < 0 ? "decrease" : ""
      } as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      cPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].
  `;
    //1 - 2.99%
  } else if (
    (cAvgSalePricePerc >= 1 && cAvgSalePricePerc < 3) ||
    (cAvgSalePricePerc <= -1 && cAvgSalePricePerc > -3)
  ) {
    city2 += `Over the last 12 months, the average sale price in ${cityName} ${
      cAvgSalePricePerc > 0 ? "nudged up" : ""
    }
      ${cAvgSalePricePerc < 0 ? "dipped" : ""}
       slightly by ${Math.abs(
         cAvgSalePricePerc
       )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was    $${Number(
      cPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;

    //3 -6
  } else if (
    (cAvgSalePricePerc >= 3 && cAvgSalePricePerc < 6) ||
    (cAvgSalePricePerc >= -3 && cAvgSalePricePerc > -6)
  ) {
    city2 += `Over the last 12 months, the average sale price in ${cityName} ${
      cAvgSalePricePerc > 0 ? "had a healthy increase" : ""
    }
      ${cAvgSalePricePerc < 0 ? "saw a decrease" : ""}
       of ${Math.abs(
         cAvgSalePricePerc
       )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      cPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    //6 - 7.99
  } else if (
    (cAvgSalePricePerc >= 6 && cAvgSalePricePerc < 8) ||
    (cAvgSalePricePerc >= -6 && cAvgSalePricePerc > -8)
  ) {
    city2 += `Over the last 12 months, the average sale price in ${cityName} ${
      cAvgSalePricePerc > 0 ? "experienced a healthy increase" : ""
    }
        ${
          cAvgSalePricePerc < 0
            ? "experienced a somewhat significant decrease"
            : ""
        }
         of ${Math.abs(
           cAvgSalePricePerc
         )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      cPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    //8 - 9.99
  } else if (
    (cAvgSalePricePerc >= 8 && cAvgSalePricePerc < 10) ||
    (cAvgSalePricePerc <= -8 && cAvgSalePricePerc > -10)
  ) {
    city2 += `Over the last 12 months, the average sale price in ${cityName} went
      ${cAvgSalePricePerc > 0 ? "up" : ""}
        ${cAvgSalePricePerc < 0 ? "down somewhat significantly" : ""}
         by ${Math.abs(
           cAvgSalePricePerc
         )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      cPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    //10+
  } else if (cAvgSalePricePerc >= 10 || cAvgSalePricePerc <= -10) {
    city2 += `Over the last 12 months, the average sale price in ${cityName}
      ${cAvgSalePricePerc > 0 ? "went up" : ""}
      ${cAvgSalePricePerc < 0 ? "dropped down" : ""}
         significantly by ${Math.abs(
           cAvgSalePricePerc
         )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      cLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was  $${Number(
      cPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
  } else {
    city2 += ``;
  }

  //formulas for city point 3 DOM

  const c180DiffDom =
    (cCurrent90DaysAvgDOM - cPrevious90DaysAvgDOM) / cPrevious90DaysAvgDOM;

  const c180DomPerc = Math.round(c180DiffDom * 100);
  //city 4 point functions
  const {
    avgHomesSoldLast12Months: city12,
    numberOfHomesForSale: citySale,
    maxHousesSoldLast12Months: maxCity,
    monthsSupply: cMonthsSupply,
  } = cityData;

  //const { avgHomesSoldLast12Months: area12 } = areaData;
  const {
    AreaNumberOfHousesForSale: areaSale,
    AvgHomesSoldLast12Months: area12,
  } = areaData.monthsSupplyTable;
  const avgCity = Number(city12);
  const avgArea = Number(area12);

  const cityInventory = homesNumber(
    citySale,
    avgCity * 10,
    true,
    true,
    maxCity,
    citySale,
    avgCity,
    avgArea
  )[1];

  //Infinity meaning cPrevious90DaysAvgDOM was 0 or  180DomPerc >= 200
  if (cPrevious90DaysAvgDOM === "0" || c180DomPerc >= 200) {
    city3 += `The average Days On Market (DOM) in ${cityName} experienced a major shift with
     ${c180DomPerc > 0 ? "an increase" : ""}
       ${c180DomPerc < 0 ? "a decrease" : ""} to ${cCurrent90DaysAvgDOM} days,
${c180DomPerc > 0 ? "above" : ""}
  ${c180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${cPrevious90DaysAvgDOM} days on market.`;

    // <= 1
  } else if (c180DomPerc >= -1 && c180DomPerc <= 1) {
    city3 += `The average Days On Market (DOM) in ${cityName} remained flat from the previous 90 days, which was ${cPrevious90DaysAvgDOM} days.`;
    //1.1 - 4.99
  } else if (
    (c180DomPerc > 1 && c180DomPerc < 5) ||
    (c180DomPerc < -1 && c180DomPerc > -5)
  ) {
    city3 += `The average Days On Market (DOM) in ${cityName} experienced a slight
     ${c180DomPerc > 0 ? "increase" : ""}
       ${c180DomPerc < 0 ? "decrease" : ""} of
${Math.abs(c180DomPerc)}% to ${cCurrent90DaysAvgDOM} days,
${c180DomPerc > 0 ? "above" : ""}
  ${c180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${cPrevious90DaysAvgDOM} days on market.`;
    //5 - 14.99
  } else if (
    (c180DomPerc >= 5 && c180DomPerc < 15) ||
    (c180DomPerc <= -5 && c180DomPerc > -15)
  ) {
    city3 += `The average Days On Market (DOM) in ${cityName} experienced a slight
     ${c180DomPerc > 0 ? "increase" : ""}
       ${c180DomPerc < 0 ? "decrease" : ""} of
${Math.abs(c180DomPerc)}% to ${cCurrent90DaysAvgDOM} days,
${c180DomPerc > 0 ? "above" : ""}
  ${c180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${cPrevious90DaysAvgDOM} days on market.`;
    //15 - 24.99
  } else if (
    (c180DomPerc >= 15 && c180DomPerc < 25) ||
    (c180DomPerc <= -15 && c180DomPerc > -25)
  ) {
    city3 += `The average Days On Market (DOM) in ${cityName} experienced
     ${c180DomPerc > 0 ? "an increase" : ""}
       ${c180DomPerc < 0 ? "a decrease" : ""} of
${Math.abs(c180DomPerc)}% to ${cCurrent90DaysAvgDOM} days,
${c180DomPerc > 0 ? "above" : ""}
  ${c180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${cPrevious90DaysAvgDOM} days on market.`;
    //25 - 49.99
  } else if (
    (c180DomPerc >= 25 && c180DomPerc < 50) ||
    (c180DomPerc <= -25 && c180DomPerc > -50)
  ) {
    city3 += `The average Days On Market (DOM) in ${cityName} experienced a significant
     ${c180DomPerc > 0 ? "increase" : ""}
       ${c180DomPerc < 0 ? "decrease" : ""} of
${Math.abs(c180DomPerc)}% to ${cCurrent90DaysAvgDOM} days,
${c180DomPerc > 0 ? "above" : ""}
  ${c180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${cPrevious90DaysAvgDOM} days on market.`;
    // > 50 && high inventory
    //   } else if (
    //     (c180DomPerc >= 50 && cityInventory === 'HIGH') ||
    //     (c180DomPerc <= -50 && cityInventory === 'HIGH')
    //   ) {
    //     city3 += `The average Days On Market (DOM) in ${cityName} experienced a major shift with
    //      ${c180DomPerc > 0 ? 'an increase' : ''}
    //        ${c180DomPerc < 0 ? 'a decrease' : ''} of
    // ${Math.abs(c180DomPerc)}% to ${cCurrent90DaysAvgDOM} days --
    // ${c180DomPerc > 0 ? 'above' : ''}
    //   ${c180DomPerc < 0 ? 'below' : ''} the previous 90 day average of
    //   ${cPrevious90DaysAvgDOM} days on market. Combining a significant increase in Days On Market to HIGH INVENTORY levels points towards a slowing of the market - we’ll have to keep an eye on this to see if a trend is forming`;
    // > 50
  } else if (c180DomPerc >= 50 || c180DomPerc <= -50) {
    city3 += `The average Days On Market (DOM) in ${cityName} experienced a major shift with
     ${c180DomPerc > 0 ? "an increase" : ""}
       ${c180DomPerc < 0 ? "a decrease" : ""} of
${Math.abs(c180DomPerc)}% to ${cCurrent90DaysAvgDOM} days,
${c180DomPerc > 0 ? "above" : ""}
  ${c180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${cPrevious90DaysAvgDOM} days on market.`;
  } else {
    city3 += "";
  }

  //city 4 points for Inventory
  // console.log('cityInventory', cityInventory);
  if (cMonthsSupply < 4) {
    city4 += `${cityName}${endsWithS(
      cityName
    )} available homes for sale (inventory) is currently at a LOW level of ${citySale} active listings. This number is in the bottom range of what’s referred to as a ‘balanced inventory’ of homes for sale. In terms of ‘months supply’, it’s only a ${
      cMonthsSupply >= 4 ? "3.9" : Math.round(cMonthsSupply)
    } month’s supply. In other words, with only ${citySale} active listings, the inventory of homes for sale would last only ${
      cMonthsSupply >= 4 ? "3.9" : Math.round(cMonthsSupply)
    } ${monthOrMonths(Math.round(cMonthsSupply))}.
    `;
  } else if (cMonthsSupply >= 4 && cMonthsSupply <= 6) {
    city4 = ` ${cityName}${endsWithS(
      cityName
    )} available homes for sale (inventory) is currently at a BALANCED level with ${citySale} active listings. A ‘balanced inventory’ means there is approximately a ${Math.round(
      cMonthsSupply
    )} month supply before all homes are sold. (Assuming no new listings were to come on the market).`;
  } else if (cMonthsSupply > 6) {
    city4 += ` ${cityName}${endsWithS(
      cityName
    )} available homes for sale (inventory) is currently at a HIGH level with ${citySale} active listings. A ‘high inventory’ means there is a ${Math.round(
      cMonthsSupply
    )} month’s supply of homes for sale. High inventory gives buyers more homes to choose from and creates more competition between active listings, which can push the market conditions towards favoring buyers.
`;
  }

  // AREA POINTS
  const {
    current90DaysAvgDOM: aCurrent90DaysAvgDOM,
    current90DaysUnitsSold: aCurrent90DaysUnitsSold,
    last12MonthsAverageSalePrice: aLast12MonthsAverageSalePrice,
    previous12MonthsAverageSalePrice: aPrevious12MonthsAverageSalePrice,
    previous90DaysAvgDOM: aPrevious90DaysAvgDOM,
    previous90DaysUnitsSold: aPrevious90DaysUnitsSold,
    prevToPrevious90DaysAvgDOM: aPrevToPrevious90DaysAvgDOM,
    prevToPrevious90DaysUnitsSold: aPrevToPrevious90DaysUnitsSold,
    maxSalePrice: aMaxSalePrice,
    maxSaleDate: aMaxSaleDate,
    maxSaleStreetDirPrefix: aMaxSaleStreetDirPrefix,
    maxSaleStreetName: aMaxSaleStreetName,
    maxSaleStreetNumber: aMaxSaleStreetNumber,
    maxSaleStreetSuffix: aMaxSaleStreetSuffix,
  } = areaData.marketHighLightsData;

  const { pinkBoxDataArea } = areaData;

  //formulas for area 1 point
  const aSortedPinkData = pinkBoxDataArea.sort(
    (a, b) => b.salePrice - a.salePrice
  );
  let cStartDate = new Date(aSortedPinkData[0].saleDate);
  let aDiff =
    (todayDate.getTime() - cStartDate.getTime()) / (1000 * 60 * 60 * 24.0);
  const aHighestSaleWithinLast6 = aDiff <= 6 * 30 ? true : false;

  const aHighestListPriceDiff = Math.round(
    ((aSortedPinkData[0].salePrice - aSortedPinkData[0].listPrice) /
      aSortedPinkData[0].listPrice) *
      100
  );

  if (aHighestSaleWithinLast6) {
    area1 += `${aSortedPinkData[0].address} just sold on
     ${new Date(aSortedPinkData[0].saleDate).toLocaleString("default", {
       month: "long",
     })}
      ${daySuffix(
        new Date(aSortedPinkData[0].saleDate).getDay()
      )}. Setting a new RECORD SALE PRICE in your neighborhood selling for $${Number(
      aSortedPinkData[0].salePrice
    ).toLocaleString()}. The list price was  $${Number(
      aSortedPinkData[0].listPrice
    ).toLocaleString()} bringing it in at ${
      aHighestListPriceDiff === 0 ? "" : Math.abs(aHighestListPriceDiff) + "%"
    } ${
      aHighestListPriceDiff === 0
        ? ""
        : aHighestListPriceDiff > 0
        ? "over"
        : "under"
    } the asking price. The previous record sale price was ${
      aSortedPinkData[1].address
    } set in ${new Date(aSortedPinkData[1].saleDate).toLocaleString("default", {
      month: "long",
    })} of ${new Date(
      aSortedPinkData[1].saleDate
    ).getFullYear()}, which sold for $${Number(
      aSortedPinkData[1].salePrice
    ).toLocaleString()}.
  `;
  }

  //formulas for area 2 point
  const aPrev12MoAvgSalePrice =
    (aLast12MonthsAverageSalePrice - aPrevious12MonthsAverageSalePrice) /
    aPrevious12MonthsAverageSalePrice;

  const aRoundedSalePrice =
    Math.round(aLast12MonthsAverageSalePrice / 1000) * 1000;

  const aPrev12RoundedSalePrice =
    Math.round(aPrevious12MonthsAverageSalePrice / 1000) * 1000;

  const aAvgSalePricePerc =
    aPrev12MoAvgSalePrice > 1
      ? Math.round((aPrev12MoAvgSalePrice - 1) * 100)
      : Math.round(aPrev12MoAvgSalePrice * 100);
  //0
  if (aAvgSalePricePerc === 0) {
    area2 += `Over the last 12 months, the average sale price in your neighborhood remained roughly the same as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      aLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      aPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    // 0.1 - 3
  } else if (aAvgSalePricePerc < 3 && aAvgSalePricePerc > -3) {
    area2 += `Over the last 12 months, the average sale price in your neighborhood ${
      aAvgSalePricePerc > 0 ? "crept up" : ""
    }
    ${
      aAvgSalePricePerc < 0 ? "slipped slightly" : ""
    } by approximately ${Math.abs(
      aAvgSalePricePerc
    )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      aLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was  $${Number(
      aPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    //3 - 3.99%
  } else if (
    (aAvgSalePricePerc >= 3 && aAvgSalePricePerc < 4) ||
    (aAvgSalePricePerc <= -3 && aAvgSalePricePerc > -4)
  ) {
    area2 += `Over the last 12 months, the average sale price in your neighborhood ${
      aAvgSalePricePerc > 0 ? "increased" : ""
    }
    ${aAvgSalePricePerc < 0 ? "decreased" : ""} by
    ${aAvgSalePricePerc > 0 ? "just over" : ""}
    ${aAvgSalePricePerc < 0 ? "just under" : ""} ${Math.abs(
      aAvgSalePricePerc
    )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      aLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      aPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    //4 - 6.99
  } else if (
    (aAvgSalePricePerc >= 4 && aAvgSalePricePerc < 7) ||
    (aAvgSalePricePerc <= -4 && aAvgSalePricePerc > -7)
  ) {
    area2 += `Over the last 12 months, the average sale price in your neighborhood ${
      aAvgSalePricePerc > 0 ? "had a healthy increase" : ""
    }
    ${aAvgSalePricePerc < 0 ? "experienced a decrease" : ""} of
    ${aAvgSalePricePerc > 0 ? "just over" : ""}
    ${aAvgSalePricePerc < 0 ? "just under" : ""} ${Math.abs(
      aAvgSalePricePerc
    )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      aLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      aPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
    //7+
  } else if (aAvgSalePricePerc >= 7 || aAvgSalePricePerc <= -7) {
    area2 += `Over the last 12 months, the average sale price in your neighborhood experienced a significant ${
      aAvgSalePricePerc > 0 ? "increase" : ""
    }
    ${aAvgSalePricePerc < 0 ? "decrease" : ""} of ${Math.abs(
      aAvgSalePricePerc
    )}% as compared to the previous 12 months. [The last 12 month average sale price was $${Number(
      aLast12MonthsAverageSalePrice
    ).toLocaleString()} whereas the previous 12 month average was $${Number(
      aPrevious12MonthsAverageSalePrice
    ).toLocaleString()}].`;
  }

  //formulas for area point 3

  const a180DiffDom =
    (aCurrent90DaysAvgDOM - aPrevious90DaysAvgDOM) / aPrevious90DaysAvgDOM || 0;

  const a180DomPerc = Math.round(a180DiffDom * 100);

  const aSalesDiff =
    (aCurrent90DaysUnitsSold - aPrevious90DaysUnitsSold) /
      aPrevious90DaysUnitsSold || 0;
  const a180SalesPerc =
    aSalesDiff > 1
      ? Math.floor((aSalesDiff - 1) * 100)
      : Math.floor(aSalesDiff * 100);

  //>=200
  if (a180DomPerc >= 200 || aPrevious90DaysAvgDOM === "0") {
    area3 += `The average Days On Market (DOM) in your neighborhood experienced a major shift with
     ${a180DomPerc > 0 ? "an increase" : ""}
       ${a180DomPerc < 0 ? "a decrease" : ""} to ${aCurrent90DaysAvgDOM} days,
${a180DomPerc > 0 ? "above" : ""}
  ${a180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${aPrevious90DaysAvgDOM} days on market.`;
  }
  // <= 1
  else if (a180DomPerc >= -1 && a180DomPerc <= 1) {
    area3 += `The average Days On Market (DOM) in your neighborhood remained flat from the previous 90 days which was ${aPrevious90DaysAvgDOM} days.`;
    //1.1 - 4.99
  } else if (
    (a180DomPerc > 1 && a180DomPerc < 5) ||
    (a180DomPerc < -1 && a180DomPerc > -5)
  ) {
    area3 += `The average Days On Market (DOM) in your neighborhood experienced a very slight
     ${a180DomPerc > 0 ? "increase" : ""}
       ${a180DomPerc < 0 ? "decrease" : ""}
       ${
         Math.abs(a180DomPerc) !== Infinity
           ? "of " + Math.abs(a180DomPerc) + "%"
           : ""
       } to ${aCurrent90DaysAvgDOM} days, just slightly
${a180DomPerc > 0 ? "above" : ""}
  ${a180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${aPrevious90DaysAvgDOM} days on market.`;
    //5 - 14.99
  } else if (
    (a180DomPerc >= 5 && a180DomPerc < 15) ||
    (a180DomPerc <= -5 && a180DomPerc > -15)
  ) {
    area3 += `The average Days On Market (DOM) in your neighborhood experienced a modest
     ${a180DomPerc > 0 ? "increase" : ""}
       ${a180DomPerc < 0 ? "decrease" : ""}
       ${
         Math.abs(a180DomPerc) !== Infinity
           ? "of " + Math.abs(a180DomPerc) + "%"
           : ""
       }  to ${aCurrent90DaysAvgDOM} days, ${a180DomPerc > 0 ? "above" : ""}
  ${a180DomPerc < 0 ? "below" : ""} the previous 90 day average of
   ${aPrevious90DaysAvgDOM} days on market.`;
    //15 - 24.99
  } else if (
    (a180DomPerc >= 15 && a180DomPerc < 25) ||
    (a180DomPerc <= -15 && a180DomPerc > -25)
  ) {
    area3 += `The average Days On Market (DOM) in your neighborhood experienced a somewhat significant
     ${a180DomPerc > 0 ? "increase" : ""}
       ${a180DomPerc < 0 ? "decrease" : ""}
       ${
         Math.abs(a180DomPerc) !== Infinity
           ? "of " + Math.abs(a180DomPerc) + "%"
           : ""
       } to ${aCurrent90DaysAvgDOM} days,
${a180DomPerc > 0 ? "above" : ""}
  ${a180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${aPrevious90DaysAvgDOM} days on market.`;
    //25 - 49.99
  } else if (
    (a180DomPerc >= 25 && a180DomPerc < 50) ||
    (a180DomPerc <= -25 && a180DomPerc > -50)
  ) {
    area3 += `The average Days On Market (DOM) in your neighborhood experienced a noteworthy
     ${a180DomPerc > 0 ? "increase" : ""}
       ${a180DomPerc < 0 ? "decrease" : ""}
       ${
         Math.abs(a180DomPerc) !== Infinity
           ? "of " + Math.abs(a180DomPerc) + "%"
           : ""
       } to ${aCurrent90DaysAvgDOM} days,
${a180DomPerc > 0 ? "above" : ""}
  ${a180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${aPrevious90DaysAvgDOM} days on market.`;
    // > 50
  } else if (a180DomPerc >= 50 || a180DomPerc <= -50) {
    area3 += `The average Days On Market (DOM) in your neighborhood experienced a major shift with
     ${a180DomPerc > 0 ? "an increase" : ""}
       ${a180DomPerc < 0 ? "a decrease" : ""}

       ${
         Math.abs(a180DomPerc) !== Infinity
           ? "of " + Math.abs(a180DomPerc) + "%"
           : ""
       }
 to ${aCurrent90DaysAvgDOM} days,
${a180DomPerc > 0 ? "above" : ""}
  ${a180DomPerc < 0 ? "below" : ""} the previous 90 day average of
  ${aPrevious90DaysAvgDOM} days on market.`;
  } else {
    area3 += "";
  }

  //area 4 point
  const {
    // numberOfHomesForSale: areaSale,
    maxHousesSoldLast12Months: maxArea,
    monthsSupply: aMonthsSupply,
  } = areaData;
  const { AreaMonthsSupply } = areaData.monthsSupplyTable;
  //const avgArea = Number(area12);
  // const areaInventory = homesNumber(
  //   citySale,
  //   avgCity * 10,
  //   true,
  //   true,
  //   maxCity,
  //   citySale,
  //   avgCity,
  //   avgArea
  // )[1];

  const areaInventory = homesNumber(
    areaSale,
    avgArea * 10,
    true,
    false,
    maxCity,
    citySale,
    avgCity,
    avgArea
  )[1];

  if (AreaMonthsSupply < 4) {
    area4 += `Your neighborhood's available homes for sale (inventory) is currently at a LOW level of ${areaSale} active listings. This number is in the bottom range of what’s referred to as a ‘balanced inventory’ of homes for sale. In terms of ‘months supply’, it’s only a ${Math.round(
      AreaMonthsSupply
    )} ${monthOrMonths(
      Math.round(AreaMonthsSupply)
    )} supply. In other words, with only ${areaSale} active listing${
      areaSale === "1" ? "" : "s"
    }, the inventory of homes for sale would last only ${Math.round(
      AreaMonthsSupply
    )} ${monthOrMonths(Math.round(AreaMonthsSupply))}.`;
  } else if (AreaMonthsSupply >= 4 && AreaMonthsSupply <= 6) {
    area4 += `Your neighborhood's available homes for sale (inventory) is currently at a BALANCED level with ${areaSale} active listings. A ‘balanced inventory’ means there is approximately a ${Math.round(
      AreaMonthsSupply
    )} month supply before all homes are sold. (Assuming no new listings were to come on the market).
`;
  } else if (AreaMonthsSupply > 6) {
    area4 += `Your neighborhood's available homes for sale (inventory) is currently at a HIGH level with ${areaSale} active listings. High inventory levels means there is more than a ${Math.round(
      AreaMonthsSupply
    )} month’s supply of homes for sale. High inventories give buyers an advantage by giving them more homes to choose from.`;
  }

  let final = {
    city: {
      city1,
      city2,
      city3,
      city4,
    },
    area: {
      area1,
      area2,
      area3,
      area4,
    },
  };
  return final;
}

function daySuffix(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

function getCurrentArea(mlsAreas, currentArea) {
  let xArea = {};
  mlsAreas.map((area) => {
    if (area.mlsAreaID === currentArea.mlsAreaID) {
      xArea = area;
    }
  });
  return xArea;
}

function getActualHomeowners(propertyData) {
  let actualHomeowners = [];
  try {
    actualHomeowners = propertyData.filter(
      (prop) => parseInt(prop.agentID) === 0
    );
  } catch (err) {}
  return actualHomeowners;
}

function getActualAgents(propertyData) {
  let agentList = [];
  try {
    agentList = propertyData.filter((prop) => parseInt(prop.agentID) > 0);
  } catch (err) {}
  return agentList;
}

function getActualUnblockedHomeowners(actualHomeowners) {
  let unblockedHO = [];
  try {
    unblockedHO = actualHomeowners.filter((prop) => {
      if (!prop.changeDetails) {
        return true;
      }
      if (prop.changeDetails && prop.changeDetails.blocked === null) {
        return true;
      }
      if (!prop.changeDetails.blocked) {
        return true;
      }
      return false;
    });
  } catch (err) {}
  return unblockedHO;
}

function getActualBlockedHomeowners(actualHomeowners) {
  let blockedHO = [];
  try {
    blockedHO = actualHomeowners.filter((prop) => {
      if (!prop.changeDetails) {
        return false;
      }
      if (
        prop.changeDetails &&
        prop.changeDetails.blocked !== null &&
        prop.changeDetails.blocked !== "Delete"
      ) {
        return true;
      }
      if (!prop.changeDetails.blocked) {
        return false;
      }
      return false;
    });
  } catch (err) {}
  return blockedHO;
}

function getActualDeletedHomeowners(actualHomeowners) {
  let blockedHO = [];
  try {
    blockedHO = actualHomeowners.filter((prop) => {
      if (!prop.changeDetails) {
        return false;
      }
      if (
        prop.changeDetails &&
        prop.changeDetails.blocked !== null &&
        prop.changeDetails.blocked === "Delete"
      ) {
        return true;
      }
      if (!prop.changeDetails.blocked) {
        return false;
      }
      return false;
    });
  } catch (err) {}
  return blockedHO;
}

function getAllUnblockedHOFromAllHomeowners(allHomeownerDataORPropertyData) {
  let actualHomeowners = getActualHomeowners(allHomeownerDataORPropertyData);
  let agentList = getActualAgents(allHomeownerDataORPropertyData).length;
  let unblockedHO = getActualUnblockedHomeowners(actualHomeowners).length;
  //combines the unblocked homeowners + ALL the agents, this will be the total number of pma's we are sending out
  return agentList + unblockedHO;
}

function filterPropertyByStatus(propertyData, filterStatus) {
  let filteredProperties = [];
  try {
    if (filterStatus === "all" || filterStatus === "") {
      filteredProperties = propertyData;
    } else if (filterStatus === "blocked") {
      return this.getActualBlockedHomeowners(propertyData);
      //getActualDeletedHomeowners
    } else if (filterStatus === "delete") {
      return this.getActualDeletedHomeowners(propertyData);
    } else if (filterStatus === "active") {
      //change to show active props by default
      return this.getActualUnblockedHomeowners(propertyData);
    }
    // actualHomeowners = propertyData.filter(
    //   prop => parseInt(prop.agentID) === 0
    // );
  } catch (err) {}
  return filteredProperties;
}

function getBlockedReasonDescription(blockedReason) {
  if (!blockedReason || blockedReason === null) {
    return "";
  }
  blockedReason = blockedReason.toLowerCase();
  if (blockedReason === "homeowner-blocked") {
    return "Homeowner Requested Stop Mailing";
  } else if (blockedReason === "agent-blocked") {
    return "My Decision to Block This Homeowners's Mailings";
  } else if (blockedReason === "returned-mail [undeliverable]") {
    return "Returned Mail Undeliverable Address";
  } else if (blockedReason === "active") {
    return "Actively Listed by Another Agent";
  } else if (blockedReason === "contingent") {
    return "Contingent Listing By Another Agent";
  } else if (
    blockedReason === "pending" ||
    blockedReason === "pending (do not show)"
  ) {
    return "Pending Listing By Another Agent";
  } else if (blockedReason === "delete") {
    return "Deleted";
  } else if (blockedReason === "dnm") {
    return "DO NOT MAIL";
  } else if (blockedReason === "duplicate mailing addresses") {
    return "Duplicate Mailing Address";
  }
  return blockedReason;
}

function getBlockedReasonGroup(blockedReason) {
  let tempBlockedReason = (blockedReason || "").toLowerCase();
  if (
    tempBlockedReason === "another real estate agent"
    // ||
    // tempBlockedReason === "active"
  ) {
    return "Another Real Estate Agent";
  } else {
    return blockedReason;
  }
}

function iTextTruncater(noobText, cutWords, cutLength) {
  /**
   * Logic would be very simple. If string contains space than use split by words[If space is after given cut length then split by 25 chracters]. otherwise split by directly 25 characters
   */
  /**forst cut by 25 cutLength character */
  try {
    let cutLengthText = noobText.split("").slice(0, cutLength).join("").trim();
    if (cutLengthText.indexOf(" ") >= 0) {
      /**means we have space under cutLength. Use word way */
      return noobText.split(" ").slice(0, cutWords).join(" ").trim();
    } else {
      /**special and second case */
      return cutLengthText;
    }
  } catch (err) {
    return "";
  }
}

function iHavingAllValueEmpty(...args) {
  let allValueEmpty = true;
  let inputString = "";
  for (let i = 0; i < args.length; i++) {
    inputString = args[i];
    if (
      inputString &&
      inputString !== "" &&
      inputString !== "0" &&
      inputString !== "EmptyValue" &&
      inputString !== "-"
    ) {
      allValueEmpty = false;
    }
  }
  return allValueEmpty;
}

function valueCorrector(inputString) {
  if (
    !inputString ||
    inputString === "" ||
    inputString === "0" ||
    inputString === "EmptyValue" ||
    inputString === "-" ||
    inputString === "*no Site Address*"
  ) {
    return ""; //here do not send - because we are sorting the data
  }
  return inputString;
}

function splitandGetFirstName(fullname) {
  try {
    let xNameintoArray = fullname.split(" ");
    /**get firstname */
    let firstName = xNameintoArray[0] ? xNameintoArray[0] : "";
    return firstName;
  } catch (err) {
    return "";
  }
}

function splitandGetLastName(fullname) {
  try {
    let xNameintoArray = fullname.split(" ");
    /**get lastname.splice the array.remove the first element */
    let lastName = xNameintoArray.splice(0, 1);
    lastName = xNameintoArray ? xNameintoArray.join(" ") : "";
    return lastName;
  } catch (err) {
    return "";
  }
}

function brokerageFirstLineNameHandler(brokerageName) {
  try {
    /**Logic would be first split by space and if length is 2 than show in one line. otherwise show ins econd line */
    let xBrokerageName = brokerageName ? brokerageName.split(" ") : [];
    if (xBrokerageName.length <= 2) {
      return brokerageName;
    } else {
      return splitandGetFirstName(brokerageName);
    }
  } catch (err) {
    return brokerageName;
  }
}

function brokerageSecondLineNameHandler(brokerageName) {
  try {
    /**Logic would be first split by space and if length is 2 than show in one line. otherwise show ins econd line */
    let xBrokerageName = brokerageName ? brokerageName.split(" ") : [];
    if (xBrokerageName.length <= 2) {
      return ""; //no second line
    } else {
      return splitandGetLastName(brokerageName);
    }
  } catch (err) {
    return brokerageName;
  }
}

function isValidDate(d) {
  try {
    d = new Date(d);
    return d instanceof Date && !isNaN(d.valueOf());
  } catch (err) {
    return false;
  }
}

function getLastPrintDate() {
  let storedData = GetStateStorage();
  try {
    if (
      storedData &&
      storedData.userData &&
      storedData.userData.lastPMAPrintDate
    ) {
      return storedData.userData.lastPMAPrintDate;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

function reverseNotes(noteHistory) {
  let xPersonalPSHistory = [...(noteHistory ? noteHistory : [])];
  let xData = Array.prototype.reverse.call(xPersonalPSHistory);
  return xData;
}

function daySuffixMailPro(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

function checkIsSoldDateBwLastPrintandToday(lastSaleDate, lastPrintDate) {
  if (!lastSaleDate || !lastPrintDate) {
    return false;
  }
  lastSaleDate = moment(new Date(lastSaleDate), "YYYY-MM-DD");
  /** convert these two dates into moment */
  lastPrintDate = moment(new Date(lastPrintDate), "YYYY-MM-DD");
  let todayDate = moment(new Date(), "YYYY-MM-DD");
  /**put special check for type also */
  if (
    (lastSaleDate.isAfter(lastPrintDate) ||
      lastSaleDate.isSame(lastPrintDate)) &&
    (lastSaleDate.isBefore(todayDate) || lastSaleDate.isSame(todayDate))
  ) {
    return true;
  }
  return false;
}

function checkIsSoldDateBwLastPrintMinus3MonthsandToday(
  lastSaleDate,
  lastPrintDate
) {
  if (!lastSaleDate || !lastPrintDate) {
    return false;
  }
  lastSaleDate = moment(new Date(lastSaleDate), "YYYY-MM-DD");
  /** convert these two dates into moment */
  lastPrintDate = moment(new Date(lastPrintDate))
    .subtract(3, "months")
    .format("YYYY-MM-DD");
  let todayDate = moment(new Date(), "YYYY-MM-DD");
  /**put special check for type also */
  if (
    (lastSaleDate.isAfter(lastPrintDate) ||
      lastSaleDate.isSame(lastPrintDate)) &&
    (lastSaleDate.isBefore(todayDate) || lastSaleDate.isSame(todayDate))
  ) {
    return true;
  }
  return false;
}

function checkCongratsLogic(lastSaleDate, lastPrintDate) {
  if (!lastSaleDate || !lastPrintDate) {
    return false;
  }
  //1.first check it should be bw last print date and current date
  //2. sale date year should be same to today year
  //3. if not same year, then sale date month should be nov or dec
  let isSoldDateBwLastPrintandToday = this.checkIsSoldDateBwLastPrintandToday(
    lastSaleDate,
    lastPrintDate
  );
  if (isSoldDateBwLastPrintandToday) {
    //first condition passed go for second
    let lastSaleDateYear = moment(new Date(lastSaleDate), "YYYY");
    let todayDateYear = moment(new Date(), "YYYY");
    let lastSaleDateMonth = moment(new Date(lastSaleDate), "MM");
    // check for isNan first
    if (
      isNaN(lastSaleDateYear) ||
      isNaN(todayDateYear) ||
      isNaN(lastSaleDateMonth)
    ) {
      return false;
      //something goes wrong send false
    }
    //check for condition 2
    if (lastSaleDateYear === todayDateYear) {
      //condition satisfied send true
      return true;
    } else if (
      Number(lastSaleDateMonth) === 11 ||
      Number(lastSaleDateMonth) === 12
    ) {
      //year doesn't macthed check for month nov,dec.i.e 11,12
      return true;
    } else {
      return false;
    }
  }
  //not matched just send false
  return false;
}

function checkAnniversaryLogic(
  lastSaleDateString,
  lastPrintDateString,
  nextPrintDateString
) {
  if (!lastSaleDateString || !lastPrintDateString || !nextPrintDateString) {
    return false;
  }
  // Convert date strings to Date objects
  let lastSaleDate = new Date(lastSaleDateString);
  let lastPrintDate = new Date(lastPrintDateString);
  let nextPrintDate = new Date(nextPrintDateString);
  //if any of date is invalid return false
  if (isNaN(lastSaleDate) || isNaN(lastPrintDate) || isNaN(nextPrintDate)) {
    return false;
  }
  // Calculate the current date
  const currentDate = new Date();

  // Calculate the date 1 year ago from the current date
  const oneYearAgoDate = new Date(currentDate);
  oneYearAgoDate.setFullYear(oneYearAgoDate.getFullYear() - 1);

  // Check if lastSaleDate is at least 1 year old
  if (lastSaleDate <= oneYearAgoDate) {
    // Adjust lastPrintDate and nextPrintDate to the same year for month-day comparison
    const adjustedLastPrintDate = new Date(lastPrintDate);
    const lastPrintDateYear = adjustedLastPrintDate.getFullYear();
    adjustedLastPrintDate.setFullYear(2000); // Use a fixed year for comparison

    const adjustedNextPrintDate = new Date(nextPrintDate);
    const nextPrintDateYear = adjustedNextPrintDate.getFullYear();
    adjustedNextPrintDate.setFullYear(
      nextPrintDateYear > lastPrintDateYear ? 2001 : 2000
    );

    const adjustedLastSaleDate = new Date(lastSaleDate);
    adjustedLastSaleDate.setFullYear(
      nextPrintDateYear > lastPrintDateYear ? 2001 : 2000
    ); // Use a fixed year for comparison

    // Compare the month and day
    if (
      adjustedLastSaleDate >= adjustedLastPrintDate &&
      adjustedLastSaleDate <= adjustedNextPrintDate
    ) {
      return true;
    }
  }
  return false;
}

// function checkAnniversaryLogic(lastSaleDate, lastPrintDate, nextPrintDate) {
//   //1.sold date(month and day only)should be in range of last print date and next print date
//   //2. but should be 1 year old also
//   let oneYear = 1000 * 60 * 60 * 24 * 30.41 * 12;
//   //console.log('lastSaleDate===', lastSaleDate)
//   let lastSaleDateinTime = new Date(lastSaleDate).getTime();
//   let today = new Date().getTime();
//   let haveEqualorGreaterThanOneYear =
//     today - lastSaleDateinTime >= oneYear ? true : false;
//   //yes we have equal or greater than 1 year diff
//   if (haveEqualorGreaterThanOneYear) {
//     //condition 2 satisfied. check for condition 1
//     let xLastSaleDate = moment(new Date(lastSaleDate), "YYYY-MM-DD");
//     /** convert these two dates into moment */
//     let xLastPrintDate = moment(new Date(lastPrintDate), "YYYY-MM-DD");
//     let xNextPrintDate = moment(new Date(nextPrintDate), "YYYY-MM-DD");
//     /***check for last pma print and next print year also. if both are not from same year then use 1972 */
//     let xLastPrintDateYear = xLastPrintDate.format("YYYY");
//     let xNextPrintDateYear = xNextPrintDate.format("YYYY");
//     /**set the year to 1971 */
//     xLastSaleDate.set({ year: "1971" });
//     xLastPrintDate.set({ year: "1971" });
//     xNextPrintDate.set({
//       year: xNextPrintDateYear > xLastPrintDateYear ? "1972" : "1971", //both have different year means year end version
//     }); //1971 or 1972
//     if (
//       (xLastSaleDate.isAfter(xLastPrintDate) ||
//         xLastSaleDate.isSame(xLastPrintDate)) &&
//       (xLastSaleDate.isBefore(xNextPrintDate) ||
//         xLastSaleDate.isSame(xNextPrintDate))
//     ) {
//       return true;
//     }
//     //not satisfied send false
//     return false;
//   }
//   return false;
// }

function getRemainingSecondInaDay() {
  let d = new Date();
  let h = d.getHours();
  let m = d.getMinutes();
  let s = d.getSeconds();
  return 24 * 60 * 60 - h * 60 * 60 - m * 60 - s;
}

function shouldBlockMailPro(mailProStatus) {
  return (
    mailProStatus === "allAreasApproved" || mailProStatus === "readyForPrint"
  );
}

function getUnapprovedAreaCount(mlsAreas) {
  let areaCount = 0;
  mlsAreas.forEach((item, index) => {
    if (
      !item.mailingStatus ||
      item.mailingStatus !== "Approved" ||
      item.mailingStatus === null ||
      item.mailingStatus === ""
    ) {
      areaCount++;
    }
  });
  return { showBox: areaCount === mlsAreas.length ? false : true, areaCount };
}

// /currentAreaID who just got approved
function getOneUnapprovedAreaData(mlsAreas, currentAreaMlsID) {
  let areaIndex = 0;
  let unapprovedArea = mlsAreas.find((item, index) => {
    if (
      item.mailingStatus !== "Approved" &&
      item.mlsAreaID !== currentAreaMlsID
    ) {
      areaIndex = index;
      return true;
    } else {
      return false;
    }
  });
  return unapprovedArea && Object.keys(unapprovedArea).length > 0
    ? { area: unapprovedArea, areaIndex }
    : null;
}

/**
 * Make First letter capital of each word
 * @param str Input value
 * @param lower Whether string needs to be converted into lower case before capitalizing
 * @param applyToFirstSpace Apply capitalization to all character occuring after space
 */
function capitalizeString(str, lower = false, applyToFirstSpace = true) {
  //https://stackoverflow.com/questions/2332811/capitalize-words-in-string
  if (str) {
    if (applyToFirstSpace) {
      let spaceCount = str.split(" ").length - 1;
      if (spaceCount > 0) {
        return str;
      }
    }
    return (lower ? str.toLowerCase() : str).replace(
      /(?:^|\s|["'([{])+\S/g,
      (match) => match.toUpperCase()
    );
  } else {
    return str;
  }
}
