import React, { Component } from "react";
import IndicatorSection from "./IndicatorSection";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YETwoMarketIndicators extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  determineComparison = () => {
    const { infinityCheck } = propertyUtil;
    function calculateDiff(curr, prev) {
      if (curr === "0" && prev === "0") {
        return "0";
      } else if (curr === "0") {
        return -100;
      } else if (prev === "0") {
        return 100;
      } else {
        curr = Math.round(curr);
        prev = Math.round(prev);
        let res = infinityCheck(
          Math.round(((curr - prev) / Math.abs(prev)) * 100)
        );
        return res;
      }
    }
    const {
      quarterlyAreaTable,
      currentArea,
      useCity,
      quarterlyCityTable,
    } = this.props;
    const { marketHighLightsData, marketTrends } = quarterlyAreaTable;

    const { keyMarketIndicatorAveragesForCity } = quarterlyCityTable;
    const { mlsAreaID } = currentArea;

    let dataObj = {
      compareDays: null,
      salePriceDiff: null,
      salePriceDataPoint: null,
      receivedPerc: null,
      receivedDataPoint: null,
      daysToSellPerc: null,
      daysToSellDataPoint: null,
      unitsSoldPerc: null,
      unitsSoldDataPoint: null,
      previousUnitsSoldDataPoint: null,
      sqftPercDiff: null,
      sqftDataPoint: null,
      monthComparison: null,
      highestSalePrice: null,
    };
    const {
      current180DaysAvgSalePrice,
      previous180DaysAvgSalePrice,
      current180DaysPercentReceivedOfAsking,
      previous180DaysPercentReceivedOfAsking,
      current180DaysAvgDOM,
      previous180DaysAvgDOM,
      current180DaysUnitsSold,
      previous180DaysUnitsSold,
      current180DaysPricePerSqFt,
      previous180DaysPricePerSqFt,

      current90DaysAvgSalePrice,
      previous90DaysAvgSalePrice,
      current90DaysPercentReceivedOfAsking,
      previous90DaysPercentReceivedOfAsking,
      current90DaysAvgDOM,
      previous90DaysAvgDOM,
      current90DaysUnitsSold,
      previous90DaysUnitsSold,
      current90DaysPricePerSqFt,
      previous90DaysPricePerSqFt,

      last12MonthsAverageSalePrice,
      previous12MonthsAverageSalePrice,
      last12MonthsPercentReceivedOfAsking,
      previous12MonthsPercentReceivedOfAsking,
      last12MonthsPricePerSqFt,
      previous12MonthsPricePerSqFt,
      previous12MonthsUnitsSold,
    } = marketHighLightsData;

    let use6Months = false;
    let use6MonthsVsLastYear6Months = true;

    if (useCity) {
      //if los olivos or Buellton

      const {
        keyMarketIndicatorAveragesForCity,
        keyMarketIndicatorAveragesPercentageForCity,
      } = quarterlyCityTable;
      let currSix = keyMarketIndicatorAveragesForCity.filter(
        (term) => term.NameOfTerm === "CurrentSixMonths"
      )[0];
      let prevSix = keyMarketIndicatorAveragesForCity.filter(
        (term) => term.NameOfTerm === "PreviousSixMonths"
      )[0];

      const {
        AverageDaysOnMarket: currAverageDaysOnMarket,
        AveragePercentOfReceiving: currAveragePercentOfReceiving,
        AveragePricePerSqFt: currAveragePricePerSqFt,
        AverageSalePrice: currAverageSalePrice,
        NameOfTerm: currNameOfTerm,
        NumberOfListings: currNumberOfListings,
      } = currSix;
      const {
        AverageDaysOnMarket: prevAverageDaysOnMarket,
        AveragePercentOfReceiving: prevAveragePercentOfReceiving,
        AveragePricePerSqFt: prevAveragePricePerSqFt,
        AverageSalePrice: prevAverageSalePrice,
        NameOfTerm: prevNameOfTerm,
        NumberOfListings: prevNumberOfListings,
      } = prevSix;

      dataObj.compareDays = "6 Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = currAverageSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        currAverageSalePrice,
        prevAverageSalePrice
      );
      dataObj.receivedDataPoint = currAveragePercentOfReceiving;
      dataObj.receivedPerc = calculateDiff(
        currAveragePercentOfReceiving,
        prevAveragePercentOfReceiving
      );
      dataObj.sqftDataPoint = currAveragePricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        currAveragePricePerSqFt,
        prevAveragePricePerSqFt
      );
      dataObj.daysToSellDataPoint = currAverageDaysOnMarket;
      dataObj.daysToSellPerc = calculateDiff(
        currAverageDaysOnMarket,
        prevAverageDaysOnMarket
      );
      dataObj.unitsSoldDataPoint = currNumberOfListings;
      dataObj.unitsSoldPerc = calculateDiff(
        currNumberOfListings,
        prevNumberOfListings
      );

      return dataObj;
    } else if (use6MonthsVsLastYear6Months) {
      // 6 month vs same 6 months last year
      console.log("use 6 month", quarterlyAreaTable);
      const {
        marketTrends: marketTrendsArea,
        marketTrendsPercentage: marketTrendsPercArea,
        graphAverages: graphAveragesArea,
      } = quarterlyAreaTable;
      console.log("marketTrends", marketTrendsPercArea);
      let currentYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "6-Months Trending"
      )[0];
      console.log("currentYearObjArea", currentYearObjArea);
      let currentYearAreaPerc = marketTrendsPercArea.filter(
        (obj) => obj.TrendPercentageName === "Same 6 Months Trends Percentage"
      )[0];
      console.log("currentYearAreaPerc", currentYearAreaPerc);
      if (!currentYearAreaPerc) {
        console.log("no year");
      }
      const pastYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "Same 6-Months Last Year Trending"
      )[0];

      console.log("pastYearObjArea", pastYearObjArea);

      dataObj.compareDays = "6MonthsVSLastYear6Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = currentYearObjArea.AvgSalePrice;
      dataObj.salePriceDiff = currentYearAreaPerc
        ? currentYearAreaPerc.PercentageAvgSalePrice
        : calculateDiff(
            currentYearObjArea.AvgSalePrice,
            pastYearObjArea.AvgSalePrice
          );
      dataObj.daysToSellDataPoint = currentYearObjArea.AvgDaysOnMarket;
      dataObj.receivedDataPoint = currentYearObjArea.AvgPercentOfReceiving;
      dataObj.receivedPerc = calculateDiff(
        currentYearObjArea.AvgPercentOfReceiving,
        pastYearObjArea.AvgPercentOfReceiving
      );

      dataObj.daysToSellPerc = currentYearAreaPerc.PercentageAvgDaysOnMarket;

      dataObj.unitsSoldDataPoint = currentYearObjArea.HomesSold;
      dataObj.previousUnitsSoldDataPoint = pastYearObjArea.HomesSold;
      dataObj.unitsSoldPerc = currentYearAreaPerc.PercentageNumberOfHomesSold;

      dataObj.sqftDataPoint = currentYearObjArea.AvgPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        currentYearObjArea.AvgPricePerSqFt,
        pastYearObjArea.AvgPricePerSqFt
      );

      return dataObj;
    } else if (current180DaysUnitsSold < 1 || +previous180DaysUnitsSold < 1) {
      // 1Year Trending" and "Past 1 Year Trending"

      const {
        marketTrends: marketTrendsArea,
        marketTrendsPercentage: marketTrendsPercArea,
        graphAverages: graphAveragesArea,
      } = quarterlyAreaTable;

      let currentYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "1-Year Trending"
      )[0];

      // let currentYearObjArea = marketTrendsArea.filter(
      //   (obj) => obj.TrendName === "1 Year Trends Percentage"
      // )[0];

      let currentYearAreaPerc = marketTrendsPercArea.filter(
        (obj) => obj.TrendPercentageName === "1 Year Trends Percentage"
      )[0];

      const pastYearObjArea = marketTrendsArea.filter(
        (obj) => obj.TrendName === "Past 1-Year Trending"
      )[0];

      dataObj.compareDays = "12 Months";
      dataObj.monthComparison = 12;
      dataObj.salePriceDataPoint = currentYearObjArea.AvgSalePrice;
      dataObj.salePriceDiff = currentYearAreaPerc.PercentageAvgSalePrice;
      dataObj.daysToSellDataPoint = currentYearObjArea.AvgDaysOnMarket;
      dataObj.receivedDataPoint = currentYearObjArea.AvgPercentOfReceiving;
      dataObj.receivedPerc = calculateDiff(
        currentYearObjArea.AvgPercentOfReceiving,
        pastYearObjArea.AvgPercentOfReceiving
      );

      dataObj.daysToSellPerc = currentYearAreaPerc.PercentageAvgDaysOnMarket;

      dataObj.unitsSoldDataPoint = currentYearObjArea.HomesSold;
      dataObj.previousUnitsSoldDataPoint = pastYearObjArea.HomesSold;
      dataObj.unitsSoldPerc = currentYearAreaPerc.PercentageNumberOfHomesSold;

      dataObj.sqftDataPoint = last12MonthsPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        last12MonthsPricePerSqFt,
        previous12MonthsPricePerSqFt
      );

      return dataObj;
    } else if (
      use6Months ||
      +current90DaysUnitsSold < 1 ||
      +previous90DaysUnitsSold < 1 ||
      mlsAreaID === "156" ||
      mlsAreaID === "SBA-20FRes"
    ) {
      dataObj.compareDays = "6 Months";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = current180DaysAvgSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        current180DaysAvgSalePrice,
        previous180DaysAvgSalePrice
      );
      dataObj.receivedDataPoint = current180DaysPercentReceivedOfAsking;
      dataObj.receivedPerc = calculateDiff(
        current180DaysPercentReceivedOfAsking,
        previous180DaysPercentReceivedOfAsking
      );
      dataObj.sqftDataPoint = current180DaysPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        current180DaysPricePerSqFt,
        previous180DaysPricePerSqFt
      );
      dataObj.daysToSellDataPoint = current180DaysAvgDOM;
      dataObj.daysToSellPerc = calculateDiff(
        current180DaysAvgDOM,
        previous180DaysAvgDOM
      );
      dataObj.unitsSoldDataPoint = current180DaysUnitsSold;
      dataObj.previousUnitsSoldDataPoint = previous180DaysUnitsSold;
      dataObj.unitsSoldPerc = calculateDiff(
        current180DaysUnitsSold,
        previous180DaysUnitsSold
      );

      return dataObj;
    } else {
      dataObj.compareDays = "90 Days";
      dataObj.monthComparison = 6;
      dataObj.salePriceDataPoint = current90DaysAvgSalePrice;
      dataObj.salePriceDiff = calculateDiff(
        current90DaysAvgSalePrice,
        previous90DaysAvgSalePrice
      );
      dataObj.receivedDataPoint = current90DaysPercentReceivedOfAsking;
      dataObj.receivedPerc = calculateDiff(
        current90DaysPercentReceivedOfAsking,
        previous90DaysPercentReceivedOfAsking
      );

      dataObj.sqftDataPoint = current90DaysPricePerSqFt;
      dataObj.sqftPercDiff = calculateDiff(
        current90DaysPricePerSqFt,
        previous90DaysPricePerSqFt
      );

      dataObj.daysToSellDataPoint = current90DaysAvgDOM;
      dataObj.daysToSellPerc = calculateDiff(
        current90DaysAvgDOM,
        previous90DaysAvgDOM
      );
      dataObj.unitsSoldDataPoint = current90DaysUnitsSold;
      dataObj.previousUnitsSoldDataPoint = previous90DaysUnitsSold;
      dataObj.unitsSoldPerc = calculateDiff(
        current90DaysUnitsSold,
        previous90DaysUnitsSold
      );

      return dataObj;
    }
  };

  render() {
    const {
      current90DaysAvgSalePrice,
      previous90DaysAvgSalePrice,
      current90DaysAvgDOM,
      previous90DaysAvgDOM,
      current90DaysPricePerSqFt,
      previous90DaysPricePerSqFt,
      current90DaysUnitsSold,
      previous90DaysUnitsSold,
    } = this.props.quarterlyAreaTable.marketHighLightsData;
    const { currentArea, printVersion } = this.props;
    const { mlsAreaID } = currentArea;
    const { infinityCheck } = propertyUtil;

    const determineComparison = this.determineComparison();

    return (
      <div
        className={`${
          printVersion
            ? "print-ye2-market-indicators"
            : "editor-ye2-market-indicators"
        }`}
      >
        {/*<div className="market-indicators-footer">
          <div className="market-indicators-footer-row">
            <p
              className={`market-indicators-footer-compare ${
                determineComparison.compareDays === "6 Months"
                  ? "six-month-indicator"
                  : determineComparison.compareDays === "12 Months"
                  ? "twelve-month-indicator"
                  : ""
              }`}
            >
              Last {determineComparison.compareDays}:
            </p>
            <p>
              {determineComparison.unitsSoldDataPoint}{" "}
              {determineComparison.unitsSoldDataPoint === "1"
                ? "Unit"
                : "Units"}{" "}
              Sold
            </p>
          </div>
          <div className="market-indicators-footer-row">
            <p
              className={`market-indicators-footer-compare ${
                determineComparison.compareDays === "6 Months"
                  ? "six-month-indicator"
                  : determineComparison.compareDays === "12 Months"
                  ? "twelve-month-indicator"
                  : ""
              }`}
            >
              Previous {determineComparison.compareDays}:{" "}
            </p>
            <p>
              {determineComparison.previousUnitsSoldDataPoint}{" "}
              {determineComparison.previousUnitsSoldDataPoint === "1"
                ? "Unit"
                : "Units"}{" "}
              Sold
            </p>
          </div>
        </div>*/}
        <div className="mini-market-title">
          <div className="left-market-title-container">
            <div className="left-market-title-section">
              <p>Key Market Indicators</p>
            </div>
            <div className="mini-market-title-divider"></div>
          </div>

          {determineComparison.compareDays === "6MonthsVSLastYear6Months" ? (
            <div className="same-6-month-last-year">
              <p>Last 6 Months</p>
              <p className="lc-market-title">vs</p>
              <p>Same 6 Months Last Year</p>
            </div>
          ) : (
            <div
              className={`right-market-title-section ${
                (determineComparison.compareDays === "6 Months" ||
                  determineComparison.compareDays === "12 Months") &&
                "smaller-market-title-section"
              }`}
            >
              <p>Last {determineComparison.compareDays}</p>
              <p className="lc-market-title">vs</p>
              <p> Previous {determineComparison.compareDays}</p>
            </div>
          )}
        </div>
        <div className="indicator-container">
          <div className="inner-indicator-container">
            <IndicatorSection
              category="Sale Prices"
              value={determineComparison.salePriceDataPoint}
              perc={determineComparison.salePriceDiff}
              currentArea={currentArea}
            />
            <IndicatorSection
              category="% Received of Asking"
              value={determineComparison.receivedDataPoint}
              perc={determineComparison.receivedPerc}
              currentArea={currentArea}
            />
            <IndicatorSection
              category="Days to Sell"
              value={determineComparison.daysToSellDataPoint}
              perc={determineComparison.daysToSellPerc}
              currentArea={currentArea}
            />

            <IndicatorSection
              category="Square Ft"
              value={determineComparison.sqftDataPoint}
              perc={determineComparison.sqftPercDiff}
              currentArea={currentArea}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default YETwoMarketIndicators;
