import React, { Component } from "react";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
import QVFourAgentLogoHeader from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourAgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import QVFourPhotos from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotos";
import YETwoShortenedMarketTable from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTable";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import GNQRCode from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/GNQRCode";
import MissionStatementBlock from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/MissionStatementBlock";

class YETwoSecondRight extends Component {
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,
      aprilData,
      demoAccountHOName,
      propertyPhotoDetailsDTO,
      oldPhotoDetails,
      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
      missionStatement,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { currentSoldListings } = quarterlyAreaTable;

    if (mlsAreaID.includes("xSBA")) {
      return (
        <div
          className={`${
            version === "year-end-2022" ? "ye2" : "qv4"
          }-second-right`}
        >
          <QVFourAgentLogoHeader agentData={agentData} />
          {hoData &&
            hoData.changeDetails &&
            Object.keys(hoData.changeDetails).length > 1 && (
              <QVFourPS
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                version={version}
                printVersion={printVersion}
                mailingNum="qv4"
                demoAccountHOName={demoAccountHOName}
                isDemo={isDemo}
              />
            )}
          <GNQRCode hoData={hoData} agentData={agentData} smallerQR={true} />
          <MissionStatementBlock
            printVersion={printVersion}
            missionStatement={missionStatement}
            agentData={agentData}
          />
          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={currentSoldListings}
            printVersion={printVersion}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`${
            version === "year-end-2022" ? "ye2" : "qv4"
          }-second-right`}
        >
          <QVFourAgentLogoHeader agentData={agentData} />
          {hoData &&
            hoData.changeDetails &&
            Object.keys(hoData.changeDetails).length > 1 && (
              <QVFourPS
                agentData={agentData}
                hoData={hoData}
                currentArea={currentArea}
                mlsAreas={mlsAreas}
                version={version}
                printVersion={printVersion}
                mailingNum="qv4"
                demoAccountHOName={demoAccountHOName}
                isDemo={isDemo}
              />
            )}
          <GNQRCode hoData={hoData} agentData={agentData} />
          <MissionStatementBlock
            printVersion={printVersion}
            agentData={agentData}
            missionStatement={missionStatement}
          />
          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={currentSoldListings}
            printVersion={printVersion}
          />
        </div>
      );
    }
  }
}

export default YETwoSecondRight;
