import React, { Component } from "react";
import QVFourFirstLeft from "./QVFourFirstLeft";
import QVFourFirstRight from "./QVFourFirstRight";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";

import RGB from "views/Pma/PmaEditor/CustomAgentPages/RGB";

class QVFourFirstPage extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      mlsAreas,

      printVersion,
      version,
      coverPageDetails,
      quarterlyAreaTable,
      testimonials,
      quadrantModalOpen,
      quadrantDetails,
      propertyPhotoDetailsDTO,
      missionStatement,
      isDemo,
    } = this.props;

    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv4-print-page-container"
            : "qv4-pma-editor-page-container")
        }
      >
        {(printVersion && mlsAreaID === "SBA-10A") ||
        (printVersion && mlsAreaID === "SBA-10G") ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : listgenUserID === "100585" ? (
          <V2DebSamuel
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : (
          <QVFourFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            missionStatement={missionStatement}
            isDemo={isDemo}
          />
        )}
        <QVFourFirstRight
          agentData={agentData}
          hoData={hoData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          quadrantModalOpen={quadrantModalOpen}
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default QVFourFirstPage;
