import React, { Component } from "react";

class UnderConstruction extends Component {
  render() {
    const { altIssue, fromMailPro } = this.props;
    if (fromMailPro) {
      return (
        <div className="under-construction">
          <p>Greetings ListGen Family!</p>
          <br />

          <p>
            Your May Precision Market Analysis is currently under construction.
          </p>
          <br />

          <p>Stay tuned...It will be available to view SOON!</p>
          <br />
          <p className="green">
            We know your homeowners are going to love this PMA!
          </p>
        </div>
      );
    } else if (altIssue) {
      return (
        <div className="under-construction">
          <p> Greetings Agents!</p>
          <br />

          <p>
            Your May Precision Market Analysis is currently under construction.
          </p>
          <br />

          <p>Stay tuned...It will be available to view SOON!</p>
          <br />
          <p className="green">
            We know your homeowners are going to love this PMA!
          </p>
          <div className="compass-margin-top-construction">
            <p> In the meantime, MailPro is is LIVE!</p>
            <p>
              Feel free to PERSONALIZE your Homeowners' names and click through
              your Happy Anniversary! notes, TAG Homeowners' you want to keep
              track of, and write any PERSONAL NOTE to Homeowners you know and
              want to send a special 'Hello' to...
            </p>
            <p>
              {" "}
              Of course, all your updates are automatically saved.&#128521;
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="under-construction">
          <p>Greetings ListGen Family!</p>
          <br />

          <p>
            Your New <span className="blue">PMA</span> is under construction.{" "}
          </p>
          <br />
          {/*<p>We will have your other PMA's ready to look at later today</p>*/}
          {/*<p>
            Please feel free to create your own <strong>Global Note</strong>{" "}
            and/or any <strong>Personal Notes in MailPro</strong>
          </p>
          <br />
          <p>All your inputs are automatically saved!</p>
          <br />*/}

          {/*<p style={{ color: "red", fontSize: "20px" }}>
            <strong>
              This PMA is scheduled to be completed for you to review on Saturday
              February 20th.
            </strong>
          </p>
          <br />*/}
          {/*<p>
            As usual, we will Express Mail a hard copy of your PMAs on Wednesday
            January 13th.{" "}
          </p>
          <br />*/}

          {/*<p className="blue">The scheduled mail date is Monday January 18th. </p>
          <br />*/}
          <p className="green">
            We know your homeowners are going to love this PMA!
          </p>
        </div>
      );
    }
  }
}

export default UnderConstruction;
