import Base from "common/constants";
import querystring from "querystring";
import { CommonAxiosAPIHandler, GetAuthorization, convertV2AgentsDatatoV1 } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import axios from "common/interceptors";

export const GetAgentDashboard = (emailAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_AGENT_DASHBOARD_API, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            emailAddress,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAgentBasicDetails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_AGENT_BASIC_DETAILS_API, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            registerToken: GetOAuthTokenByKey("jti"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
  ////////////
  // return new Promise((resolve, reject) => {
  //   try {
  //     fetch(
  //       `${Base.GET_AGENT_BASIC_DETAILS_API}/?registerToken=` +
  //       GetOAuthTokenByKey('jti'),
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
  //           'Cache-Control': 'no-cache',
  //         },
  //       }
  //     )
  //       .then(response => response.json())
  //       .then(res => {
  //         if (Object.keys(res).length > 0) {
  //           resolve(res);
  //         } else {
  //           reject('Unable to process request.');
  //         }
  //       })
  //       .catch(err => {
  //         if (
  //           err.response &&
  //           err.response.data &&
  //           err.response.data.error_description
  //         )
  //           reject(err.response.data.error_description);
  //         else reject('OOPS!!! something went wrong.');
  //       });
  //   } catch (err) {
  //     reject('Something went wrong. Unable to process request.');
  //   }
  // });
};

export const GetAgentDetail = (listgenUserID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_AGENT_DETAILS_BY_LISTGEN_ID, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
  // return new Promise((resolve, reject) => {
  //   try {
  //     fetch(
  //       `${Base.GET_AGENT_DETAILS_BY_LISTGEN_ID}/?listgenUserID=` +
  //       listgenUserID,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${GetOAuthTokenByKey('access_token')}`,
  //           'Access-Control-Allow-Origin': '*',
  //         },
  //       }
  //     )
  //       .then(response => response.json())
  //       .then(res => {
  //         if (Object.keys(res).length > 0) {
  //           resolve(res);
  //         } else {
  //           reject('Unable to process request.');
  //         }
  //       })
  //       .catch(err => {
  //         if (
  //           err.response &&
  //           err.response.data &&
  //           err.response.data.error_description
  //         )
  //           reject(err.response.data.error_description);
  //         else reject('OOPS!!! something went wrong.');
  //       });
  //   } catch (err) {
  //     reject('Something went wrong. Unable to process request.');
  //   }
  // });
};

export const RegisterAgent = (formData) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(Base.REGISTER_AGENT_API, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

//this is not waiting api so
export const UpdateMailproAfterRegistrations = (listgenUserID, countyID) => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(Base.UPDATE_MAILPRO_AFTER_REGISTER_API, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
        },
        params: {
          listgenUserID,
          inputAreaID: "-",
          fromDateStr: "-",
          callingType: "-",
          countyID,
        },
      });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdateAgentDetails = (formData) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(Base.UPDATE_AGENT_DETAILS_API, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
          },
        })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const CreateAgentAPI = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.CREATE_AGENT_API, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAllAgentAPI = (userStatus) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_ALL_AGENTS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            userStatus,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const SearchAgentByStatusAPI = (countyID = null, userStatus = "active", queryType = 'county', marketScheduleID = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.SEARCH_AGENT_BY_STATUS_V1, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            userStatus,
            queryType,
            queryValue: queryType === 'county' ? countyID : marketScheduleID,
          },
        })
        .then((res) => {
          //sort in asc order
          if (res.status === 200) {
            res.data.sort((a, b) =>
                b["listgenUserFirstName"] > a["listgenUserFirstName"]
                  ? -1
                  : b["listgenUserFirstName"] < a["listgenUserFirstName"]
                  ? 1
                  : 0
              );
            //we are doing this change because in some apis we are getting listAreaDetails and in some we are getting navbarInfo. but on some screen we need navbarInfo
            //Remove this in future if not required
            resolve(convertV2AgentsDatatoV1(res.data));
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const SearchIntoAgentAPI = (
  userStatus,
  countyID,
  listgenUserFirstName,
  listgenUserLastName,
  mobilePhoneNo,
  emailAddress,
  mailingCity,
  brokerLicenseNumber,
  agentLicenseNumber,
  listgenUserID,
  teamName
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.AGENT_SEARCH_API, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            userStatus,
            countyID,
            listgenUserFirstName,
            listgenUserLastName,
            mobilePhoneNo,
            emailAddress,
            mailingCity,
            brokerLicenseNumber,
            agentLicenseNumber,
            listgenUserID,
            teamName,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            //asc order
            resolve(
              res.data.sort((a, b) =>
                b["listgenUserFirstName"] > a["listgenUserFirstName"]
                  ? -1
                  : b["listgenUserFirstName"] < a["listgenUserFirstName"]
                  ? 1
                  : 0
              )
            );
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RegisterAgentValidate = (registerToken) => {
  /**Register agent token validation */
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.VALIDATE_REGISTER_AGENT_API}?registerToken=` + registerToken,
          {
            headers: {
              "Content-Type": "application/json",
              //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const OnLoadRegisterAgentData = (emailAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.ON_LOAD_REGISTER_AGENT_API}?emailAddress=` + emailAddress,
          {
            headers: {
              "Content-Type": "application/json",
              //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RegisterAgentValidateEmail = (emailAddress, listgenUserID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(
          `${Base.REGISTER_AGENT_VALIDATE_EMAIL_API}?emailAddress=` +
            emailAddress +
            "&listgenUserID=" +
            listgenUserID,
          {
            headers: {
              "Content-Type": "application/json",
              //'Authorization': `Basic ${await GetAuthorization()}`,
              //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
              "Access-Control-Allow-Origin": "*",
            },
            data: {},
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RegisterAgentOnSubmitAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.REGISTER_AGENT_ON_SUBMIT_API, data, {
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const AddAgentCCInfo = (
  listgenUserID,
  emailID,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvc,
  nameOnCard = null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.CREATE_AGENT_WITH_CC_INFO,
          {
            listgenUserID,
            emailID,
            cardNumber,
            expiryMonth,
            expiryYear,
            cvc,
            nameOnCard,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RegisterAgentUploadPhotoAPI = (
  type,
  listgenUserID,
  fileData,
  uploadedByAdmin
) => {
  console.log("type", type);
  console.log("listgenUserID", listgenUserID);
  console.log("fileData", fileData);
  console.log("uploadedByAdmin", uploadedByAdmin);
  let url = "";
  if (type === "profile") {
    url = Base.UPLAOD_AGENT_PHOTO;
  } else if (type === "logo") {
    url = Base.UPLOAD_AGENT_LOGO;
  } else if (type === "logo2") {
    url = Base.UPLAOD_AGENT_LOGO_2;
  } else if (type === "signature") {
    url = Base.UPLOAD_AGENT_SIGNATURE;
  } else if (type === "brokerageLogo") {
    url = Base.UPLOAD_AGENT_BROKERAGE_LOGO;
  } else if (type === "designationLogo") {
    url = Base.UPLOAD_DESIGNATION_LOGO_IMAGE;
  } else if (type === "propertyHighlight") {
    // url = Base.UPLOAD_PROPERTY_HIGHLIGHT_PHOTO;
    url = Base.PROP_HIGHLIGHTS_PHOTO_OPTIMIZATION;
  } else if (type === "coverPhoto") {
    url = Base.UPLOAD_COVER_PHOTO_IMAGE;
  }
  const formData = new FormData();
  formData.append("file", fileData);
  formData.append("listgenUserID", listgenUserID);
  formData.append("uploadedByAdmin", uploadedByAdmin);
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RegisterAgentUploadTeamSignatureAPI = (
  listgenUserID,
  fileData
) => {
  const formData = new FormData();
  formData.append("file", fileData);
  formData.append("listgenUserID", listgenUserID);
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.UPLOAD_AGENT_TEAM_SIGNATURE, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            //'Authorization': `Bearer ${GetOAuthTokenByKey('access_token')}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdateAgentDetailsAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.UPDATE_AGENT_DETAILS, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetAgentCreditCardListAPI = (listgenUserID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_AGENT_ALL_CREDIT_CARDS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const AddCreditCardForAnAgentAPI = (
  nameOnCard,
  listgenUserID,
  customerID,
  emailID,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvc,
  isDefault
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.ADD_AGENT_CREDIT_CARD_INFO,
          {
            nameOnCard: nameOnCard,
            listgenUserID: listgenUserID,
            customerID: customerID,
            emailID: emailID,
            cardNumber: cardNumber,
            expiryMonth: expiryMonth,
            expiryYear: expiryYear,
            cvc: cvc,
            isDefault: isDefault,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RemoveAgentFromStripeWithCreditCardAPI = (
  listgenUserID,
  customerID
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.REMOVE_AGENT_FROM_STRIPE_CREDIT_CARD_LG_DB, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
            customerID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const DeleteAgentCreditCardAPI = (listgenUserID, customerID, cardID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.DELETE_AGENT_CREDIT_CARD_LG_DB_STRIPE, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
            customerID,
            cardID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const SetDefaultCreditCardForAnAgentAPI = (
  listgenUserID,
  cardID,
  customerID
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.SET_AGENT_DEFAULT_CREDIT_CARD, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
            cardID,
            customerID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const ChargeAgentCreditCardAPI = (
  listgenUserID,
  customerID,
  cardID,
  amount,
  currency
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(
          Base.CHARGE_AGENT_CREDIT_CARD,
          {
            listgenUserID: listgenUserID,
            customerID: customerID,
            cardID: cardID,
            amount: amount,
            currency: currency,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

/**Special Payment */
export const GetAllSpecialPaymentAgentAPI = () => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_ALL_SPECIAL_PAYMENT_AGENTS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetSpecialPaymentForAnAgentAPI = (listgenUserID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.GET_SPECIAL_PAYMENT_FOR_AN_AGENT, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const AddUpdateSpecialPaymentForAnAgent = (specialPaymentData) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(Base.ADD_UPDATE_SPECIAL_PAYMENT_AGENTS, specialPaymentData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const RemoveAllSpecialPaymentsForAnAgentAPI = (listgenUserID) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(Base.REMOVE_ALL_SPECIAL_PAYMENT_FOR_A_AGENT, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
          params: {
            listgenUserID,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const AddUpdatePmaAdmin = (mode, data) => {
  let apiData = {
    addTestimonials: Base.ADD_NEW_CLIENT_TESTIMONIALS,
    updateTestimonials: Base.UPDATE_A_CLIENT_TESTIMONIALS,
    addUpdateStrength: Base.ADD_UPDATE_STRENGTH_DESIGNATION,
    addUpdateAdvantages: Base.ADD_UPDATE_ADVANTAGES,
    addMission: Base.ADD_NEW_MISSION_STATEMENT,
    updateMission: Base.UPDATE_NEW_MISSION_STATEMENT,
  };
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .post(apiData[mode], data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const GetPmaAdminDataAPI = (mode, listgenUserID, otherArgs = {}) => {
  let finalDataObj = Object.assign({ listgenUserID }, otherArgs);
  let apiData = {
    getTestimonials: Base.GET_ALL_CLIENT_TESTIMONIALS,
    deleteTestimonials: Base.DELETE_A_CLIENT_TESTIMONIAL,
    getStrengths: Base.GET_AGENT_STRENGTH_DESIGNATIONS,
    getAdvantages: Base.GET_AGENT_ADVANTAGES,
    getMissionStatements: Base.GET_ALL_MISSION_STATEMENTS,
    deleteStatement: Base.DELETE_MISSION_STATEMENT,
    setCurrentStatement: Base.SET_CURRENT_MISSION_STATEMENT,
    setCurrentTestimonial: Base.SET_CURRENT_TESTIMONIAL
  };
  //make params foramt for finalDataObj
  const queryString = new URLSearchParams(finalDataObj).toString();
  return CommonAxiosAPIHandler("GET", [
    `${apiData[mode]}?${queryString}`
  ]);
};

export const GetFreeAreasByCountyID = (countyId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(`${Base.GET_FREE_AREAS_BY_COUNTY_ID}?countyID=${countyId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: {},
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
export const UpdatePropertyHighlightsForSingleArea = (formData) => {
  console.log("**prop highlights for single area", formData);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(Base.UPDATE_PROPERTY_HIGHLIGHTS_FOR_SINGLE_AREA, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
          },
        })
        .then((res) => {
          console.log("after then statement", res);
          if (res.data) {
            console.log("inside api data", res.data);
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          console.log("inside error", err);
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const UpdatePropertyHighlights = (formData) => {
  console.log("**formData about to submit api", formData);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(Base.UPDATE_PROPERTY_HIGHLIGHTS, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
          },
        })
        .then((res) => {
          console.log("after then statement", res);
          if (res.data) {
            console.log("inside api data", res.data);
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          console.log("inside error", err);
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};

export const PropertyHighlightPhotoOptimization = (photoURL) => {
  console.log("********prop highlight optimization api", photoURL);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(
          Base.PROP_HIGHLIGHTS_PHOTO_OPTIMIZATION,
          { photoURL },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
            },
          }
        )
        .then((res) => {
          console.log("after res", res);
          if (res.data) {
            console.log("****inside api optimization", res.data);
            resolve(res.data);
          } else {
            reject("Unable to process request.");
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.error_description
          )
            reject(err.response.data.error_description);
          else reject("OOPS!!! something went wrong.");
        });
    } catch (err) {
      reject("Something went wrong. Unable to process request.");
    }
  });
};
