import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import MQThreeMarketSaleRow from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeMarketSaleRow";
import MarketTableBG from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/green-table.png";
import UpdatedMarketTableBG from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/green-table.png";

class YETwoShortenedMarketTable extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  getTitle = (area) => {
    const {
      changeDetails,
      subdivisionName,
      coverPageHeader,
    } = this.props.hoData;

    const { listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props.currentArea;
    const { demoAccountAreaName } = this.props;
    const { splitAreas } = propertyUtil;
    let areaSplit = splitAreas(area);

    let characterCount = areaSplit.join("").length + areaSplit.length;

    let specialFontSize;
    if (characterCount > 40) {
      specialFontSize = "xs-market-sales-title";
    } else if (characterCount > 33) {
      specialFontSize = "small-market-sales-title";
    } else if (characterCount > 27) {
      specialFontSize = "medium-market-sales-title";
    } else {
      specialFontSize = "regular-market-sales-title";
    }

    if (characterCount > 32) {
      return <div className="market-sales-title-text">Your Neighborhood</div>;
    } else if (coverPageHeader === "Upper East and Lower Mission Canyon") {
      return (
        <div className="market-sales-title-text small-market-sales-title">
          Lower Mission Canyon and Upper East
        </div>
      );
    } else if (area === "Winchester Canyon") {
      return (
        <div className="market-sales-title-text">
          Rancho Embarcadero & Winchester
        </div>
      );
    } else if (demoAccountAreaName && demoAccountAreaName.length > 1) {
      return (
        <div className="market-sales-title-text">{demoAccountAreaName}</div>
      );
    } else if (
      (changeDetails && changeDetails.subdivisionName) ||
      (subdivisionName && subdivisionName.length > 1)
    ) {
      return (
        <div className="market-sales-title-text">
          {subdivisionName ? subdivisionName : changeDetails.subdivisionName}
        </div>
      );
    } else {
      return (
        <div className={`market-sales-non-sub-container ${specialFontSize}`}>
          {areaSplit.map((area, i) => {
            return (
              <div key={area} className="market-sales-inside-title-row">
                {i > 0 && (
                  <div
                    className={`market-sales-title-separator ${specialFontSize}`}
                  ></div>
                )}

                <p className={`market-sales-title-text ${specialFontSize}`}>
                  {area}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };
  // sortByStatus = (propList) => {
  //   const {
  //     activePhotoDetailsList,
  //     contingentPhotoDetailsList,
  //     pendingPhotoDetailsList,
  //     preSoldPhotoDetailsList,
  //     recentSoldPhotoDetailsList,
  //   } = this.props.quarterlyAreaTable;
  //
  //   let sliced = propList
  //     .slice(0, 19)
  //     .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
  //   let sold = recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList : [];
  //   let pend = pendingPhotoDetailsList ? pendingPhotoDetailsList : [];
  //   let cont = contingentPhotoDetailsList ? contingentPhotoDetailsList : [];
  //   let active = activePhotoDetailsList ? activePhotoDetailsList : [];
  //   let laterSold = preSoldPhotoDetailsList ? preSoldPhotoDetailsList : [];
  //
  //   let last45DaysSum =
  //     (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
  //     (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
  //     (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
  //     (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);
  //
  //   let remainderPreSold = 10 - last45DaysSum;
  //
  //   // let sold = sliced.filter((x) => x.status === "Sold");
  //   // let pend = sliced.filter((x) => x.status === "Pending");
  //   // let cont = sliced.filter((x) => x.status === "Contingent");
  //   // let active = sliced.filter((x) => x.status === "Active");
  //   let trueSort = [
  //     ...active,
  //     ...pend,
  //     ...cont,
  //     ...sold,
  //     ...laterSold.slice(0, remainderPreSold),
  //   ];

  //   return trueSort;
  //   // return propList.slice(0, 19);
  // };
  getOldestDate = (propList) => {
    let sliced = propList
      .slice(0, 18)
      .sort((a, b) => new Date(a.saleDate) - new Date(b.saleDate))[0].saleDate;

    let formatted = moment(sliced).format("MMM D, YYYY");
    return formatted;
  };
  getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  formatLastPrintDate = (date) => {
    return moment(date).format("MMM D, YYYY");
  };
  isAgentProperty = (prop) => {
    const { agentFilteredPhotoDetailsList } = this.props.quarterlyAreaTable;
    let someAgentsProperty = agentFilteredPhotoDetailsList.some(
      (property) => property.isAgentFiltered === true
    );

    if (someAgentsProperty) {
      return true;
    } else {
      return false;
    }
    if (
      agentFilteredPhotoDetailsList &&
      agentFilteredPhotoDetailsList.length > 1
    ) {
      let foundObject = agentFilteredPhotoDetailsList.filter((property) => {
        return (
          property.streetName === prop.streetName &&
          property.streetNumber === prop.streetNumber &&
          property.sellingAgentLicenseID === prop.sellingAgentLicenseID &&
          property.status === prop.status &&
          property.listingAgentLicenseID === prop.listingAgentLicenseID
        );
      });

      if (foundObject.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  render() {
    const {
      currentArea,
      quarterlyAreaTable,
      printDate,
      printVersion,
      lastMidQuarterlyPrintDate,
      currentSoldListings,
      sorted,
      useExtendedTable,
      agentData,
    } = this.props;

    let {
      listgenUserID,
      agentLicenseNumber,
      isMemberOfTeam,
      teamMembers,
    } = agentData;
    if (listgenUserID === "102843") {
      isMemberOfTeam = true;
    }
    const {
      totalActiveListings,
      totalPendingListings,
      daysToSellSinceLastMidQtr,
      monthsSupplyTable,
      activePhotoDetailsList,
    } = quarterlyAreaTable;

    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      splitAreas,
      countAreaChars,
      slashSeparatedArea,
    } = propertyUtil;

    let tableProperties;
    // 1. activePhotoDetailsList - Contains Active Listings
    // 2. contingentPhotoDetailsList - Contains Contingent Listings
    // 3. pendingPhotoDetailsList - Contains Pending Listings
    // 4. preSoldPhotoDetailsList - Contains Solds  which is more than 45 days old
    // 5.  recentSoldPhotoDetailsList - Contains 45 Days Sold

    const {
      contingentPhotoDetailsList,
      pendingPhotoDetailsList,
      preSoldPhotoDetailsList,
      recentSoldPhotoDetailsList,
    } = quarterlyAreaTable;
    let last45DaysSum =
      (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
      (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
      (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
      (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);

    // if (last45DaysSum > 10) {
    //   useExtendedTable = true;
    //   console.log("**USE EXTENDED TABLE");
    // }

    const { mlsAreaID, mlsNeighborhood } = currentArea;
    let isBuellton = mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G";
    const { getTitle, getOldestDate, isAgentProperty } = this;
    const midQuarterSoldListingCount = quarterlyAreaTable.midQuarterSoldListingsCount
      ? quarterlyAreaTable.midQuarterSoldListingsCount
      : 0;

    let totalActives = isBuellton
      ? monthsSupplyTable.CityNumberOfHousesForSale
      : monthsSupplyTable.AreaNumberOfHousesForSale;

    const totalSolds = this.props.quarterlyAreaTable.soldListingsCount;
    // let sorted =
    //   quarterlyAreaTable && quarterlyAreaTable.marketActivityTable
    //     ? this.sortByStatus(quarterlyAreaTable.marketActivityTable)
    //     : [];
    // let sorted =
    //   currentSoldListings && currentSoldListings
    //     ? this.sortByStatus(currentSoldListings)
    //     : [];
    let oldestSaleDate = sorted
      .filter((prop) => prop.saleDate !== "-")
      .map((x) => x.saleDate)
      .sort((a, b) => new Date(a) - new Date(b))[0];

    const soldNum =
      (quarterlyAreaTable.marketActivityTable &&
        quarterlyAreaTable.marketActivityTable.filter(
          (list) => list.status === "Sold"
        ).length) ||
      0;
    // console.log(
    //   "quarterlyAreaTable.marketActivityTable",
    //   quarterlyAreaTable.marketActivityTable
    // );
    const activesNum =
      quarterlyAreaTable.marketActivityTable &&
      quarterlyAreaTable.marketActivityTable.filter(
        (list) => list.status === "Active"
      ).length;

    const contingentsNum =
      quarterlyAreaTable.marketActivityTable &&
      quarterlyAreaTable.marketActivityTable.filter(
        (list) => list.status === "Contingent"
      ).length;
    console.log("***sorted", sorted);
    const currentSolds = (
      <MQThreeMarketSaleRow
        solds={sorted}
        mlsAreaID={mlsAreaID}
        isSingleTable={true}
        agentData={agentData}
        agentProperties={quarterlyAreaTable.agentFilteredPhotoDetailsList}
        isAgentProperty={isAgentProperty}
      />
    );

    const tableHeader = (
      <thead>
        <tr>
          <th>Status</th>
          <th>
            <p>Sale </p>
            <p>Date</p>
          </th>
          <th>
            <p>Property</p>
            <p>Type</p>
          </th>

          <th>Address</th>
          <th>List Price</th>
          <th>Sale Price</th>

          <th>
            <p>Days on</p>
            <p>Market</p>
          </th>

          <th>
            <p>Beds</p>
          </th>
          <th>
            <p>Baths</p>
          </th>
          <th>
            <p>Living</p>
            <p>SqFt</p>
          </th>
        </tr>
      </thead>
    );
    let useRecentToOldDate = true;
    if (listgenUserID === "105794") {
      useRecentToOldDate = true;
    }

    let isLosOlivos = mlsAreaID === "SBA-40G";

    const pendingAndContingents =
      Number(totalPendingListings) + Number(contingentsNum);
    let tableStyles;
    if (currentSolds && currentSolds.length <= 8) {
      tableStyles = "move-table-up";
    }

    let allAgentLicenses = [];
    if (isMemberOfTeam && teamMembers && teamMembers.length > 1) {
      teamMembers.map((member) =>
        allAgentLicenses.push(member.agentLicenseNumber)
      );
    }

    let needsAgentDisclaimer = sorted.some(
      (list) => list.isAgentFiltered === true
    );
    let hasOffMarketDisclaimer = sorted.some(
      (property) => property.isOffMarketSale === "1"
    );

    let extraDisclaimer = false;

    if (mlsAreaID === "SMA-470") {
      let has2533Hayward = sorted.some(
        (list) =>
          list.streetNumber === "2533" &&
          list.streetName === "Hayward" &&
          list.streetSuffix === "Dr"
      );
      if (has2533Hayward) {
        extraDisclaimer = true;
      }
    }

    return (
      <div
        className={`${
          printVersion
            ? "print-ye2-market-sales-table-v2"
            : "editor-ye2-market-sales-table-v2"
        } ${useExtendedTable && "extended-table-container"} ${tableStyles}`}
      >
        <div className="market-sales-title-container">
          {getTitle(mlsNeighborhood)}
          <p className="market-sales-subtitle-text larger-subtitle-text">
            Here's What's Happening in{" "}
            {isLosOlivos
              ? "LOS OLIVOS"
              : isBuellton
              ? "BUELLTON"
              : listgenUserID === "101332" && mlsAreaID === "SCL-14H"
              ? "Our Neighborhood"
              : mlsAreaID === "SMA-390"
              ? "Your Sea Colony Neighborhood"
              : "Your Neighborhood"}
          </p>
          {needsAgentDisclaimer ? (
            <div className="ye2-shortened-table-disclaimer">
              <div className="ye2-shortened-table-disclaimer-text">
                {listgenUserID === "106421" ? (
                  <p>
                    Julie Flouty's Listings and Sales{" "}
                    <span className="color-1">Highlighted in Blue</span>
                  </p>
                ) : (
                  <p>
                    Take a Look at {isMemberOfTeam ? "Our" : "My"} Listings and
                    Sales <span className="color-1">Highlighted in Blue</span>
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="ye2-shortened-table-disclaimer"></div>
          )}

          {extraDisclaimer && (
            <div className="ye2-shortened-table-disclaimer">
              <div className="ye2-shortened-table-disclaimer-text">
                <p>** Outlier selling price not included in averages</p>
              </div>
            </div>
          )}
        </div>

        <div className="subtitle-row">
          <div className="left-subtitle-section">
            <div className="left-subtitle-text">
              {useRecentToOldDate ? (
                <p className="small-left-subtitle">
                  {this.formatLastPrintDate(oldestSaleDate)} —{" "}
                  {this.getTodayDate()}
                </p>
              ) : (
                <p className="small-left-subtitle">
                  {this.getTodayDate()} —{" "}
                  {this.formatLastPrintDate(oldestSaleDate)}
                </p>
              )}
            </div>
            <div className="right-subtitle-section">
              <p>Active Listings: {totalActiveListings}</p>
              <div className="right-subtitle-divider">|</div>
              <p>Pending: {pendingAndContingents}</p>
            </div>
          </div>
          <div className="golden-divider extra-long" />
        </div>
        <div className="market-table-containers">
          {printVersion ? (
            <div
              className={`mini-table-gradient ${
                useExtendedTable && "extended-bg-image"
              }`}
            ></div>
          ) : (
            <img
              src="https://pma-coverphotos.s3.amazonaws.com/non-covers/020724_TableBG_DogWood-1.png"
              alt="Market Table Cloud"
              className={`market-table-bg-image ${
                useExtendedTable && "extended-bg-image"
              }`}
            />
          )}

          <div className="both-market-tables">
            {useExtendedTable ? (
              <table className="regular-sales-table">
                {sorted.length >= 1 ? tableHeader : null}
                {currentSolds}
              </table>
            ) : (
              <table className="regular-sales-table">
                {sorted.length >= 1 ? tableHeader : null}
                {currentSolds}
              </table>
            )}
          </div>
          {hasOffMarketDisclaimer && (
            <div className="off-market-disclaimer">
              <div className="off-market-disclaimer-text">
                *Sold privately off market and was not publicly listed on the
                Multiple Listing Service. (MLS)
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default YETwoShortenedMarketTable;
