import React, { Component } from "react";
import QVTwoFirstPage from "./QVTwoFirstPage";
import QVTwoSecondPage from "./QVTwoSecondPage";

import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles";
import { withSnackbar } from "notistack";

class QVTwo extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./qv2print.scss");
    } else {
      require("./qv2styles.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      quarterlyAreaTable,
      navbarData,
      coverPageDetails,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      propertyPhotoDetailsDTO,
      bubbleBoxes,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
    } = this.props;

    return (
      <div>
        <div
          className={printVersion ? "qv2-print-page" : "qv2-pma-editor-page"}
        >
          <QVTwoFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            navbarData={navbarData}
            quarterlyAreaTable={quarterlyAreaTable}
            coverPageDetails={coverPageDetails}
            yearEnd={yearEnd}
            qArea={qArea}
            qCity={qCity}
            aprilData={aprilData}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            bubbleBoxes={bubbleBoxes}
          />
          <QVTwoSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            navbarData={navbarData}
            quarterlyAreaTable={quarterlyAreaTable}
            coverPageDetails={coverPageDetails}
            qArea={qArea}
            qCity={qCity}
            aprilData={aprilData}
            recentActivityPhotoList={recentActivityPhotoList}
            photoCheckboxModalOpen={photoCheckboxModalOpen}
          />
        </div>
      </div>
    );
  }
}

export default compose(withSnackbar, withStyles(styles))(QVTwo);
