import Base from "common/constants";
import querystring from "querystring";
import { CommonAxiosAPIHandler, GetAuthorization } from "common/helpers";
import { GetOAuthTokenByKey } from "common/storage";
import { isArray } from "util";
import axios from "common/interceptors";

export const GetPmaEstimatedInvoiceData = (listgenUserID) => {
  return CommonAxiosAPIHandler("GET", [
    `${Base.PMA_ESTIMATED_INVOICE_DATA}?listgenUserID=${listgenUserID}`
  ]);
};
