import React, { Component } from "react";

import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/YETwoFirstLeft";
import YETwoFirstRight from "./YETwoFirstRight";
import { connect } from "react-redux";

import QVFourFirstLeft from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourFirstLeft";

import TableQuadrantsFirstLeft from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageOne/TableQuadrantsFirstLeft";

class YETwoFirstPage extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      navbarData,
      printVersion,
      version,
      viewingGeneratedCL,
      coverPageDetails,

      quarterlyAreaTable,

      aprilData,

      demoAccountHOName,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      testimonials,
      typeOfVersion,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      quadrantModalOpen,
      quadrantDetails,
      isDemo,
      bubbleBoxes,
    } = this.props;

    const { mlsAreaID } = currentArea;

    let showPropertyHighlightPage = true;

    //  console.log("quadrantDetails", quadrantDetails);
    //create logic for agent focus page

    let showTableQuadrants = false;

    // if (
    //   (propertyPhotoDetailsDTO &&
    //     propertyPhotoDetailsDTO.photoDetails.length === 0) ||
    //   listgenUserID === "103404"
    // ) {
    //   showTableQuadrants = true;
    // }

    // if (
    //   listgenUserID === "104410" ||
    //   listgenUserID === "105678" ||
    //   listgenUserID === "105246" ||
    //   listgenUserID === "101332" ||
    //   listgenUserID === "105224" ||
    //   listgenUserID === "105016" ||
    //   listgenUserID === "102734"
    // ) {
    //   showPropertyHighlightPage = false;
    //   showTableQuadrants = false;
    // }
    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "ye2-print-page-container"
            : "ye2-pma-editor-page-container")
        }
      >
        {showPropertyHighlightPage ? (
          <YETwoFirstLeft
            auth={auth}
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            demoAccountHOName={demoAccountHOName}
            demoAccountAreaName={demoAccountAreaName}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quarterlyAreaTable={quarterlyAreaTable}
            mlsAreaID={mlsAreaID}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
            quadrantDetails={quadrantDetails}
          />
        ) : showTableQuadrants ? (
          <TableQuadrantsFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            isDemo={isDemo}
          />
        ) : (
          <QVFourFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
          />
        )}

        <YETwoFirstRight
          auth={auth}
          agentData={agentData}
          hoData={hoData}
          navbarData={navbarData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          viewingGeneratedCL={viewingGeneratedCL}
          coverPageDetails={coverPageDetails}
          aprilData={aprilData}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountAreaName={demoAccountAreaName}
          typeOfVersion={typeOfVersion}
          quarterlyAreaTable={quarterlyAreaTable}
          isDemo={isDemo}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quadrantModalOpen: state.pma.quadrantModalOpen,
    // quadrantDetails:  state.pma.quadrantDetails,
  };
};
export default connect(mapStateToProps)(YETwoFirstPage);
