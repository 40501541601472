import React, { Component } from "react";
import MappedNav from "./MappedNav";
import { connect } from "react-redux";
import { pmaActions } from "../../../_store/_actions";
import {
  GetStateStorage,
  IsValidNavbarData,
  SetNavbarDataInStorage,
} from "common/storage";
import { propertyUtil } from "../PropertyUtil";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import styles from "./styles";
import { Backdrop, withStyles, Button } from "@material-ui/core";

class PMANavbar extends Component {
  constructor() {
    super();
    this.state = {
      options: null,
      allAreas: [],
    };
    this.changeCurrentArea = this.changeCurrentArea.bind(this);
  }

  /**
   * Using this method in approval process also. Index logic if we are chaing then chnage in property util also Method is: getOneUnapprovedAreaData
   */
  async changeCurrentArea(index1, area, listgenUserID) {
    let { version, typeOfMQVersion, typeOfQVersion, agentData } = this.props;
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    const { mlsAreaID, mlsCityID } = area;
    let areaAndDate = `${area.mlsAreaID}-${thisYear}-${thisMonth}-${today}`;
    const cityAndDate = `${area.mlsCityID}-${thisYear}-${thisMonth}-${today}`;

    await this.props.changeLoading(true);
    await this.props.switchArea(area);
    let isMailpro = window.location.href.includes("/mailpro");
    console.log("area", area);
    setTimeout(async () => {
      console.log("inside timeout for navbar");
      if (isMailpro) {
        //if its MailPro only load mail pro data
        await this.props.getMailProMailerData(mlsAreaID, agentData);
        this.props.history.push(`/mailpro/${area.mlsAreaID}`);
      } else {
        // if (version === "qv4" ) {

        let fullCityAndDay = `${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}`;

        await this.props.getMailerProDetails(
          mlsAreaID,
          cityAndDate,
          agentData,
          true,
          version,
          typeOfMQVersion,
          typeOfQVersion,
          listgenUserID,
          false,
          false,
          false,
          true
        );
        this.props.history.push(`/pma/${area.mlsAreaID}`);
        // }
      }
      //reset the new HO dialog settings also
      this.props.resetNewMailProInitDialogFn();
      /**set only if have valid area data */
      if (area) {
        SetNavbarDataInStorage(area);
      }
    }, 5500);
  }

  handleSwitchStep = async (step) => {
    this.props.switchStep(step);
    //reset the new Mailing dialog data if moving to step 0
    let isPmaEditor = window.location.href.includes("/pma");
    if (isPmaEditor || step === 0) {
      this.props.resetNewMailProInitDialogFn();
    }
  };

  render() {
    let {
      mlsAreas,
      currentArea,
      isLoading,
      navbarData,
      handleDrawerOpen,
      currentPeriod,
      propertyData,
      agentData,
      version,
      mailingVersion,
      showUnapprovedBox,
      unapprovedAreaCount,
      enableMailProApprovalProcess,
      isDataVerification,
      isUnavailable,
    } = this.props;
    /**get updated current area data */
    currentArea = propertyUtil.getCurrentArea(mlsAreas, currentArea);
    /**process data now */
    const { selectedAreaIndex, options, allAreas } = this.state;
    // let step = this.props.match.params.areaID;
    //console.log(this.props, this.state);
    if ((navbarData && currentArea) || isDataVerification) {
      return (
        <div id="pma-modal-overlay-root">
          <MappedNav
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            changeCurrentArea={this.changeCurrentArea}
            handleSwitchStep={this.handleSwitchStep}
            currentStep={this.props.currentStep}
            handleDrawerOpen={handleDrawerOpen}
            currentPeriod={currentPeriod}
            propertyData={propertyData}
            agentData={agentData}
            isLoading={isLoading}
            version={version}
            mailingVersion={mailingVersion}
            showUnapprovedBox={showUnapprovedBox}
            unapprovedAreaCount={unapprovedAreaCount}
            enableMailProApprovalProcess={enableMailProApprovalProcess}
            explicitEnableNewMailProInit={
              this.props.explicitEnableNewMailProInit
            }
            handleExplicitNewMailingScreen={
              this.props.explicitEnableNewMailProInitFn
            }
            resetNewMailProInitDialogFn={this.props.resetNewMailProInitDialogFn}
            isUnavailable={isUnavailable}
            isMailProLocked={this.props.isMailProLocked}
          />
        </div>
      );
    } else {
      return <div>Loading..</div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.pma.isLoading,
    //user: state.auth.user,
    currentVersion: state.pma.currentVersion,
    currentStep: state.pma.currentStep,
    currentPeriod: state.pma.currentPeriod,
    //currentArea: state.pma.currentArea,
    mlsAreas: state.pma.mlsAreas,
    pma: state.pma,
    pmaData: state.pma.pmaData,
    propertyData: state.pma.propertyData,
    version: state.pma.version,
    typeOfMQVersion: state.pma.typeOfMQVersion,
    typeOfQVersion: state.pma.typeOfQVersion,
    typeOfYEVersion: state.pma.typeOfYEVersion,
    mailingVersion: state.pma.mailingVersion,
    explicitEnableNewMailProInit: state.pma.explicitEnableNewMailProInit,
    //for new mailing data
    //newMailingEditedData: state.pma.newMailingEditedData,
    //newMailingMailProData: state.pma.newMailingMailProData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchVersion: (version) => dispatch(pmaActions.switchVersion(version)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    switchArea: (area) => dispatch(pmaActions.switchArea(area)),
    getCityAreaMap: (countyID, index) =>
      dispatch(pmaActions.getCityAreaMap(countyID, index)),
    getNavbarInfo: (listgenUserID) =>
      dispatch(pmaActions.getNavbarInfo(listgenUserID)),
    getMailerProDetails: (
      areaId,
      cityAndDate,
      agentData,
      forEditor,
      version,
      typeOfMQVersion,
      typeOfQVersion,
      listgenUserID,
      typeOfYEVersion,
      isDataVerification,
      specialHO,

      switchingStep
    ) => {
      dispatch(
        pmaActions.getMailerProDetails(
          areaId,
          cityAndDate,
          agentData,
          forEditor,
          version,
          typeOfMQVersion,
          typeOfQVersion,
          listgenUserID,

          typeOfYEVersion,
          isDataVerification,
          specialHO,

          switchingStep
        )
      );
    },
    getMailProMailerData: (areaId, agentData) =>
      dispatch(pmaActions.getMailProMailerData(areaId, agentData)),
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
    getMarketActivity: (areaAndDate) => {
      dispatch(pmaActions.getMarketActivity(areaAndDate));
    },
    getPMACoverPhotoDetails: (cityID) => {
      dispatch(pmaActions.getPMACoverPhotoDetails(cityID));
    },
    qAreaAndCityDetails: (mlsAreaID, cityAndDate, subdivision) => {
      dispatch(
        pmaActions.qAreaAndCityDetails(mlsAreaID, cityAndDate, subdivision)
      );
    },
    getMQCityDetails: (cityAndDate) => {
      dispatch(pmaActions.getMQCityDetails(cityAndDate));
    },
    getMQ2CityDetails: (cityAndDate, subdivision, listgenUserID) => {
      dispatch(
        pmaActions.getMQ2CityDetails(cityAndDate, subdivision, listgenUserID)
      );
    },
    updateNavbar: (mlsAreas) => dispatch(pmaActions.updateNavbar(mlsAreas)),
    getQuarterAreaTable: (areaAndDate) => {
      dispatch(pmaActions.getQuarterAreaTable(areaAndDate));
    },
    getQuarterCityTable: (cityAndDate) => {
      dispatch(pmaActions.getQuarterCityTable(cityAndDate));
    },
    getSalePriceForCities: (date) => {
      dispatch(pmaActions.getSalePriceForCities(date));
    },
    getYearEndDetails: (date, listgenUserID) => {
      dispatch(pmaActions.getYearEndDetails(date, listgenUserID));
    },

    //new mailing
    resetNewMailProInitDialogFn: () =>
      dispatch(pmaActions.resetNewMailProInitDialogFn()),
  };
}
const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);
export default enhance(PMANavbar);
// export default compose(withRouter, withStyles(styles))(MappedNav);
