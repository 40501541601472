import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";
import ListingRow from "./ListingRow";
import PhotoAddProperty from "views/Pma/PmaEditor/CommonEditor/PhotoAddProperty";
import PhotoEditProperty from "views/Pma/PmaEditor/CommonEditor/PhotoEditProperty";
import { ImageUploadDialog } from "components";
import Base from "common/constants";
import { IsValidSessionSwitch } from "common/storage";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styles2, { getPHListStyle } from "views/PmaAdmin/styles";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/Quarterly/QVFour/qv4styles.scss";
import { withRouter } from "react-router-dom";
import { showErrorNotification, showInfoNotification } from "common/helpers";

class PhotoModal extends Component {
  state = {
    oldPropData: {
      address: "",
      bedBaths: "",
      brokerageName: "",
      cityName: "",
      daysOnMarket: "",
      displayOrder: "",
      isBuyingAgent: false,
      isSelected: false,
      isSellingAgent: true,
      listDate: "",
      listPrice: "",
      listingAgentLicenseID: "",
      listingAgentName: "",
      lotSizeSquareFt: "",
      photoURL: "",
      saleDate: "",
      salePrice: "",
      sellingAgentLicenseID: "",
      sellingAgentName: "",
      squareFeet: "",
      status: "",
      streetDirPrefix: "-",
      streetName: "",
      streetNumber: "",
      streetSuffix: "",
      unitNumber: "",
      view: "",
      justAdded: false,
    },

    showEditPropDialog: false,

    uploadCurrentImage: "",
    editDialog: false,
    errorModalText: "",
    showErrorModal: false,
    editPropFormData: {
      address: "",
      bedBaths: "",
      brokerageName: "",
      cityName: "",
      daysOnMarket: "",
      displayOrder: "",
      isBuyingAgent: false,
      isSelected: false,
      isSellingAgent: true,
      listDate: "",
      listPrice: "",
      listingAgentLicenseID: "",
      listingAgentName: "",
      lotSizeSquareFt: "",
      photoURL: "",
      saleDate: "",
      salePrice: "",
      sellingAgentLicenseID: "",
      sellingAgentName: "",
      squareFeet: "",
      status: "",
      streetDirPrefix: "-",
      streetName: "",
      streetNumber: "",
      streetSuffix: "",
      unitNumber: "",
      view: "",
      justAdded: false,
    },
  };
  handleSelect = (property, select) => {
    console.log("inside handle select", property, select);
    // let selected = photoDetails.filter(
    //   (property) => property.isSelected === true
    // );
    // let uniqueSelect = new Set(selected);
    // if (select === true && selected.length >= 5) {
    //   setErrorModalText("Max properties");
    //   setShowErrorModal(true);
    // } else {
    //   let propData = photoDetails;
    //   let selected = photoDetails.filter((prop) => prop.isSelected === true);
    //   let newDisplayOrder;
    //   if (select && !selected) {
    //     newDisplayOrder = 0;
    //   } else if (select && selected.length > 0) {
    //     newDisplayOrder = selected.length;
    //   } else {
    //     newDisplayOrder = 0;
    //   }
    //   const updatedPropList = propData.map((prop) =>
    //     prop.streetName === property.streetName &&
    //     prop.streetNumber === property.streetNumber &&
    //     prop.listDate === property.listDate
    //       ? {
    //           ...prop,
    //           isSelected: select ? true : false,
    //           displayOrder: newDisplayOrder,
    //         }
    //       : prop
    //   );
    //   let updateSelected = updatedPropList.filter(
    //     (property) => property.isSelected === true
    //   );
    //   if (!select) {
    //     let changeSelectOrders = updateSelected.map(
    //       (prop, index) => (prop.displayOrder = index)
    //     );
    //   }
    //   const {
    //     areaID,
    //     countyID,
    //     listgenUserAreaID,
    //     listgenUserID,
    //     photoDetailsRunDate,
    //   } = propHighlightData;
    //   let constructedHighlights = {
    //     areaID,
    //     countyID,
    //     listgenUserAreaID,
    //     listgenUserID,
    //     photoDetails: updatedPropList,
    //     photoDetailsRunDate,
    //   };
    //   setPropHighlightData({
    //     ...propHighlightData,
    //     photoDetails: updatedPropList,
    //   });
    // }
  };
  onEditFormSubmit = () => {
    console.log("inside submit");
    // const { photoDetails } = propHighlightData;
    // const {
    //   streetNumber,
    //   streetName,
    //   cityName,
    //   salePrice,
    //   brokerageName,
    //   photoURL,
    // } = editPropFormData;
    // if (
    //   !streetNumber ||
    //   !streetName ||
    //   !cityName ||
    //   !salePrice ||
    //   !brokerageName ||
    //   !photoURL
    // ) {
    //   setErrorModalText("Missing inputs");
    //   setShowErrorModal(true);
    // } else {
    //   const changeProperty = photoDetails.map((prop) =>
    //     prop.streetName === editPropFormData.streetName &&
    //     prop.streetNumber === editPropFormData.streetNumber &&
    //     prop.listDate === editPropFormData.listDate &&
    //     prop.unitNumber === editPropFormData.unitNumber
    //       ? editPropFormData
    //       : prop
    //   );
    //
    //   let change3 = photoDetails.map((prop) => {
    //     if (
    //       //compare fields that cannot be edited by the agent
    //       prop.view === editPropFormData.view &&
    //       prop.bedBaths === editPropFormData.bedBaths &&
    //       prop.listingAgentLicenseID === editPropFormData.listingAgentLicenseID
    //     ) {
    //       return editPropFormData;
    //     } else {
    //       return prop;
    //     }
    //   });
    //
    //   setPropHighlightData({
    //     ...propHighlightData,
    //     photoDetails: change3,
    //   });
    //   setIsSubmitting(true);
    //   handleFormClose();
    // }
  };

  handleDragEnd = (result) => {
    console.log("inside handleDrag end", result);
    const {
      areaID,
      countyID,
      listgenUserAreaID,
      listgenUserID,
      photoDetailsRunDate,
      photoDetails,
    } = this.props;
    // dropped outside the list
    let selected = photoDetails.filter((prop) => prop.isSelected === true);
    let sortSelected = selected.sort((a, b) => a.displayOrder - b.displayOrder);
    let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));
    let unselected = photoDetails.filter((prop) => prop.isSelected === false);
    if (!result.destination) {
      return;
    }
    let dataToSort = Array.from(selected);
    let startIndex = result.source.index;
    let endIndex = result.destination.index;
    let [startData] = selected.splice(startIndex, 1);
    selected.splice(endIndex, 0, startData);
    let changeOrders = selected.map(
      (prop, index) => (prop.displayOrder = index)
    );
    let newList = [...selected, ...unselected];
    let constructedHighlights = {
      areaID,
      countyID,
      listgenUserAreaID,
      listgenUserID,
      photoDetails: newList,
      photoDetailsRunDate,
    };
    // setPropHighlightData({
    //   ...propHighlightData,
    //   photoDetails: newList,
    // });
    showInfoNotification(
      this.props,
      `Please SAVE YOUR CHANGES!  Click on the blue LOOKS GOOD – SUBMIT button at the bottom of the screen.`,
      20000,
      "top",
      "center"
    );
  };

  listMapFunc = (list, i) => {
    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      shortenStatus,
      shortenPropType,
    } = propertyUtil;
    const { shortenYear } = propertyUtil;
    const {
      solds,
      mlsAreaID,
      older,
      isSingleTable,
      handlePropCheck,
      agentData,
    } = this.props;
    let {
      listgenUserFirstName,
      agentLicenseNumber,
      isMemberOfTeam,
      teamMembers,
      listgenUserID,
      teamName,
    } = agentData;

    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    let hasUnitNum =
      !isCountryClubGate &&
      (list.propertyType === "Townhouse" ||
        list.propertyType === "Condominium") &&
      list.unitNumber !== "-";

    let addressName = "";
    if (
      list.streetNumber !== "-" &&
      list.streetNumber != "0" &&
      list.streetNumber !== "."
    ) {
      addressName += list.streetNumber;
    }
    addressName += ` ${list.streetName}`;
    if (list.streetSuffix && list.streetSuffix !== "-") {
      addressName += ` ${list.streetSuffix}`;
    }
    if (hasUnitNum) {
      addressName += `, #${list.unitNumber}`;
    }
    let addressSize = addressName.length > 25 ? "smaller-row-address" : "";
    //console.log("list", list);
    const teamMember1License =
      isMemberOfTeam && teamMembers[0].agentLicenseNumber;
    const teamMember2License =
      isMemberOfTeam && teamMembers[1].agentLicenseNumber;
    let teamMember1Name =
      isMemberOfTeam && teamMembers[0].firstName.toUpperCase();

    let isListingAgent = false;

    if (
      (!isMemberOfTeam && agentLicenseNumber === list.listingAgentLicenseID) ||
      (isMemberOfTeam &&
        (teamMember1License === list.listingAgentLicenseID ||
          teamMember2License === list.listingAgentLicenseID)) ||
      (list.isCoListingAgent && list.isCoListingAgent === "true") ||
      list.coListingAgents
    ) {
      isListingAgent = true;
    }
    let isSellingAgent = false;
    if (
      (!isMemberOfTeam && agentLicenseNumber === list.sellingAgentLicenseID) ||
      (isMemberOfTeam &&
        (teamMember1License === list.sellingAgentLicenseID ||
          teamMember2License === list.sellingAgentLicenseID)) ||
      (list.isCoSellingAgent && list.isCoSellingAgent === "true")
    ) {
      isSellingAgent = true;
    }

    const hasBanner = isSellingAgent || isListingAgent;

    return (
      <tr
        key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
        className={`${list.status.toLowerCase()} ${
          older ? "presolds-row" : "recent-row"
        } ${isSingleTable ? "single-table-row" : ""} ${
          list.isSystemSelected && "prop-row-checked"
        }`}
      >
        <td>
          <input
            type="checkbox"
            id={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
            // name="vehicle1"
            // value="Bike"
            className="photo-row-checkbox"
            onClick={(e) => handlePropCheck(e, list, hasBanner, false)}
            checked={list.isSystemSelected || false}
          />
        </td>
        <td
          // className={`status-text`}
          className={`status-text ${list.status.toLowerCase()}`}
          align="left"
        >
          {list.isOffMarketSale == "1" && <strong>*</strong>}
          <p>{shortenStatus(list.status)}</p>
        </td>

        {list.saleDate === "-" ? (
          <td></td>
        ) : (
          <td className="market-table-sale-date-row">
            {this.getMonthAndYearString(list.saleDate)}
          </td>
        )}

        <td className="prop-type-text">{propTypeAcronym(list.propertyType)}</td>
        <td>
          <p className={addressSize}>
            {addressName}
            {/*{list.streetNumber === "-" || list.streetNumber === "0"
                ? null
                : list.streetNumber}{" "}
              {list.streetName}{" "}
              {list.streetSuffix && list.streetSuffix !== "-"
                ? list.streetSuffix
                : null}
              {hasUnitNum ? `, #${list.unitNumber}` : ""}*/}
          </p>
        </td>
        <td>
          {list.listPrice === "-" ? (
            <div className="off-centered-dash">
              <p>—</p>
            </div>
          ) : (
            <div>
              <p>$</p>
              <p>{Number(list.listPrice).toLocaleString()}</p>
            </div>
          )}
        </td>

        <td>
          {list.salePrice === "-" ? (
            <div className="off-centered-dash">
              <p>—</p>
            </div>
          ) : (
            <div>
              <p>$</p>
              <p>{Number(list.salePrice).toLocaleString()}</p>
            </div>
          )}
        </td>
        {list.isOffMarketSale == "1" &&
        (list.daysOnMarket === "0" || list.daysOnMarket === "-") ? (
          <td>
            <div className="off-centered-dash">
              <p>—</p>
            </div>
          </td>
        ) : (
          <td>{list.daysOnMarket}</td>
        )}

        <td>{formatBaths(list.bedBaths)}</td>
        <td>
          {list.squareFeet === "-" || list.squareFeet === "0" ? (
            <p>N/A</p>
          ) : (
            <p>{Math.round(list.squareFeet).toLocaleString()}</p>
          )}
        </td>
        <td>
          <td>
            {list.lotSizeSquareFt === "-" || list.lotSizeSquareFt === "0" ? (
              <p>N/A</p>
            ) : (
              <p>{Math.round(list.lotSizeSquareFt).toLocaleString()}</p>
            )}
          </td>
        </td>
        <td>{hasBanner && <div className="my-listing-dot" />}</td>
      </tr>
    );
  };

  getMonthAndYearString = (inputTime) => {
    try {
      let month = moment(inputTime).format("MMM");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");

      return (
        <div className="date-td">
          <div>{month}</div>
          <div>{day},</div>
          <div>{year}</div>
        </div>
      );
      // return moment(inputTime).format("MMM D, YYYY");
    } catch (err) {
      return "";
    }
  };

  getTodayDate = () => {
    return moment(new Date()).format("MMM D, YYYY");
  };
  calculatePricePerSqft(calculation, saleP, sqft) {
    if (calculation === "-") {
      let res = Math.round(saleP / sqft);
      return res.toLocaleString();
    } else {
      return Math.round(calculation).toLocaleString();
    }
  }

  handlePropertyHighlightUpload = async (uploadedImageUrl) => {
    if (uploadedImageUrl && uploadedImageUrl.optimizedPhotoURL) {
      this.setState((prevState) => ({
        editPropFormData: {
          ...prevState.editPropFormData,
          photoURL: uploadedImageUrl.optimizedPhotoURL,
          optimizedPhotoURL: uploadedImageUrl.optimizedPhotoURL,
          justAdded: true,
        },
      }));
    }
    this.setState({
      openUploadImageDialog: false,
      uploadCurrentImage: null,
      uploadImageKey: null,
      showError: "",
    });
  };

  render() {
    const {
      place,

      updateChanges,
      closePhotoModal,
      handleRepresentedOptions,
      openUploadMedia,
      handleFormClose,
      photoDetails,
      dateSort,
      beginClosePhotoModal,
      showEditPropDialog,
      editPropFormData,
      solds,
      mlsAreaID,
      older,
      isSingleTable,
      handlePropCheck,
      agentData,
      handleOpenEditDialog,
      oldPropData,
      handleInputChange,
      showAddPropDialog,
      handleAddPropDialog,
      openUploadImageDialog,
      currentArea,
    } = this.props;
    const { mlsNeighborhood } = currentArea;
    console.log("modal props", this.props);
    const {
      listMapFunc,
      handleDragEnd,
      handlePropertyHighlightUpload,
      handleSelect,
    } = this;
    const { uploadCurrentImage } = this.state;
    const isAValidSessionSwitch = IsValidSessionSwitch();

    const isAdminAccess =
      isAValidSessionSwitch ||
      agentData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

    const {
      getPropView,
      formatSuffix,
      formatBaths,
      toAcre,
      cutName,
      propTypeAcronym,
      shortenStatus,
      shortenPropType,
    } = propertyUtil;
    const { shortenYear } = propertyUtil;

    let {
      listgenUserFirstName,
      agentLicenseNumber,
      isMemberOfTeam,
      teamMembers,
      listgenUserID,
      teamName,
    } = agentData;
    const {
      handleAddProperty,

      onEditFormSubmit,
    } = this;
    // console.log("****render", this.state);
    let isCountryClubGate = mlsAreaID === "132" ? true : false;

    const tableHeader = (
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>Status</th>
          <th>
            <p>Sale </p>
            <p>Date</p>
          </th>

          <th>Address</th>
          <th>List Price</th>
          <th>Sale Price</th>
        </tr>
      </thead>
    );
    if (photoDetails && photoDetails.length >= 1) {
      console.log("inside prop", photoDetails);
      let soldProperties = photoDetails.filter(
        (prop) => prop.status === "Sold" && prop.isSystemSelected == false
      );
      console.log("soldProperties", soldProperties);
      let pendingProperties = photoDetails.filter(
        (prop) =>
          (prop.status === "Pending" || prop.status === "Contingent") &&
          prop.isSystemSelected == false
      );
      console.log("pendingProperties", pendingProperties);
      let activeProperties = photoDetails.filter(
        (prop) => prop.status === "Active" && prop.isSystemSelected == false
      );
      let selectedProperties = photoDetails.filter(
        (prop) => prop.isSystemSelected == true
      );
      // console.log("****modal state", this.state);
      return (
        <div className="photo-modal">
          {openUploadImageDialog ? (
            <ImageUploadDialog
              openDialog={true}
              onSubmit={handlePropertyHighlightUpload}
              onClose={handlePropertyHighlightUpload}
              isMyAccountUpload={isAValidSessionSwitch}
              imageType={"propertyHighlight"}
              listgenUserID={agentData.listgenUserID} //not required  because designation Logo doesnt required any image upload
              currentImage={uploadCurrentImage}
              teamMode={false}
            />
          ) : (
            ""
          )}
          {showAddPropDialog ? (
            <PhotoAddProperty
              onCloseFn={handleFormClose}
              openUploadMedia={openUploadMedia}
              handleInputChange={handleInputChange}
              editPropFormData={editPropFormData}
              handleRepresentedOptionsFunc={handleRepresentedOptions}
              listgenUserID={agentData.listgenUserID}
              handleAddProperty={handleAddProperty}
              userData={agentData}
              fromPhotoTable={true}
              agentData={agentData}
            />
          ) : (
            ""
          )}
          {showEditPropDialog ? (
            <PhotoEditProperty
              onCloseFn={handleFormClose}
              openUploadMedia={openUploadMedia}
              handleInputChange={handleInputChange}
              editPropFormData={editPropFormData}
              handleRepresentedOptionsFunc={handleRepresentedOptions}
              listgenUserID={agentData.listgenUserID}
              onEditFormSubmit={onEditFormSubmit}
              userData={agentData}
              oldPropData={oldPropData}
              areaID={mlsAreaID}
              fromPhotoTable={true}
              agentData={agentData}
            />
          ) : (
            ""
          )}
          <div className="photo-modal-body">
            <div className="photo-modal-top-title-row">
              <div className="photo-modal-title">
                <div className="photo-modal-title-text">PMA</div>
                <div className="photo-modal-title-divider" />
                <div className="photo-modal-title-text">
                  Edit Property Photos
                </div>
              </div>
              <div
                className="close-photo-modal-button"
                onClick={() => beginClosePhotoModal()}
              >
                <p>Close</p>
              </div>
            </div>
            <div className="photo-modal-top-divider"></div>
            <div className="photo-modal-bottom-title-row">
              <div className="photo-modal-bottom-title-area">
                {mlsNeighborhood}
              </div>
              <div className="photo-modal-bottom-title-date">
                As of XXX - March 13, 2023
              </div>
            </div>

            <div
              className="update-changes-button"
              onClick={() => updateChanges()}
            >
              <p>Update + Save Changes</p>
            </div>
            <div className="photo-modal-information-banner">
              <div className="photo-modal-information-banner-text">
                <p>
                  Click the checkbox of any 6 properties that you would like to
                  display on Page 3:
                </p>
              </div>
            </div>
            {/*<div
              className="photo-add-property-row"
              onClick={() => handleAddPropDialog()}
            >
              <div className="add-property-button">
                <div>+</div>
              </div>
              <div className="add-property-text">
                Add a <span className="sold-span">Sold</span> Property
              </div>
            </div>*/}
            {/*<div className="update-changes-button-container">
              <div style={{ maxWidth: "439px" }}>
                <DrawerModeHelpScreen
                  accessedFromAreaID={(currentArea || {}).mlsAreaID}
                  text="Don’t see a property you’re looking for? Click here to send a support ticket"
                />
              </div>
             <div
                className="support-photo-modal-button"
                // onClick={() => updateChanges()}
              >
                <p>Don’t see a property you’re looking for? </p>
                <p>Click here to send a support ticket</p>
              </div>
            </div>*/}

            {/*<div className="photo-modal-recent-activity-text">
              Recent Activity as of XXX - {this.getTodayDate()}
            </div>
            <div className="photo-modal-legend">
              <div className="photo-modal-legend-row">
                <div className="photo-modal-my-listing-dot"></div>Indicates My
                Listing
              </div>
            </div>*/}
            <table className="photo-sales-table">
              <tbody>
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  className="drag-drop-context"
                >
                  <Droppable
                    droppableId="lg-property-highlight"
                    className="droppable-prop"
                  >
                    {(provided, snapshot) => (
                      <div
                        className="draggable-div"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getPHListStyle(snapshot.isDraggingOver)}
                      >
                        <Draggable
                          className="draggable-container"
                          draggableId={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                          index={photoDetails[0].displayOrder}
                          key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="highlight-row-container"
                              key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListingRow
                                properties={selectedProperties}
                                oldPropData={oldPropData}
                                photoDetails={photoDetails.filter(
                                  (listing) => listing.isSystemSelected
                                )}
                                solds={solds}
                                mlsAreaID={mlsAreaID}
                                older={older}
                                isSingleTable={isSingleTable}
                                handlePropCheck={handlePropCheck}
                                agentData={agentData}
                                handleOpenEditDialog={handleOpenEditDialog}
                                fromPhotoTable={true}
                                isTopSix={true}
                                prop={photoDetails[0]}
                                index={photoDetails[0].displayOrder}
                                areaID={mlsAreaID}
                                handleSelect={handleSelect}
                              />
                            </div>
                          )}
                        </Draggable>

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                {photoDetails && photoDetails.length > 1 && (
                  <DragDropContext
                    onDragEnd={handleDragEnd}
                    className="drag-drop-context"
                  >
                    <Droppable
                      droppableId="lg-property-highlight"
                      className="droppable-prop"
                    >
                      {(provided, snapshot) => (
                        <div
                          className="draggable-div"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getPHListStyle(snapshot.isDraggingOver)}
                        >
                          {photoDetails
                            .filter((prop) => prop.isSystemSelected)
                            .map((prop, index) => {
                              return (
                                <Draggable
                                  className="draggable-container"
                                  // key={prop.displayOrder}
                                  draggableId={`${prop.streetNumber}-${prop.streetName}-${index}-${prop.unitNumber}`}
                                  // draggableId={`lg-ag-${prop.displayOrder}`}
                                  // draggableId={index.toString()}
                                  index={index}
                                  key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="highlight-row-container"
                                      key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <ListingRow
                                        properties={selectedProperties}
                                        oldPropData={oldPropData}
                                        photoDetails={photoDetails.filter(
                                          (listing) => listing.isSystemSelected
                                        )}
                                        solds={solds}
                                        mlsAreaID={mlsAreaID}
                                        older={older}
                                        isSingleTable={isSingleTable}
                                        handlePropCheck={handlePropCheck}
                                        agentData={agentData}
                                        handleOpenEditDialog={
                                          handleOpenEditDialog
                                        }
                                        fromPhotoTable={true}
                                        isTopSix={true}
                                        prop={photoDetails[0]}
                                        index={photoDetails[0].displayOrder}
                                        areaID={mlsAreaID}
                                        handleSelect={handleSelect}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}

                <ListingRow
                  properties={selectedProperties}
                  oldPropData={oldPropData}
                  photoDetails={photoDetails.filter(
                    (listing) => listing.isSystemSelected
                  )}
                  solds={solds}
                  mlsAreaID={mlsAreaID}
                  older={older}
                  isSingleTable={isSingleTable}
                  handlePropCheck={handlePropCheck}
                  agentData={agentData}
                  handleOpenEditDialog={handleOpenEditDialog}
                  fromPhotoTable={true}
                  isTopSix={true}
                />

                <ListingRow
                  properties={dateSort(soldProperties)}
                  solds={solds}
                  mlsAreaID={mlsAreaID}
                  older={older}
                  isSingleTable={isSingleTable}
                  handlePropCheck={handlePropCheck}
                  agentData={agentData}
                  handleOpenEditDialog={handleOpenEditDialog}
                  fromPhotoTable={true}
                  oldPropData={oldPropData}
                />

                <ListingRow
                  properties={dateSort(pendingProperties)}
                  solds={solds}
                  mlsAreaID={mlsAreaID}
                  older={older}
                  isSingleTable={isSingleTable}
                  handlePropCheck={handlePropCheck}
                  agentData={agentData}
                  handleOpenEditDialog={handleOpenEditDialog}
                  fromPhotoTable={true}
                  oldPropData={oldPropData}
                />

                <ListingRow
                  properties={dateSort(activeProperties)}
                  solds={solds}
                  mlsAreaID={mlsAreaID}
                  older={older}
                  isSingleTable={isSingleTable}
                  handlePropCheck={handlePropCheck}
                  agentData={agentData}
                  handleOpenEditDialog={handleOpenEditDialog}
                  fromPhotoTable={true}
                  oldPropData={oldPropData}
                />
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default compose(
  withSnackbar,
  withStyles(
    (theme) => ({
      ...styles,
      ...styles2(theme),
    }),
    {
      withTheme: true,
    },
    withRouter
  )
)(PhotoModal);
