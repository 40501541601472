import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";

import JohnsonsQuadrantsTwo from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsQuadrantsTwo";
import QuadrantContainer from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourQuadrantContainer";

class TableQuadrantsFirstLeft extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,

      testimonials,
      quadrantModalOpen,
      quadrantDetails,
    } = this.props;
    const { listgenUserID } = agentData;

    let isJohnsons = listgenUserID === "100988";

    return (
      <div className="table-quadrants-first-left">
        <div className={`${quadrantModalOpen && "first-left-dim"}`}>
          <FirstLeftHeader
            agentData={agentData}
            printVersion={printVersion}
            currentArea={currentArea}
          />
          <QVFourLogoAndAvatar
            agentData={agentData}
            currentArea={currentArea}
            printVersion={printVersion}
          />
          <HOAddress
            hoData={hoData}
            version={version}
            agentData={agentData}
            currentArea={currentArea}
            printVersion={printVersion}
            mailInfoClass="mail-info-container-print"
            coverPageDetails={coverPageDetails}
          />
        </div>
        {isJohnsons ? (
          <JohnsonsQuadrantsTwo />
        ) : (
          quadrantDetails &&
          quadrantDetails.length && (
            <>
              <QuadrantContainer
                currentArea={currentArea}
                quadrantDetails={quadrantDetails}
                mlsAreas={mlsAreas}
                printVersion={printVersion}
                testimonials={testimonials}
              />{" "}
              <QVFourTestimonial
                testimonials={testimonials}
                agentData={agentData}
                currentArea={currentArea}
              />
            </>
          )
        )}
      </div>
    );
  }
}

export default TableQuadrantsFirstLeft;

// <YETwoFirstLeft
//   propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
//   agentData={agentData}
//   printVersion={printVersion}
// />

// import React, { Component } from "react";
// import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
// import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
// import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
// import QuadrantContainer from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageOne/QuadrantContainer";
// import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";
//
// class TableQuadrantsFirstLeft extends Component {
//   render() {
//     const {
//       printVersion,
//       version,
//       agentData,
//       hoData,
//       currentArea,
//       mlsAreas,
//       coverPageDetails,
//       quarterlyAreaTable,
//       testimonials,
//       quadrantModalOpen,
//       quadrantDetails,
//       propertyPhotoDetailsDTO,
//     } = this.props;
//     const { listgenUserID } = agentData;
//
//     // console.log("first left", this.props);
//     let isJohnsons = listgenUserID === "100988";
//
//     return (
//       <div className="qv4-first-left">
//         <div className={`${quadrantModalOpen && "first-left-dim"}`}>
//           <FirstLeftHeader
//             agentData={agentData}
//             printVersion={printVersion}
//             currentArea={currentArea}
//           />
//           <QVFourLogoAndAvatar agentData={agentData}       printVersion={printVersion}/>
//           <HOAddress
//             hoData={hoData}
//             version={version}
//             agentData={agentData}
//             currentArea={currentArea}
//             printVersion={printVersion}
//             mailInfoClass="qv4-mail-info-container-print"
//             versionType="qv4"
//             typeOfVersion="qv4"
//             coverPageDetails={coverPageDetails}
//           />
//           {quadrantDetails && quadrantDetails.length && (
//             <>
//               <QuadrantContainer
//                 currentArea={currentArea}
//                 quadrantDetails={quadrantDetails}
//                 mlsAreas={mlsAreas}
//                 printVersion={printVersion}
//               />{" "}
//             </>
//           )}
//         </div>
//       </div>
//     );
//   }
// }
//
// export default TableQuadrantsFirstLeft;
