import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
import "./customStyles.scss";
import "./customPrintStyles.scss";

import SeniorRelocation from "views/Pma/PmaEditor/CustomAgentPages/SeniorRelocation";

import SOSQRCode from "views/Pma/PmaEditor/CustomAgentPages/sos_qr_code.png";
import PrintV2DebSamuel from "./PrintV2DebSamuel";

class V2DebSamuel extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,

      coverPageDetails,
    } = this.props;
    return (
      <div className={`${version}-first-left`}>
        <FirstLeftHeader
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />
        <QVFourLogoAndAvatar
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <HOAddress
              hoData={hoData}
              version={version}
              agentData={agentData}
              currentArea={currentArea}
              printVersion={printVersion}
              mailInfoClass="mail-info-container-print"
              typeOfVersion={version}
              coverPageDetails={coverPageDetails}
            />
          )}
        <div className="deb-bg" />
        {printVersion ? <PrintV2DebSamuel /> : <SeniorRelocation />}
        {/*<SeniorRelocation />*/}
        <div className="v2-deb-testimonial-qr">
          <div className="qr-container">
            <img src={SOSQRCode} alt="Sos qr code" />
            <div className="sbos-link">sbsos.care</div>
          </div>
          <div className="ds-testimonial">
            <div className="ds-testimonial-text-container">
              <div className="ds-testimonial-title">We Love Our Clients</div>
              <div className="ds-testimonial-divider" />
              <div className="ds-testimonial-text">
                <p>
                  “My wife and I have only owned two homes and we lived for 27
                  years in the home we sold through Deborah. She is
                  professional, competent and, more importantly,
                  client-centered. Deborah always showed she represented us, and
                  was focused on achieving our goals. Deborah and SBSOS made a
                  difficult process less stressful and the timeline to the
                  finish line less difficult.”
                </p>
              </div>
              <div className="ds-testimonial-homeowner">
                <p>Andrew & Jo Gifford</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default V2DebSamuel;
// import React, { Component } from "react";
// import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
// import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
// import QVFourLogoAndAvatar from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourLogoAndAvatar";
// import "./customStyles.scss";
// import "./customPrintStyles.scss";
// import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";
// import BeachCliff from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/SB_SOS_Backcover.jpg";
// import SeniorRelocation from "views/Pma/PmaEditor/CustomAgentPages/SeniorRelocation";
// import SOSLogo from "views/Pma/PmaEditor/CustomAgentPages/CustomImages/sb-sos-logo.png";
// import SOSQRCode from "views/Pma/PmaEditor/CustomAgentPages/sos_qr_code.png";
// import PrintV2DebSamuel from "./PrintV2DebSamuel";
//
// class V2DebSamuel extends Component {
//   render() {
//     const {
//       printVersion,
//       version,
//       agentData,
//       hoData,
//       currentArea,
//       demoAccountHOName,
//       coverPageDetails,
//     } = this.props;
//     return (
//       <div className={`${version}-first-left`}>
//         <FirstLeftHeader
//           agentData={agentData}
//           printVersion={printVersion}
//           currentArea={currentArea}
//         />
//         <QVFourLogoAndAvatar agentData={agentData} currentArea={currentArea}       printVersion={printVersion}/>
//         {hoData &&
//           hoData.changeDetails &&
//           Object.keys(hoData.changeDetails).length > 1 && (
//             <HOAddress
//               hoData={hoData}
//               version={version}
//               agentData={agentData}
//               currentArea={currentArea}
//               printVersion={printVersion}
//               mailInfoClass="mail-info-container-print"
//               typeOfVersion={version}
//               coverPageDetails={coverPageDetails}
//             />
//           )}
//         <div className="deb-bg"></div>
//         {printVersion ? <PrintV2DebSamuel /> : <SeniorRelocation />}
//         {/*<SeniorRelocation />*/}
//         <div className="v2-deb-testimonial-qr">
//           {!printVersion && (
//             <div className="qr-container">
//               <div>
//                 <img src={SOSQRCode} alt="Sos qr code" />
//               </div>
//               <div className="sbos-link">sbsos.care</div>
//             </div>
//           )}
//           <div className="ds-testimonial">
//             <div className="ds-testimonial-text-container">
//               <div className="ds-testimonial-title">We Love Our Clients</div>
//               <div className="ds-testimonial-divider" />
//               <div className="ds-testimonial-text">
//                 <p>
// “My wife and I have only owned two homes and we lived for 27
// years in the home we sold through Deborah. She is
// professional, competent and, more importantly,
// client-centered. Deborah always showed she represented us, and
// was focused on achieving our goals. Deborah and SBSOS made a
// difficult process less stressful and the timeline to the
// finish line less difficult.”
//                 </p>
//               </div>
//               <div className="ds-testimonial-homeowner">
//                 <p>Andrew & Jo Gifford</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
//
// export default V2DebSamuel;
