import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import MiniCompassLogo from "_img/brokerage-logos/compass-white-brokerage-logo.png";

class QVFourProducedByFooter extends Component {
  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  };
  render() {
    const {
      brokerageName,
      listgenUserFirstName,
      listgenUserLastName,
      isMemberOfTeam,
      teamMembers,
      teamName,
      listgenUserID,
      agentLicenseNumber,
    } = this.props.agentData;

    const { currentSoldListings, printVersion } = this.props;

    const { mlsAreaID, mlsCityID } = this.props.currentArea;

    const isBerkshire =
      (brokerageName.includes("Berkshire") &&
        brokerageName.includes("Hathaway")) ||
      (brokerageName.includes("berkshire") && listgenUserID === "101");

    const { formatDaySuffix, getPhotoCredit } = propertyUtil;
    let date = new Date();

    const today = `${date.getDate()}`;
    const month = date.toLocaleString("default", { month: "long" });
    const thisYear = date.getFullYear();

    const isScar = teamName === "Team Scarborough";
    const isAmberRussell = listgenUserID === "100040";

    const { blackBorder, filterBrokerage } = propertyUtil;
    let hasBlackBorder = false;
    if (listgenUserID === "104412") {
      hasBlackBorder = true;
    }
    const {
      formatSuffix,

      emptyValueCheck,
      formatAddress,
    } = propertyUtil;
    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    let useCompassLegal = false;
    if (mlsAreaID.includes("SCL") || mlsAreaID.includes("SM")) {
      useCompassLegal = true;
    }
    let isKyleTeam3 = listgenUserID === "103406";
    let isJohnsons = listgenUserID === "100988";
    let ampersand = "&";
    if (isJohnsons) {
      ampersand = "and";
    }
    let isSCCompass = brokerageName === "Compass" && useCompassLegal;
    //add comment
    if (listgenUserID === "x103455") {
      return (
        <div
          className={
            printVersion
              ? "print-alt-qv4-produced-by-footer-with-names"
              : "editor-alt-qv4-produced-by-footer-with-names"
          }
        >
          <div className="row-title">
            THE PROPERTY PHOTOS SHOWN ABOVE ARE COURTESY OF THE FOLLOWING REAL
            ESTATE AGENTS:
          </div>
          <div className="names-row">
            <div className="names-col">
              {currentSoldListings.slice(0, 3).map((list) => (
                <div className="place" key={formatAddress(list, mlsAreaID)}>
                  {formatAddress(list, mlsAreaID)} | {list.listingAgentName} |
                  MLS# {list.listingAgentLicenseID}
                </div>
              ))}
            </div>

            <div className="names-col">
              {currentSoldListings.slice(3, 6).map((list) => (
                <div className="place" key={formatAddress(list, mlsAreaID)}>
                  {formatAddress(list, mlsAreaID)} | {list.listingAgentName} |
                  MLS# {list.listingAgentLicenseID}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (listgenUserID === "100190") {
      return (
        <div
          className={`${
            printVersion
              ? "print-qv4-produced-by-footer"
              : "editor-qv4-produced-by-footer"
          } ${hasBlackBorder && "black-produced-by"}
          `}
        >
          <p>
            Compiled and Presented by Team Zech Properties / Coldwell Banker
            Realty
          </p>
        </div>
      );
    } else if (isSCCompass) {
      return (
        <div
          className={`${
            printVersion
              ? "print-qv4-produced-by-footer"
              : "editor-qv4-produced-by-footer"
          } ${hasBlackBorder && "black-produced-by"} berk-footer
          `}
        >
          <div>
            Compass is a real estate broker licensed by the State of California
            and abides by Equal Housing Opportunity laws. License Number
            01991628, 01527235, 01527365. All material presented herein is
            intended for informational purposes only and is compiled from
            sources deemed reliable but have not been verified. Changes in
            price, condition, sale or withdrawal may be made without notice. No
            statement is made as to the accuracy of any description. All
            measurements and square footage are approximate.
          </div>
        </div>
      );
    } else if (isBerkshire) {
      return (
        <div
          className={`${
            printVersion
              ? "print-qv4-produced-by-footer"
              : "editor-qv4-produced-by-footer"
          } ${hasBlackBorder && "black-produced-by"} berk-footer
          `}
        >
          <div>
            ©{thisYear} Berkshire Hathaway HomeServices California Properties
            (BHHSCP) is a member of the franchise system of BHH Affiliates LLC.
            BHHS and the BHHS symbol are registered service marks of Columbia
            Insurance Company, a Berkshire Hathaway affiliate. BHH Affiliates
            LLC and BHHSCP do not guarantee accuracy of all data including
            measurements, conditions, and features of property. Information is
            obtained from various sources and will not be verified by broker or
            MLS. Buyer is advised to independently verify the accuracy of that
            information. Based on information obtained from the MLS as of{" "}
            {month} {today}
            {formatDaySuffix(today)}, {thisYear}. Display of MLS data is deemed
            reliable but is not guaranteed accurate by the MLS.
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {isMemberOfTeam ? (
            <div
              className={`${
                printVersion
                  ? "print-qv4-produced-by-footer"
                  : "editor-qv4-produced-by-footer"
              } ${hasBlackBorder && "black-produced-by"}
              `}
            >
              {isAmberRussell ? (
                <p>
                  Compiled and Presented by {listgenUserFirstName}{" "}
                  {listgenUserLastName} & {teamMembers[1].firstName}{" "}
                  {teamMembers[1].lastName}
                </p>
              ) : isKyleTeam3 ? (
                <p>Compiled by MORRISON, ALTMAN & FROMM</p>
              ) : isScar ? (
                <p>Compiled and Presented by {teamName}</p>
              ) : isJohnsons ? (
                <p>Compiled and Presented by Dianne and Brianna Johnson</p>
              ) : (
                <p>
                  Compiled and Presented by {listgenUserFirstName}{" "}
                  {teamMembers[1].firstName}
                </p>
              )}
              <div className="produced-by-divider">|</div>
              {brokerageName.toLowerCase() === "compass" ? (
                <div>
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/Horizontal_White_Compass%20Logo_500px.png"
                    className="presented-by-compass-logo"
                    alt="Compass logo"
                  />
                </div>
              ) : (
                <p>{filterBrokerage(brokerageName)}</p>
              )}
            </div>
          ) : (
            <div
              className={`${
                printVersion
                  ? "print-qv4-produced-by-footer"
                  : "editor-qv4-produced-by-footer"
              } ${hasBlackBorder && "black-produced-by"}
              `}
            >
              <p>
                Compiled and Presented by {listgenUserFirstName}{" "}
                {listgenUserLastName}
              </p>
              <div className="produced-by-divider">|</div>
              {brokerageName.toLowerCase() === "compass" ? (
                <div>
                  <img
                    src="https://pma-coverphotos.s3.amazonaws.com/Horizontal_White_Compass%20Logo_500px.png"
                    className="presented-by-compass-logo"
                    alt="Compass logo"
                  />
                </div>
              ) : (
                <p>{filterBrokerage(brokerageName)}</p>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

export default QVFourProducedByFooter;
