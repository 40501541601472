import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import White40Percent from "./valueChangedTransparentBox.png";

class DashboardBodyRange extends Component {
  getHomeOrCondo = (num) => {
    const { mlsAreaID } = this.props.currentArea;
    let homeOrCondo = "home";
    if (mlsAreaID === "SBA-20FCondo") {
      if (num == "1") {
        homeOrCondo = "condo has";
      } else {
        homeOrCondo = "condos have";
      }
    } else {
      if (num == "1") {
        homeOrCondo = "home has";
      } else {
        homeOrCondo = "homes have";
      }
    }
    return homeOrCondo;
  };
  homeOrProperties = (homesSold) => {
    const { listgenUserID } = this.props.agentData;
    const { mlsAreaID } = this.props.currentArea;

    if (mlsAreaID === "SBA-20FCondo") {
      if (homesSold === "1") {
        return "Condo";
      } else {
        return "Condos";
      }
    } else if (
      listgenUserID === "101006" ||
      listgenUserID === "100560" ||
      mlsAreaID === "SBA-20FInv"
    ) {
      if (homesSold === "1") {
        return "Property";
      } else {
        return "Properties";
      }
    } else {
      if (homesSold === "1") {
        return "Home";
      } else {
        return "Homes";
      }
    }
  };
  render() {
    let {
      rangeNum,
      baths,
      halfBaths,
      beds,
      dom,
      garageSpaces,
      golfView,
      sqft,
      lotSize,
      homesSold,
      oceanView,
      priceRangeLow,
      priceRangeHigh,
      agentData,
      currentArea,
    } = this.props;
    const { mlsAreaID } = currentArea;

    const { listgenUserID } = agentData;
    const { homeOrProperties, getHomeOrCondo } = this;
    const { roundSalePriceTenths, acreOrSqft, toAcre } = propertyUtil;
    let acreConvert = toAcre(lotSize, true);

    let useAcreOrSqft = acreOrSqft(lotSize, true);

    let roundedAcre = toAcre(Math.round(lotSize), true);

    let specialVaitGarage = mlsAreaID === "SBA-20FRes" && rangeNum === 3;

    return (
      <div className="dashboard-body-range">
        <div className="price-range-col">
          <div className="price-range-inner-container">
            <div className="price-range-text">
              {roundSalePriceTenths(priceRangeLow)}
              {priceRangeHigh == "100000000"
                ? " & up"
                : ` - ${roundSalePriceTenths(priceRangeHigh)}`}
            </div>

            <div className="price-range-number">
              Average Price Range #{rangeNum}
            </div>
          </div>
        </div>
        <img
          className="range-details-img"
          src={White40Percent}
          alt="Transparent dashboard container"
        />
        <div
          className={`range-details-col-with-image range-detail-${rangeNum}`}
        >
          <div className="range-details-inner-container">
            <div className="range-property-details">
              <p>
                {homesSold} {homeOrProperties(homesSold)} Sold
              </p>
              <p>
                {dom} {dom === "1" ? "Day" : "Days"} to Sell
              </p>
            </div>
            {mlsAreaID === "SBA-45H" && rangeNum === 3 ? (
              <div className="property-attributes">
                <p className="property-attributes-title">
                  Common Property Attributes
                </p>

                {lotSize && +lotSize >= 1 && mlsAreaID !== "SBA-20FCondo" && (
                  <p className="property-attributes-text">
                    Lot Size: {acreConvert} {useAcreOrSqft}
                  </p>
                )}
              </div>
            ) : (
              <div className="property-attributes">
                <p className="property-attributes-title">
                  Common Property Attributes
                </p>
                <p className="property-attributes-text">
                  {beds} {beds === "1" ? "bedroom" : "bedrooms"}
                </p>
                <p className="property-attributes-text">
                  {baths} full {baths === "1" ? "bath" : "baths"}{" "}
                  {+halfBaths > 0
                    ? `  +   ${halfBaths}
                half ${halfBaths === "1" ? "bath" : "baths"}
              `
                    : ""}
                </p>
                <p className="property-attributes-text">
                  {Number(sqft).toLocaleString()}
                  {(listgenUserID === "102340" || listgenUserID === "102356") &&
                    "+"}{" "}
                  Living Square Feet
                </p>
                {garageSpaces &&
                  +garageSpaces > 0 &&
                  !specialVaitGarage &&
                  mlsAreaID !== "SCL-13A" && (
                    <p className="property-attributes-text">
                      {garageSpaces} car garage
                    </p>
                  )}
                {specialVaitGarage && (
                  <p className="property-attributes-text">No Garage</p>
                )}
                {lotSize &&
                  +lotSize >= 1 &&
                  mlsAreaID !== "SBA-20FCondo" &&
                  mlsAreaID !== "SCL-13A" && (
                    <p className="property-attributes-text">
                      Lot Size: {acreConvert} {useAcreOrSqft}
                    </p>
                  )}
                {+oceanView > 0 ? (
                  <p className="property-attributes-text">
                    {oceanView} {`${getHomeOrCondo(oceanView)} an ocean view`}
                  </p>
                ) : +golfView > 0 ? (
                  <p className="property-attributes-text">
                    {golfView}{" "}
                    {`${getHomeOrCondo(golfView)} a golf course view`}
                  </p>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div
          className={`dashboard-body-divider dashboard-divider-${rangeNum}`}
        />
      </div>
    );
  }
}
export default DashboardBodyRange;
