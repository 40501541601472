import React, { Component } from "react";
import { Link } from "react-router-dom";

class MQThreeTestimonial2 extends Component {
  render() {
    const { agentData, missionStatement } = this.props;

    const { isMemberOfTeam, listgenUserID } = agentData;
    let signoffText;
    let textContent;

    let alternateTitle;
    if (listgenUserID === "100778") {
      alternateTitle = "Our Mission";
    }

    if (missionStatement && Object.keys(missionStatement).length > 0) {
      const { missionStatementContent, namePreferred } = missionStatement;

      if (listgenUserID === "100047") {
        signoffText = "Kyle and David";
      } else {
        signoffText = namePreferred;
      }

      textContent = missionStatementContent;
    }

    let textClass;

    if (missionStatement === "none" || !missionStatement) {
      return (
        <div className="mq3-testimonial2-container">
          <div className="mq3-testimonial2-title-container">
            {alternateTitle && alternateTitle.length > 1 ? (
              <div className="mq3-testimonial2-title">{alternateTitle}</div>
            ) : (
              <div className="mq3-testimonial2-title">
                {isMemberOfTeam || listgenUserID === "100988" ? "Our" : "My"}{" "}
                Commitment To You
              </div>
            )}
            <div className="mq3-testimonial2-title-divider"></div>
          </div>
          <Link to="/agent/missionstatements">
            <div className="mq3-testimonial">
              <div className="testimonial-top-layer">
                <div className="testimonial-container">
                  <div className="testimonial-content-row">
                    <div className="add-testimonial-text">
                      Please Click Here to Add Your Mission Statement (required)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="mq3-testimonial2-container">
          <div className="mq3-testimonial2-title-container">
            {alternateTitle && alternateTitle.length > 1 ? (
              <div className="mq3-testimonial2-title">{alternateTitle}</div>
            ) : (
              <div className="mq3-testimonial2-title">
                {isMemberOfTeam || listgenUserID === "100988" ? "Our" : "My"}{" "}
                Commitment To You
              </div>
            )}
            <div className="mq3-testimonial2-title-divider"></div>
          </div>
          <div className="mq3-testimonial">
            <div className="testimonial-top-layer">
              <div className="testimonial-container">
                <div className="testimonial-content-row">
                  <div className={`testimonial-text ${textClass}`}>
                    {textContent}
                  </div>
                </div>

                <div
                  className={`testimonial-signoff ${
                    textClass
                      ? textClass
                      : "Please add testimonial or mission statement"
                  }`}
                >
                  <p>— {signoffText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MQThreeTestimonial2;
