import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";
import moment from "moment";

class QVFourPhotoSection extends Component {
  isAgentProperty = (prop) => {
    const { currentSoldListings } = this.props;
    if (currentSoldListings && currentSoldListings.length > 1) {
      let foundObject = currentSoldListings.filter((property) => {
        return (
          property.streetName === prop.streetName &&
          property.streetNumber === prop.streetNumber &&
          property.sellingAgentLicenseID === prop.sellingAgentLicenseID &&
          property.status === prop.status &&
          property.listingAgentLicenseID === prop.listingAgentLicenseID
        );
      });

      if (foundObject.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  formatAddressName(address, agentListing) {
    function capitalizeFirstLetter(word) {
      word = word.toLowerCase();
      const firstLetter = word.charAt(0);
      const firstLetterCap = firstLetter.toUpperCase();
      const remainingLetters = word.slice(1);
      const capitalizedWord = firstLetterCap + remainingLetters;

      return capitalizedWord;
    }

    let split = address.split(" ");
    let mappedSplit = split.map((name) => {
      let isAllUpperCase = name === name.toUpperCase();
      if (isAllUpperCase) {
        return capitalizeFirstLetter(name);
      }
      return name;
    });
    return mappedSplit.join(" ");
  }
  getMonthAndYearString = (inputTime) => {
    try {
      let month = moment(inputTime).format("M");
      let day = moment(inputTime).format("D");
      let year = moment(inputTime).format("YY");
      return (
        <p>
          {month}&#8729;
          {day}&#8729;
          {year}
        </p>
      );
    } catch (err) {
      return "";
    }
  };

  shortenPresentedBy = (name, brokerageRaw, printVersion) => {
    const { mlsCityID } = this.props.currentArea;
    let isSB = false;
    if (mlsCityID === "5060" || mlsCityID === "5063" || mlsCityID === "5056") {
      isSB = true;
    }

    let max;
    if (printVersion) {
      max = 47;
    } else {
      max = 47;
    }
    let brokerage = brokerageRaw;
    if (brokerageRaw === "Village Properties - 1") {
      brokerage = "Village Properties";
    }

    const presented = "Presented by "; //20 w/ space

    let credit = "Source: MLSListings";

    if (brokerage.length < 2) {
      return <p className="presented">{credit}</p>;
    } else if (brokerage.length >= max) {
      return (
        <p className="presented">
          {presented} {brokerage.slice(0, max - 3)}
          ...
          <span className="credit-ml"></span>
          {isSB ? <span>Source: Flexmls &copy;</span> : credit}
        </p>
      );
    } else {
      return (
        <p className="presented">
          {presented} {brokerage} <span className="credit-ml"></span>
          {isSB ? <span>Source: Flexmls &copy;</span> : credit}
        </p>
      );
    }
  };

  sortProperties = () => {
    const { currentSoldListings } = this.props;
    let solds = currentSoldListings
      .filter((x) => x.status === "Sold")
      .sort((a, b) => b.salePrice - a.salePrice);
    // console.log("solds", solds);
    let pending = currentSoldListings
      .filter((x) => x.status === "Pending" || x.status === "Contingent")
      .sort((a, b) => b.salePrice - a.salePrice);

    let active = currentSoldListings
      .filter((x) => x.status === "Active")
      .sort((a, b) => b.listPrice - a.listPrice);
    let newSort = [...solds, ...pending, ...active];
    // console.log("newSort", newSort);
    return newSort;
  };

  render() {
    let {
      agentData,
      currentArea,
      printVersion,
      hoData,
      currentSoldListings,
    } = this.props;

    const { getMonthAndYearString } = this;
    const { subdivisionName, coverPageHeader } = hoData;

    const { mlsAreaID, mlsNeighborhood } = currentArea;
    const {
      formatBaths,
      toAcre,

      endsWithS,
      statusContingentToPending,
    } = propertyUtil;

    let isCountryClubGate = mlsAreaID === "132" ? true : false;
    let {
      listgenUserFirstName,
      agentLicenseNumber,
      isMemberOfTeam,
      teamMembers,
      listgenUserID,
    } = agentData;
    // if (listgenUserID === "101677") {
    //   currentSoldListings = this.sortProperties();
    // }

    let isEastMesa = mlsAreaID === "SBA-20J";
    let isNateRandall = listgenUserID === "100030";
    const teamMember1License =
      isMemberOfTeam && teamMembers[0].agentLicenseNumber;
    const teamMember2License =
      isMemberOfTeam && teamMembers[1].agentLicenseNumber;
    let teamMember1Name =
      isMemberOfTeam && teamMembers[0].firstName.toUpperCase();

    let isScar = listgenUserID === "100588";

    const teamMember2Name =
      isMemberOfTeam && teamMembers[1].firstName.toUpperCase();

    let mappedListings = currentSoldListings.slice(0, 6).map((list, i) => {
      // let list = trueList;
      // if (mlsAreaID === "SBA-35F" && i == 0) {
      //   console.log("*******inside new list", i);
      //   list = {
      //     address: "-",
      //     bedBaths: "4/4",
      //     brokerageName: "Village Properties - 1",
      //     coListingAgentFirstName: null,
      //     coListingAgentLastName: null,
      //     coListingAgents: null,
      //     daysOnMarket: "16",
      //     guestHouseBathsAndBeds: "4 Beds/4 Baths Guest: 3 Beds/2 Baths",
      //     listDate: "2022-04-06T00:00:00",
      //     listPrice: "4795000",
      //     listingAgentLicenseID: "00947199",
      //     listingAgentName: "Dianne and Brianna Johnson",
      //     lotSizeSquareFt: "173368.00",
      //     originalListPrice: null,
      //     photoURL:
      //       "https://optimized-property-photos.s3.amazonaws.com/SantaBarbara/Optimized-20220329202438788195000000.jpg",
      //     propertyType: "Single Family Home",
      //     saleDate: "-",
      //     salePrice: "-",
      //     sellingAgentLicenseID: null,
      //     sellingAgentName: null,
      //     squareFeet: "4120.00",
      //     status: "Pending",
      //     streetDirPrefix: "-",
      //     streetName: "La Paloma",
      //     streetNumber: "4488",
      //     streetSuffix: "Ave",
      //     unitNumber: "-",
      //     view: "Mountain, Ocean, Setting, Wooded",
      //   };
      // }
      if (
        listgenUserID === "103455" &&
        list.streetName === "N, Fairview Avenue #6" &&
        list.streetNumber === "250"
      ) {
        list.streetName = "N. Fairview Avenue #6";
      }

      let isListingAgent = false;

      if (
        (!isMemberOfTeam &&
          agentLicenseNumber === list.listingAgentLicenseID) ||
        (isMemberOfTeam &&
          (teamMember1License === list.listingAgentLicenseID ||
            teamMember2License === list.listingAgentLicenseID)) ||
        (list.isCoListingAgent && list.isCoListingAgent === "true") ||
        list.coListingAgents
      ) {
        isListingAgent = true;
      }
      let isSellingAgent = false;
      if (
        (!isMemberOfTeam &&
          agentLicenseNumber === list.sellingAgentLicenseID) ||
        (isMemberOfTeam &&
          (teamMember1License === list.sellingAgentLicenseID ||
            teamMember2License === list.sellingAgentLicenseID)) ||
        (list.isCoSellingAgent && list.isCoSellingAgent === "true")
      ) {
        isSellingAgent = true;
      }
      let soldStatus = list.status === "Sold";
      let bannerText;
      let shouldHighlightDOM = false;
      if (list.daysOnMarket < 30 && list.status !== "Active") {
        shouldHighlightDOM = true;
      }
      let teamComboWithS;
      let teamCombo;
      if (isMemberOfTeam) {
        teamComboWithS = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
          teamMember2Name,
          true
        )}`;
        teamCombo = `${teamMember1Name} & ${teamMember2Name}`;
        if (isScar) {
          teamComboWithS = "TEAM SCARBOROUGH'S";
          teamCombo = "TEAM SCARBOROUGH";
        }
      }
      // console.log("isListingAgent", isListingAgent);
      // console.log("isSellingAgent", isSellingAgent);
      // console.log("soldStatus", soldStatus);

      if (soldStatus) {
        if (
          isEastMesa &&
          list.streetNumber === "1210" &&
          list.streetName === "Del Oro" &&
          list.status === "Sold"
        ) {
          bannerText = "C&H BUYER REPRESENTED";
        } else if (
          listgenUserID === "100528" &&
          isListingAgent &&
          !isSellingAgent
        ) {
          bannerText = "DOUGLAS & ASSOCIATES RECENT SALE";
        } else if (
          listgenUserID === "100528" &&
          isListingAgent &&
          isSellingAgent === false
        ) {
          bannerText = "DOUGLAS & ASSOCIATES LISTING";
        } else if (!isMemberOfTeam && isListingAgent && !isSellingAgent) {
          bannerText = `${listgenUserFirstName.toUpperCase()}${endsWithS(
            listgenUserFirstName,
            true
          )} LISTING SOLD`;
        } else if (!isMemberOfTeam && !isListingAgent && isSellingAgent) {
          bannerText = `SOLD BY ${listgenUserFirstName.toUpperCase()}`;
        } else if (!isMemberOfTeam && isListingAgent && isSellingAgent) {
          bannerText = `LISTED & SOLD BY ${listgenUserFirstName.toUpperCase()}`;
        } else if (isMemberOfTeam && isListingAgent && !isSellingAgent) {
          bannerText = `${teamComboWithS} LISTING SOLD`;
          // bannerText = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
          //   teamMember2Name,
          //   true
          // )} LISTING SOLD`;
        } else if (isMemberOfTeam && !isListingAgent && isSellingAgent) {
          bannerText = `SOLD BY ${teamCombo}`;
          // bannerText = `SOLD BY ${teamMember1Name} & ${teamMember2Name}`;
        } else if (isMemberOfTeam && isListingAgent && isSellingAgent) {
          bannerText = `LISTED & SOLD BY ${teamCombo}`;
          // bannerText = `LISTED & SOLD BY ${teamMember1Name} & ${teamMember2Name}`;
        }
      } else {
        if (
          listgenUserID === "103455" &&
          list.streetName === "Moreton Bay Lane" &&
          list.streetNumber === "327" &&
          list.listingAgentName === "Gail Pearl" &&
          list.status === "Active"
        ) {
          bannerText = "GAIL'S NEW LISTING";
        } else if (
          isNateRandall &&
          list.streetNumber === "304" &&
          list.streetName === "Locust" &&
          list.status === "Active"
        ) {
          bannerText = "NATE'S NEW LISTING";
        } else if (
          listgenUserID === "100528" &&
          isListingAgent &&
          isSellingAgent === false
        ) {
          bannerText = "DOUGLAS & ASSOCIATES LISTING";
        } else if (!isMemberOfTeam && isListingAgent && !isSellingAgent) {
          bannerText = `${listgenUserFirstName.toUpperCase()}${endsWithS(
            listgenUserFirstName,
            true
          )} LISTING`;
        } else if (isMemberOfTeam && isListingAgent && !isSellingAgent) {
          bannerText = `${teamComboWithS} LISTING`;
          // bannerText = `${teamMember1Name} & ${teamMember2Name}${endsWithS(
          //   teamMember2Name,
          //   true
          // )} LISTING`;
        } else if (!isMemberOfTeam && !isListingAgent && isSellingAgent) {
          bannerText = `${listgenUserFirstName.toUpperCase()} IS REPRESENTING THE BUYER`;
        } else if (isMemberOfTeam && !isListingAgent && isSellingAgent) {
          bannerText = `${teamMember1Name} & ${teamMember2Name} ${
            isScar ? "IS" : "ARE"
          } REPRESENTING THE BUYER`;
          // bannerText = `${teamMember1Name} & ${teamMember2Name} ARE REPRESENTING THE BUYER`;
        }
      }

      let allAgentLicenses = [];
      if (isMemberOfTeam && teamMembers && teamMembers.length > 1) {
        teamMembers.map((member) =>
          allAgentLicenses.push(member.agentLicenseNumber)
        );
      }
      let isAgentListing =
        list.listingAgentLicenseID === agentLicenseNumber ||
        list.sellingAgentLicenseID === agentLicenseNumber ||
        allAgentLicenses.includes(list.listingAgentLicenseID) ||
        allAgentLicenses.includes(list.sellingAgentLicenseID) ||
        (list.listingAgentLicenseID === "70003028" &&
          agentLicenseNumber === "01333982");

      let showAddressNumber =
        list.status === "Sold" || isAgentListing || listgenUserID === "101001";

      let hasUnitNum =
        !isCountryClubGate &&
        (list.propertyType === "Townhouse" ||
          list.propertyType === "Condominium") &&
        list.unitNumber !== "-";

      let addressName = "";
      if (
        list.streetNumber !== "-" &&
        list.streetNumber !== "0" &&
        list.streetNumber !== "." &&
        showAddressNumber
      ) {
        addressName += list.streetNumber;
      }

      addressName += ` ${list.streetName}`;
      if (list.streetSuffix && list.streetSuffix !== "-") {
        addressName += ` ${list.streetSuffix}`;
      }
      if (hasUnitNum && showAddressNumber) {
        addressName += `, #${list.unitNumber}`;
      }

      return (
        <div
          key={`${list.address} ${list.listPrice} ${list.saleDate} ${i}`}
          className="list-map-item"
        >
          <div>
            <div className="list-img-container">
              {bannerText && (
                <p
                  className={`banner-text-bg ${
                    listgenUserID === "100528" && "douglas-banner"
                  }`}
                >
                  {bannerText}
                </p>
              )}
              <div className="list-photo-and-banner-container">
                <img src={list.photoURL} alt="Home" className="listing-img" />
                <div className="list-row status-address-row">
                  <div className="status-address-row-inner">
                    <p className="list-address sold-address">
                      {this.formatAddressName(addressName)}
                    </p>
                    <div className="status-and-sales-price">
                      <p className={`list-${list.status.toLowerCase()} status`}>
                        {statusContingentToPending(list.status)}
                      </p>
                      {list.status !== "Active" && list.salePrice !== "-" && (
                        <p className="list-sale-price">
                          {" "}
                          ${Math.round(list.salePrice).toLocaleString()}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="active-listing-text-container three-active-cols">
              {list.status === "Sold" ? (
                <div className="list-row">
                  {list.listPrice !== "-" ? (
                    <div className="list-col">
                      <p>List Price</p>
                      <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                    </div>
                  ) : (
                    <p></p>
                  )}

                  <div className="list-col">
                    <p>Sale Date</p>
                    {getMonthAndYearString(list.saleDate)}
                  </div>
                  <div className="list-col">
                    <p>Beds / Baths</p>
                    <p>
                      {formatBaths(list.bedBaths, true).bed} /{" "}
                      {formatBaths(list.bedBaths, true).bath}
                    </p>
                  </div>
                </div>
              ) : list.propertyType === "Residential Lot" ? (
                <div className="list-row">
                  {list.listPrice !== "-" ? (
                    <div className="list-col">
                      <p>List Price</p>
                      <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="list-col">Vacant Lot</div>
                </div>
              ) : (mlsAreaID === "SBA-35H" || mlsAreaID === "SBA-35F") &&
                list.streetName === "La Paloma" &&
                list.streetNumber === "4488" ? (
                <div className="list-row">
                  {list.listPrice !== "-" ? (
                    <div className="list-col">
                      <p>List Price</p>
                      <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="list-col">
                    {String(list.guestHouseBathsAndBeds)
                      .split(" ")
                      .slice(0, 3)
                      .join(" ")}
                    {/*<p>Beds</p>
                    <p> {formatBaths(list.bedBaths, true).bed}</p>*/}
                  </div>
                  <div className="list-col smaller-guest-text">
                    {String(list.guestHouseBathsAndBeds)
                      .split(" ")
                      .slice(3)
                      .join(" ")}
                    {/*<p>Baths</p>
                    <p> {formatBaths(list.bedBaths, true).bath}</p>*/}
                  </div>
                </div>
              ) : (
                <div className="list-row">
                  {list.listPrice !== "-" ? (
                    <div className="list-col">
                      <p>List Price</p>
                      <p> ${Math.round(list.listPrice).toLocaleString()}</p>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="list-col">
                    <p>Beds</p>
                    <p>{formatBaths(list.bedBaths, true).bed}</p>
                  </div>
                  <div className="list-col">
                    <p>Baths</p>
                    <p> {formatBaths(list.bedBaths, true).bath}</p>
                  </div>
                </div>
              )}
              <div className="list-row">
                {list.daysOnMarket !== null ? (
                  <div
                    className={
                      "list-col " + (shouldHighlightDOM ? "highlight-dom" : "")
                    }
                  >
                    <p>Days on Market </p>
                    <p>{Math.round(list.daysOnMarket).toLocaleString()}</p>
                  </div>
                ) : (
                  <p></p>
                )}
                {list.streetName === "Santa Rosa" &&
                list.streetNumber === "9496" &&
                list.salePrice === "7000000" ? (
                  <div className="list-col"></div>
                ) : (
                  <div className="list-col">
                    <p>Living SqFt</p>
                    {list.squareFeet === "0" ? (
                      <p>—</p>
                    ) : (
                      <p>{Math.round(list.squareFeet).toLocaleString()}</p>
                    )}
                  </div>
                )}

                <div className="list-col">
                  <p>Lot</p>
                  {Math.round(list.lotSizeSquareFt) === "0" ||
                  list.lotSizeSquareFt === "0.0" ? (
                    <p>—</p>
                  ) : (
                    <p>{toAcre(list.lotSizeSquareFt, false, false, true)}</p>
                  )}
                </div>
              </div>
              <div
                className={
                  "full-row presented " +
                  (isListingAgent ? "highlight-presented" : "small-gray")
                }
              >
                {this.shortenPresentedBy(
                  list.listingAgentName,
                  list.brokerageName,
                  printVersion,
                  "Presented by "
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
    const { splitAreas } = propertyUtil;
    const { demoAccountAreaName, version } = this.props;
    let place;
    let placeCount = mlsNeighborhood.length;
    if (mlsAreaID === "SBA-20FInv" || mlsAreaID === "SBA-20FRes") {
      place = "Downtown Santa Barbara";
    } else if (demoAccountAreaName && demoAccountAreaName.length > 1) {
      place = demoAccountAreaName;
    } else if (
      coverPageHeader &&
      coverPageHeader === "Upper East and Lower Mission Canyon"
    ) {
      place = "Upper East + Lower Mission Canyon";
    } else if (mlsNeighborhood === "Winchester Canyon") {
      place = "Rancho Embarcadero & Winchester";
    } else if (mlsNeighborhood === "Ennisbrook Birnam Wood") {
      place = "Ennisbrook / Birnam Wood";
    } else if (subdivisionName) {
      place = subdivisionName;
    } else if (placeCount > 30 || mlsAreaID === "SCL-16J") {
      place = "Your Neighborhood";
    } else {
      place = splitAreas(mlsNeighborhood).join(" + ");
    }
    let isGail = listgenUserID === "103455";
    return (
      <div className="qv4-photo-section-container">
        <div
          className={`qv4-photo-section-title ${
            isGail && "gail-photo-section-title"
          }`}
        >
          {/*  <p className="qr-photo-section-title">
        <span className="qr-text-new">NEW!</span> Get Your Home Valuation
            Here:{" "}
            {listgenUserID === "100411" ? (
              <a href="https://hmbt.co/DcY6Gq" target="_blank">
                <span className="qr-code-link-for-photos">
                  https://hmbt.co/DcY6Gq
                </span>
              </a>
            ) : (
              <a href={homeOwnerURL} target="_blank">
                <span className="qr-code-link-for-photos">
                  OurProperty.info/{homeOwnerURLID}
                </span>
              </a>
            )}
          </p>*/}

          {mlsAreaID === "SBA-35J" ? (
            <p>My Recent Encina Royale Sales</p>
          ) : listgenUserID === "100560" ? (
            <p>Coastal Ranch Properties for Sale</p>
          ) : version === "qv4" ? (
            <p></p>
          ) : mlsAreaID === "SCL-16J" ? (
            <p>What Homes Are Selling For in Your Neighborhood</p>
          ) : mlsAreaID === "SBA-40H" ? (
            <p>WHAT SANTA YNEZ VALLEY RANCHES ARE SELLING FOR</p>
          ) : mlsAreaID === "SBA-20FCondo" ? (
            <p>What Condos Are Selling in Downtown Santa Barbara?</p>
          ) : mlsAreaID === "SBA-20FInv" ? (
            <p>What Properties Are Selling in Downtown Santa Barbara?</p>
          ) : (
            <p>
              What Homes Are Selling{" "}
              {mlsAreaID === "SBA-20K" || mlsAreaID === "SBA-20I" ? "on" : "in"}{" "}
              {place}
            </p>
          )}
        </div>
        <div className="qv4-mapped-listings">{mappedListings}</div>
      </div>
    );
  }
}

export default QVFourPhotoSection;
