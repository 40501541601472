import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class QV2AHGraph extends Component {
  render() {
    const {
      isCity,
      areaCityName,
      ddPerc,
      inventory,
      sale,
      overflowDiff,
      mappedBar,
      twoAreas,
    } = this.props;
    const { countAreaChars } = propertyUtil;
    // console.log(countAreaChars(areaCityName));
    // console.log('ah graph', this.props);

    // console.log('is CCG', isCCG);
    let smallerText = "";
    const areaChar = countAreaChars(areaCityName);
    if (areaChar.includes("medium") || areaChar.includes("small")) {
      smallerText = true;
    }
    // console.log('smallerText', smallerText);
    // console.log('areaChar', areaChar);
    return (
      <div className="ah-bar-new">
        <div className="area-col">
          <div>
            <div className="center-col">
              <h4 className={`mh-title ${smallerText && "smaller-text"}`}>
                {areaCityName}
              </h4>
            </div>
          </div>

          {/* {areaCount === 4 ? (
            <div>
              <div className="mh-title-multi multiple-center-col">
                <p>
                  {areaSplit[0]} / {areaSplit[1]} /
                </p>
                <p>
                  {areaSplit[2]} / {areaSplit[3]}
                </p>
              </div>
            </div>
          ) : areaCount === 3 ? (
            <div>
              <div className="mh-title-multi multiple-center-col">
                <p>
                  {areaSplit[0]} / {areaSplit[1]} /
                </p>
                <p>{areaSplit[2]}</p>
              </div>
            </div>
          ) : isCCG ? (
            <div>
              <div className="mh-title-multi multiple-center-col two-areas">
                <p>
                  {areaSplit[0]} / {areaSplit[1]}
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div className="center-col">
                <h4 className={`mh-title ${smallerText && "smaller-text"}`}>
                  {areaCityName}
                </h4>
              </div>
            </div>
          )} */}
        </div>

        <div className="sub-title">
          Current Inventory Is{" "}
          <span
            className={`inventory-name ${
              // inventory === "Low" ? "inventory-low-text" : ""
              inventory.toLowerCase()
            }`}
          >
            {inventory}
          </span>
        </div>
        <div className="description-title">
          <p>
            {sale} {sale === "1" ? "Home" : "Homes"} For Sale
          </p>
          <p className="description-title-divider">|</p>
          <p>
            {inventory === "Low"
              ? "(< 4 month's supply)"
              : inventory === "Balanced"
              ? "(4 - 6 month's supply)"
              : "(6+ month's supply)"}
          </p>
        </div>
        <div className="bars-row">
          <div className="before-zero-bar" />
          <div className="bars-container">
            {/* <dd className={`percentage percentage-${ddPerc}  ${bg}`}> */}
            <dd className={`ah-triangle-${ddPerc}`}></dd>
            {/* </dd> */}
            <dd className="percentage percentage-100 base-bar" />

            {overflowDiff > 0 && isCity && (
              <p className="ah-overflow-1">+{overflowDiff}</p>
            )}
            {overflowDiff > 0 && !isCity && (
              <p
                className={
                  "ah-overflow-2 " + (twoAreas ? "" : "two-area-overflow")
                }
              >
                +{overflowDiff}
              </p>
            )}

            <dd className="bar-marks ">
              <span className="text" />
            </dd>
          </div>
        </div>
        {/* <div className="barBorder-x" /> */}
        <div className={`barBorder-x-numbers${isCity ? "" : "-2"}`}>
          {mappedBar}
        </div>
      </div>
    );
  }
}

export default QV2AHGraph;
