import React, { Component } from "react";

class QVThreeGreetingsPS extends Component {
  render() {
    const {
      theHomeowners,
      hoTextNote,
      isMemberOfTeam,
      teamMembers,
      agentFirstNames,
      listgenUserFirstName,
      mailingNum,
      currentPS,
      agentSignoff,
      fromMailPro,
      demoAccountHOName,
      // currentArea
    } = this.props;
    // const { mlsNeighborhood } = currentArea;
    // console.log("greetingsPS", this.props);
    // console.log(theHomeowners.length);
    let smallerHOText = false;
    if (theHomeowners.length > 11) {
      smallerHOText = true;
    }
    // let areaCount = mlsNeighborhood.length;
    // console.log("areaCount", areaCount);
    // let smallerAreaStyles;
    // if (areaCount > 30) {
    //   smallerAreaStyles = "medium-area-styles";
    // } else if (areaCount > 40) {
    //   smallerAreaStyles = "small-area-styles";
    // }
    return (
      <div
        className={mailingNum ? `${mailingNum}-greetings-ps` : "greetings-ps"}
      >
        <div className="greetings-ps-title-container">
          <div
            className={`greetings-ps-title ${
              smallerHOText ? "smaller-greetings-ho-text" : ""
            }`}
          >
            Greetings{" "}
            {demoAccountHOName && demoAccountHOName.length > 1
              ? "Stacy"
              : theHomeowners.toLowerCase()}
          </div>
          <div className="greetings-ps-divider" />
        </div>
        <div className="greetings-ps-box">
          {hoTextNote === "<p></p>" ? (
            <div className="greetings-ps-text">
              After a record-setting winter, the Monterey Peninsula's market
              continues to blossom. Robust buyer demand and low inventory have
              created unprecedented negotiating strengths for sellers. This
              spring & summer will yield fresh opportunities for homeowners to
              negotiate their best terms. It's an incredibly strong market -
              putting sellers in the driver's seat!
            </div>
          ) : (
            <div
              className="greetings-ps-text"
              dangerouslySetInnerHTML={{
                __html: hoTextNote,
              }}
            ></div>
          )}

          <div className="agent-signoff">
            <p className="signoff">Here's to new beginnings,</p>

            {fromMailPro === true ? (
              <p className="agent-name">{agentSignoff}</p>
            ) : isMemberOfTeam && teamMembers.length === 2 ? (
              <p className="agent-name">
                {agentFirstNames(listgenUserFirstName, teamMembers)}
              </p>
            ) : (
              <p className="agent-name">{listgenUserFirstName}</p>
            )}
          </div>
          {currentPS && currentPS.length > 8 && (
            <div className="qv3-ps-line">PS: {currentPS}</div>
          )}
        </div>
      </div>
    );
  }
}

export default QVThreeGreetingsPS;
